import { UserTypesEnum } from '@assets/constants'
import { AddressDTO } from '@microservices/dto/AddressDTO'
import { IAdminInfo } from '@views/schemas'

export interface UserRequestDTO {
  id?: string
  first_name: string
  last_name: string
  email: string
  percent_ownership?: number
  phone: string
  dob: Date
  ssn: string
  legal_address: AddressDTO | Partial<AddressDTO>
  status?: PersonStatusesEnum
}

export interface UserResponseDTO {
  address_line_1?: string
  address_line_2?: string
  birthday: string
  city?: string
  email: string
  first_name: string
  id: string
  last_name?: string
  percent_ownership?: number
  phone?: string
  state?: string
  zip_code?: string
}

export interface SendEmailRequestDto {
  email: string
  type: UserTypesEnum
  resend_email?: boolean
}

export const mapEmailToBoEmailRequest = (email: string, resend?: boolean): SendEmailRequestDto => {
  return {
    email,
    type: UserTypesEnum.BENEFICIAL_OWNER,
    resend_email: resend || false,
  }
}

export const AdminInfoToUserDTO = (
  adminInfo: IAdminInfo | Partial<IAdminInfo>
): Partial<UserRequestDTO> => {
  let legal_address = null
  if (adminInfo.address_line_1)
    legal_address = {
      address_line_1: adminInfo.address_line_1,
      address_line_2: adminInfo.address_line_2,
      city: adminInfo.city,
      state: adminInfo.state,
      postal_code: adminInfo.zip_code,
    }

  return {
    ...(adminInfo.id && { id: adminInfo.id }),
    ...(adminInfo.first_name && { first_name: adminInfo.first_name }),
    ...(adminInfo.last_name && { last_name: adminInfo.last_name }),
    ...(adminInfo.email && { email: adminInfo.email }),
    ...(adminInfo.phone && { phone: adminInfo.phone }),
    ...(adminInfo.birthday && { dob: new Date(adminInfo.birthday) }),
    ...(adminInfo.ssn && { ssn: adminInfo.ssn }),
    ...(legal_address && { legal_address }),
    ...(adminInfo.status && { status: adminInfo.status }),
  }
}

export enum PersonStatusesEnum {
  ACTIVE = 'ACTIVE',
  DECEASED = 'DECEASED',
  DENIED = 'DENIED',
  DORMANT = 'DORMANT',
  ESCHEAT = 'ESCHEAT',
  FROZEN = 'FROZEN',
  INACTIVE = 'INACTIVE',
  PROSPECT = 'PROSPECT',
  SANCTION = 'SANCTION',
}
