import React from 'react'
import { StyleSheet, View } from 'react-native'

import { COLORS } from '@assets/constants'
import { AppText } from '@views/components'
import { NeedHelp } from '@views/components/NeedHelp'
import { useAuth } from '@views/hooks'

interface Props {
  title?: string
  subtitle?: string
  route?: string
  subroute?: string
}

const Header: React.FC<Props> = ({
  title = 'Account Creation',
  subtitle = 'Create an account to start using Players Health Services.',
  route = 'Organization info',
  subroute,
}) => {
  const { logout } = useAuth()
  return (
    <>
      <View style={styles.header}>
        <AppText style={styles.title} bold>
          {title}
        </AppText>
        <NeedHelp logout={logout} />
      </View>
      <AppText style={styles.subTitle1}>{subtitle}</AppText>
      <AppText bold style={styles.subTitle2}>
        {route}
        {subroute && (
          <>
            &nbsp;/&nbsp;
            <AppText bold style={{ color: COLORS.GRAY_500 }}>
              {subroute}
            </AppText>
          </>
        )}
      </AppText>
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: 32,
    paddingBottom: 6,
  },
  title: {
    fontSize: 24,
  },
  subTitle1: {
    fontSize: 16,
    maxWidth: 850,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
})

export default Header
