import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppText,
  Button,
  FormInput,
  InputTypes,
  openModal,
  TakePictures,
  closeModal,
  UL,
} from '@views/components'
import logo from '@assets/images/logo_color.png'
import closeIcon from '@assets/images/close-icon.png'
import * as yup from 'yup'
import { depositAmountRegex, COLORS } from '@assets/constants'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { useAuth } from '@views/hooks'
import { createRDCOptions } from '@views/utils'
import { visitSupportCenter } from '@views/helpers'
import { UnauthenticatedError } from '@microservices/errors/http'

type DepositCheckProps = {
  isInitialDeposit?: boolean
  navigate: () => void
}

const initialDepositSchema = yup.object().shape({
  depositAmount: yup
    .string()
    .required('Required')
    .matches(depositAmountRegex, 'Invalid Deposit Amount')
    .min(5, 'Invalid Deposit Amount')
    .max(16, 'Invalid Deposit Amount'),
})

const DepositCheck: React.FC<DepositCheckProps> = ({ isInitialDeposit = false, navigate }) => {
  const [invalidAmount, setInvalidAmount] = useState(false)
  const [depositInfoComplete, setDepositInfoComplete] = useState<boolean>(false)
  const [front, setFront] = useState<string | undefined>(undefined)
  const [back, setBack] = useState<string | undefined>(undefined)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { logout, user } = useAuth()

  const checkRequirements = [
    `Front and back photos of the check`,
    `Your signature on the back of the check and an endorsement that says: "For Mobile Deposit Only"`,
  ]

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(initialDepositSchema),
    defaultValues: {
      depositAmount: '',
    },
  })

  const resetState = () => {
    reset()
    setDepositInfoComplete(false)
    setFront(undefined)
    setBack(undefined)
    setIsComplete(false)
    setIsSubmitted(false)
  }

  const closeAndNavigate = () => {
    if (isInitialDeposit && isSubmitSuccessful) {
      location.reload()
      return
    }
    resetState()
    closeModal()
    navigate()
  }

  const goBackButton = () => {
    return (
      <Button
        type="light"
        text={isInitialDeposit ? 'Back to Fund Account' : 'Back to Business Account'}
        onPress={closeAndNavigate}
      />
    )
  }

  const onClose = () => {
    openModal({
      type: 'warning',
      dismiss: false,
      title: 'Data has not been saved.',
      subtitle: 'Are you sure you want to close?',
      buttons: [
        <Button
          type="primary"
          text="Continue Making a Deposit"
          onPress={() => {
            closeModal()
          }}
        />,
        goBackButton(),
      ],
    })
  }

  const onSubmit = async (depositData: any) => {
    openModal({
      title: 'Hang tight, we are working to secure your deposit.',
      type: 'warning',
      dismiss: false,
    })
    const depositAmount = Math.trunc(Number(depositData.depositAmount.replace(/[\$,]/g, '')) * 100)
    const payload = await createRDCOptions(`${depositAmount}`, front, back)
    try {
      await bankingService(bankingRepo).createCheckDeposit(user?.association.id, payload)
      setIsSubmitted(true)
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openErrorModal()
    }
  }

  const openErrorModal = () => {
    openModal({
      type: 'warning',
      dismiss: false,
      title: 'Something went wrong.',
      subtitle: 'Your check deposit could not be processed.',
      onDismiss: () => {
        closeAndNavigate()
      },
      buttons: [
        <Button
          type="primary"
          text={isInitialDeposit ? 'Back to Fund Account' : 'Back to Business Account'}
          onPress={() => {
            closeAndNavigate()
          }}
        />,
        <Button onPress={() => visitSupportCenter()} text={'Contact us'} type={'light'} />,
      ],
    })
  }

  useEffect(() => {
    if (!isSubmitted) return
    if (isSubmitSuccessful)
      openModal({
        type: 'success',
        dismiss: false,
        title: 'Success',
        subtitle: 'Your check was sent for review.',
        onDismiss: () => closeAndNavigate(),
        buttons: [
          <Button
            type="primary"
            text={isInitialDeposit ? 'Go to dashboard' : 'Back to Business Account'}
            onPress={() => closeAndNavigate()}
          />,
        ],
      })
    else openErrorModal()
    setIsSubmitted(false)
  }, [isSubmitSuccessful, isSubmitted])

  const onNext = (depositData: any) => {
    const depositAmount = Number(depositData.depositAmount.replace(/[\$,]/g, '')) * 100

    let minimumDepositAmount = 50 * 100
    if (!isInitialDeposit) minimumDepositAmount = 100

    if (depositAmount < minimumDepositAmount) setInvalidAmount(true)
    else {
      setInvalidAmount(false)
      setDepositInfoComplete(true)
    }
  }

  const getTitle = () => {
    if (front && back) return 'Ready to submit'
    if (!depositInfoComplete) return 'We need some information about your deposit'
    return `Please take a photo of the ${!front ? 'front' : 'back'} of your check.`
  }

  const getCheckPictures = () => {
    return (
      <View style={styles.picturesContainer}>
        <TakePictures
          setFront={setFront}
          front={front}
          setBack={setBack}
          back={back}
          setIsComplete={setIsComplete}
          takeBackSide={true}
          showBorder={true}
          navigate={closeAndNavigate}
        />
        <Button
          style={styles.btnSubmit}
          onPress={(e) => {
            handleSubmit(onSubmit)(e).catch((err) => {
              console.error({ err })
            })
            return
          }}
          text={isSubmitting ? 'Loading...' : 'Submit'}
          disabled={!isComplete || isSubmitting}
          loading={isSubmitting}
        />
      </View>
    )
  }

  const getCheckForm = () => {
    return (
      <View style={styles.formContainer}>
        <View style={styles.inputsContainer}>
          <FormInput
            type={InputTypes.money}
            name={'depositAmount'}
            label={'Amount'}
            control={control}
            error={errors.depositAmount as unknown as FieldError}
            placeholder={'Enter amount'}
          />
          {invalidAmount && <AppText style={styles.invalidMessage}>Invalid Deposit Amount</AppText>}
          {getMinimumDepositMessage()}
        </View>

        <AppText bold style={[styles.listText]}>
          As a reminder, we require:
        </AppText>
        <UL data={checkRequirements} textStyle={styles.listText} underlineMap={{ 1: 'and' }} />

        <Button onPress={handleSubmit(onNext)} text="Next" />
      </View>
    )
  }

  const getMinimumDepositMessage = () => (
    <AppText style={styles.amountRequirement}>Minimum {!isInitialDeposit ? '$1' : '$50'}</AppText>
  )

  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <View style={styles.headerContainer}>
          <Image source={logo} style={styles.logo} />
          <TouchableOpacity onPress={onClose}>
            <Image source={closeIcon} style={styles.closeIcon} />
          </TouchableOpacity>
        </View>
        <AppText style={styles.title} bold>
          {getTitle()}
        </AppText>
      </View>
      {depositInfoComplete ? getCheckPictures() : getCheckForm()}
    </View>
  )
}

export default DepositCheck

const styles = StyleSheet.create({
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddedText: {
    padding: 20,
  },
  container: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  closeIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  invalidMessage: {
    color: '#f00',
    fontSize: 14,
  },
  formContainer: {
    paddingBottom: 16,
    paddingTop: 60,
    minHeight: '70%',
    width: '100%',
  },
  picturesContainer: {
    paddingBottom: 16,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  inputsContainer: {
    width: '100%',
  },
  depositAccount: {
    width: '100%',
    backgroundColor: COLORS.BACKGROUND,
    fontSize: 14,
    paddingVertical: 13,
    paddingLeft: 16,
    color: COLORS.GRAY_700,
  },
  logo: {
    width: 101,
    height: 23,
    marginBottom: 15,
  },
  btnSubmit: {
    position: 'absolute',
    bottom: 0,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  titleView: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  amountRequirement: {
    fontSize: 12,
    color: COLORS.GRAY_500,
    marginBottom: 20,
  },
  listText: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    fontSize: 20,
  },
})
