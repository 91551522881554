import { InternalTransfer } from '@domain/models'

export interface AchDTO {
  user_id?: string
  amount: string
  originating_account_id: string
  receiving_account_id: string
  dc_sign?: string
  comments?: string
}
export interface InternalTransferRequestDTO {
  amount: number
  originating_account_id: string
  receiving_account_id: string
}

export interface InternalTransferResponseDTO {
  amount: number
  currency: string
  originating_account_id: string
  receiving_account_id: string
  type: string
  id: string
}

export type ExternalAccountRequestDTO = {
  account_owner_name: string
  account_number: string
  routing_number: string
  user_id: string
  bank_name: string
  customer_type?: string
  account_type?: string
}

export const ExternalAccountToExternalAccountReqeustDTO = (
  data: any
): ExternalAccountRequestDTO => ({
  account_owner_name: data.accountOwnerName,
  account_number: data.accountNumber,
  routing_number: data.routingOrABANumber,
  bank_name: data.bankName,
  user_id: data.user_id,
})

export const InternalTransferDtoToInternalTransfer = (
  dto: InternalTransferResponseDTO
): InternalTransfer => {
  return {
    amount: dto.amount,
    currency: dto.currency,
    id: dto.id,
    originating_account_id: dto.originating_account_id,
    receiving_account_id: dto.receiving_account_id,
    type: dto.type,
  }
}
