import { PermissionContainer } from '@views/components'
import React from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import BusinessAccountDrawer from './BusinessAccountDrawer'
import BusinessAccountInfo from './BusinessAccountInfo'

const BusinessAccountPage: React.FC = () => {
  return (
    <ScrollView contentContainerStyle={styles.businessAccContainer}>
      <PermissionContainer>
        <BusinessAccountDrawer />
      </PermissionContainer>
      <BusinessAccountInfo />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  businessAccContainer: {
    flexDirection: 'column',
    flex: 1,
  },
})

export default BusinessAccountPage
