import { CardActions } from './actions'
import { ICardsState } from './interfaces'

export const CardsReducer = (state: ICardsState, action: CardActions): ICardsState => {
  const reducerActions = {
    SET_CARD_PRODUCTS: () => {
      return { ...state, cardProducts: action.payload }
    },
    SET_CARD_PRODUCTS_LOADING: () => {
      return { ...state, loadingCardProducts: action.payload }
    },
    SET_SUBMIT_LOADING: () => {
      return { ...state, submitLoading: action.payload }
    },
    default: () => state,
  }
  return <ICardsState>(reducerActions[action.type] || reducerActions.default)()
}
