import React from 'react'
import { MemberCards, DepositsTab } from '@views/components'

type Props = {
  showDepositsTab: boolean
}

const AccountInformation: React.FC<Props> = ({ showDepositsTab }) => {
  return showDepositsTab ? <DepositsTab /> : <MemberCards />
}

export default AccountInformation
