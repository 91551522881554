import { COLORS } from '@assets/constants'
import { mapTransactionTypes } from '@assets/constants/trasactionTypes'
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'
import { AppText, DropdownPopover, Hoverable, openModal } from '@views/components'
import { useTransactions } from '@views/hooks/useTransactions'
import { currencyFormatter } from '@views/utils'
import moment from 'moment'
import React, { useEffect } from 'react'
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ViewDetails } from './ViewDetails'

interface TransactionsListProps {
  pendingOrPosted: 'pending' | 'posted'
  fromDate: string
  toDate: string
  type: string
  bankAccountId: string
}
const TransactionsList = ({
  pendingOrPosted,
  fromDate,
  toDate,
  type,
  bankAccountId,
}: TransactionsListProps) => {
  const {
    getTransactions,
    transactionsState: { transactions, loading },
  } = useTransactions()

  useEffect(() => {
    const newFromDate = moment(new Date(fromDate))
    const newToDate = moment(new Date(toDate))

    getTransactions({
      pendingOrPosted,
      fromDate: newFromDate.isValid() ? newFromDate.format('YYYY-MM-DD') : '',
      toDate: newToDate.isValid() ? newToDate.format('YYYY-MM-DD') : '',
      type,
      bankAccountId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTransactions, pendingOrPosted])

  if (loading) return <ActivityIndicator />

  return (
    <View style={styles.clubTeamInfocontainer}>
      <View>
        <View style={styles.clubInfoHeader}>
          <AppText style={[styles.tabHeader]}>Recipient</AppText>
          <AppText style={[styles.tabHeader]}>
            {pendingOrPosted === 'pending' ? 'Initiated' : 'Posted'} date
          </AppText>
          <AppText style={[styles.tabHeader]}>Transaction type</AppText>
          <AppText style={[styles.tabHeader]}>Initiated by</AppText>
          <AppText style={[styles.tabHeader]}>Amount</AppText>
          <View style={styles.filler} />
        </View>
        {transactions?.result?.length > 0
          ? transactions?.result?.map((item) => (
              <View key={`${item.id}-${item.created}`}>
                <View style={styles.clubInfoRow}>
                  <AppText style={styles.initiatedBy}>
                    <View>
                      <AppText style={styles.initiatedByText}>
                        {item?.data?.user_data?.recipient_name || ''}
                      </AppText>
                    </View>
                  </AppText>
                  <AppText style={styles.date}>
                    {moment(new Date(item.posted_date || item.created)).format('MM/DD/YY')}
                  </AppText>
                  <AppText style={styles.transactionType}>
                    <View style={styles.typeWrapper}>
                      <AppText>{mapTransactionTypes(item?.data?.type || item?.type)}</AppText>
                    </View>
                  </AppText>
                  <AppText style={styles.initiatedBy}>
                    <View>
                      <AppText style={styles.initiatedByText}>
                        {item?.data?.user_data?.user_name_synctera || ''}
                      </AppText>
                      <AppText style={styles.initiatedByOrgText}>
                        {item.data.user_data.source_account_name}
                      </AppText>
                    </View>
                  </AppText>
                  <AppText style={styles.amount}>
                    {item.data.dc_sign === 'debit' && `- `}

                    {item?.data?.amount
                      ? currencyFormatter(item?.data?.amount / 100)
                      : item?.data?.lines?.[0]?.amount
                      ? currencyFormatter(item?.data?.lines?.[0]?.amount / 100)
                      : null}
                  </AppText>

                  <DropdownPopover
                    from={
                      <MaterialCommunityIcons
                        name="dots-vertical"
                        size={20}
                        color={COLORS.GRAY_700}
                      />
                    }>
                    <View style={styles.popoverContainer}>
                      <Hoverable>
                        {(isHovered) => (
                          <TouchableOpacity
                            style={styles.popoverOptions}
                            onPress={() => {
                              openModal({
                                content: <ViewDetails result={item} />,
                                dismiss: false,
                              })
                            }}>
                            <FontAwesome5
                              name="list-alt"
                              size={16}
                              color={isHovered ? COLORS.PRIMARY : COLORS.GRAY_700}
                            />
                            <AppText
                              style={[styles.popoverOptionsText, isHovered && styles.textHover]}>
                              View Details
                            </AppText>
                          </TouchableOpacity>
                        )}
                      </Hoverable>
                    </View>
                  </DropdownPopover>
                </View>
                <View style={styles.divider} />
              </View>
            ))
          : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
  },
  divider: {
    backgroundColor: COLORS.GRAY_300,
    height: 1,
    width: '100%',
  },
  noElements: {
    fontSize: 16,
    fontWeight: '400',
  },
  typeWrapper: {
    height: 29,
    paddingHorizontal: 8,
    backgroundColor: COLORS.AZURE_LIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: 'fit-content',
    borderRadius: 8,
  },
  clubInfoHeader: {
    paddingTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clubInfoRow: {
    paddingVertical: 24,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filler: {
    width: 20,
  },
  tabHeader: {
    fontSize: 12,
    fontWeight: '500',
    width: '100%',
  },
  date: {
    fontSize: 14,
    width: '100%',
  },
  transactionType: {
    fontWeight: '300',
    fontSize: 14,
    width: '100%',
  },
  initiatedBy: {
    fontSize: 14,
    width: '100%',
  },
  initiatedByText: {
    fontWeight: '700',
  },
  initiatedByOrgText: {
    fontWeight: '300',
  },
  amount: {
    width: '100%',
    fontSize: 14,
    fontWeight: '600',
  },
  dots: {
    resizeMode: 'contain',
    height: 20,
    width: '5%',
  },

  popoverContainer: {
    backgroundColor: '#FFF',
    width: 150,
    height: 42,
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    borderRadius: 8,
  },
  popoverOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  popoverOptionsText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
  textHover: {
    color: COLORS.PRIMARY,
  },
})

export default TransactionsList
