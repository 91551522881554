import React, { useState } from 'react'
import { View, StyleSheet, NativeScrollEvent, Linking } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { useSystemConfig } from '@views/hooks'
import { AppText, Button } from '@views/components'

import { COLORS, DISCLAIMERS_LIST } from '@assets/constants'

const MARGIN_BOTTOM = 10

interface Props {
  onAccepted: () => void
}

export const DisclaimersList: React.FC<Props> = ({ onAccepted }) => {
  const { isSmallDevice } = useSystemConfig()
  const [disclaimersListRead, setDisclaimersListRead] = useState(false)

  return (
    <View style={isSmallDevice ? styles.containerSmall : styles.container}>
      <AppText bold style={styles.title}>
        Players Health Terms and Conditions
      </AppText>
      <ScrollView scrollEventThrottle={16}>
        {DISCLAIMERS_LIST.map((section) => (
          <View key={section.title}>
            <AppText
              bold
              style={styles.sectionTitle}
              onPress={() => Linking.openURL(section.content)}>
              {section.title}
            </AppText>
          </View>
        ))}

        <View>
          <AppText style={styles.sectionContent}>
            By clicking accept, I’m acknowledging that I read and agree to all disclosures in the
            Players Health Financial Services terms and conditions.
          </AppText>
        </View>
      </ScrollView>
      <Button style={styles.button} onPress={onAccepted} type="primary" text="Accept" />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { maxHeight: '70vh', marginBottom: MARGIN_BOTTOM },
  containerSmall: { maxHeight: '60vh', marginBottom: 10 },
  title: { fontSize: 24 },
  sectionTitle: { marginTop: 20, color: COLORS.SECONDARY },
  sectionContent: { lineHeight: 24, marginTop: 20 },
  button: { marginTop: 36 },
})
