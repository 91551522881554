import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_CLIENT_ID, AUTH_TOKEN_KEY, UserTypesEnum } from '@assets/constants'
import { LocalStorage } from '@microservices/instances/LocalStorage'
import { userService } from '@domain/service/UserService'
import { userRepo } from '@microservices/instances/UserRepo'
import { User } from '@domain/models'
import { checkForIncompleteCreateFlow, CompleteFlow } from '@views/helpers'

type LoginArgs = {
  redirectUri?: string
  action?: 'signup' | 'login'
  appState?: any
}

interface AuthContextInfo {
  user?: User
  setUser: Dispatch<SetStateAction<User>>
  updateAuthFlow: (userData: User) => void
  logout: () => void
  login: (args?: LoginArgs) => Promise<void>
  isLoadingAuth: boolean
  isAuthenticatedAuth0: boolean
  isAuthenticatedApi: boolean
  authFlowInfo?: CompleteFlow
  appState?: any
}

interface AuthContextProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextInfo)
const localStorage = new LocalStorage()

export function AuthContextProvider({ children }: AuthContextProps) {
  const {
    logout: auth0Logout,
    loginWithRedirect,
    isLoading: isLoadingAuth0,
    isAuthenticated: isAuthenticatedAuth0,
    user: auth0User,
    getAccessTokenSilently,
    handleRedirectCallback,
  } = useAuth0()

  const [user, setUser] = useState<User>({} as User)
  const [isAuthenticatingApi, setIsAuthenticating] = useState(true)
  const [authFlowInfo, setAuthFlowInfo] = useState<CompleteFlow>()
  const [appState, setAppState] = useState<any>({})

  const logout = useCallback(async () => {
    auth0Logout({ returnTo: window.location.origin, client_id: AUTH0_CLIENT_ID })
    await localStorage.removeItem(AUTH_TOKEN_KEY)
  }, [auth0Logout])

  const updateAuthFlow = useCallback((userData) => {
    const flowInfo = checkForIncompleteCreateFlow(userData)
    setAuthFlowInfo(flowInfo)
  }, [])

  const authenticateToApi = useCallback(async () => {
    try {
      setIsAuthenticating(true)
      const token = await getAccessTokenSilently()
      await localStorage.setItem(AUTH_TOKEN_KEY, token)

      const userResponse = await userService(userRepo).authenticateUser({
        email: auth0User!.email!,
        email_verified: auth0User?.email_verified!,
        first_name: auth0User?.name,
      })

      if (!userResponse) throw new Error()

      const userRole = auth0User?.playershealth_roles[0] || userResponse?.type?.toLowerCase()
      setUser({ ...userResponse, role: userRole || undefined })

      updateAuthFlow(userResponse)
    } catch (err) {
      console.error(err)
      logout()
    } finally {
      setIsAuthenticating(false)
    }
  }, [auth0User, getAccessTokenSilently, logout])

  const getAppState = async () => {
    try {
      const result = await handleRedirectCallback()
      setAppState(result.appState || {})
    } catch (err) {
      console.log('no app state available')
      setAppState({})
    }
  }

  useEffect(() => {
    if (isAuthenticatedAuth0) {
      authenticateToApi()
      getAppState()
      return
    }
    if (!isAuthenticatedAuth0 && !isLoadingAuth0) setIsAuthenticating(false)
  }, [isAuthenticatedAuth0, isLoadingAuth0, authenticateToApi])

  const login = (args?: LoginArgs): Promise<void> => {
    let uri
    if (args?.redirectUri) uri = `${window.location.origin}/${args.redirectUri}`

    return loginWithRedirect({
      redirectUri: uri,
      screen_hint: args?.action,
      appState: args?.appState || {},
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        updateAuthFlow,
        login,
        logout,
        isLoadingAuth: isAuthenticatingApi,
        isAuthenticatedAuth0: isAuthenticatedAuth0,
        isAuthenticatedApi: !!authFlowInfo?.isComplete,
        authFlowInfo: authFlowInfo,
        appState,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
