import React, { FC } from 'react'
import { StyleSheet } from 'react-native'
import { Picker, PickerProps } from '@react-native-picker/picker'

import { COLORS, FONT_TYPES } from '@assets/constants'
import icon from '@assets/images/caret_down.png'
import { IInputItems } from '@views/components'

interface IPickerProps extends PickerProps {
  onChange: any
  name: string
  value: any
  items: IInputItems[]
  placeholder?: string
  pickerStyle?: {}
}

export const RNPicker: FC<IPickerProps> = ({
  value,
  onChange,
  items,
  placeholder,
  enabled,
  pickerStyle,
  ...rest
}) => {
  const hasPlaceholder = Boolean(placeholder)
  const hasSelected = !['', '--'].includes(value)
  return (
    <Picker
      selectedValue={value}
      onValueChange={(itemValue) => onChange(itemValue)}
      style={[
        styles.picker,
        hasSelected && styles.pickerTextSelected,
        !enabled && styles.disabled,
        pickerStyle,
      ]}
      enabled={enabled}
      {...rest}>
      {hasPlaceholder && <Picker.Item enabled={true} label={placeholder} value={'--'} />}
      {items.map((item, idx) => {
        return (
          <Picker.Item label={item.label} value={item.value} enabled={item.enabled} key={idx} />
        )
      })}
    </Picker>
  )
}

const styles = StyleSheet.create({
  picker: {
    color: COLORS.GRAY_700,
    height: 50,
    backgroundColor: COLORS.GRAY_200,
    borderColor: COLORS.GRAY_200,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 26,
    fontFamily: FONT_TYPES.FONT_REGULAR,
    appearance: 'none',
    backgroundImage: `url(${icon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right 7px',
  },
  disabled: {
    backgroundColor: COLORS.BACKGROUND,
    color: COLORS.DARK,
  },
  pickerTextSelected: {
    color: COLORS.DARK,
  },
})
