import React from 'react'
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter } from '@views/utils'
import { useAccount, useDeposits } from '@views/hooks'
import { BankingAccount } from '@domain/models'


export type teamsGeneralInfo = {
  teamName: string
  teamOwner: string
  teamBalance: number
}

const TeamTabs: React.FC<{}> = () => {
  const { subAccounts, currentSelectedAccount, onSelectCurrentAccount } = useAccount()
  const { getDeposits } = useDeposits()

  const handleSelectTeam = (account: BankingAccount) => {
    onSelectCurrentAccount(account)
    getDeposits(account.id)
  }

  return (
    <View>
      {subAccounts.length > 0 ? (
        <ScrollView
          horizontal={true}
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={styles.teamTabs}>
          {subAccounts.map((account) => {
            const isAccountSelected = currentSelectedAccount.id === account.id

            return (
              <TouchableOpacity
                key={account.id}
                style={isAccountSelected ? styles.teamTabSelected : styles.teamTab}
                onPress={() => handleSelectTeam(account)}>
                <View>
                  <AppText style={styles.teamName}>{account.nickname}</AppText>
                  <AppText
                    style={
                      styles.teamOwner
                    }>{`${account.user.first_name} ${account.user.last_name}`}</AppText>
                </View>
                <AppText style={styles.teamBalance}>
                  {currencyFormatter(account.available_balance / 100)}
                </AppText>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      ) : (
        <AppText style={styles.addSubAcc}>Add a sub-account to get started</AppText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  teamTabs: {
    alignItems: 'center',
  },
  teamTab: {
    borderRadius: 4,
    padding: 16,
    paddingVertical: 25,
    width: 270,
    marginRight: 8,
    marginBottom: 20,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: COLORS.WHITE,
  },
  teamTabSelected: {
    borderRadius: 4,
    padding: 16,
    paddingVertical: 25,
    width: 270,
    marginRight: 8,
    marginBottom: 20,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: COLORS.WHITE,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY,
  },
  teamName: {
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 5,
  },
  teamOwner: {
    fontSize: 12,
    fontWeight: '300',
  },
  teamBalance: {
    fontSize: 16,
    fontWeight: '800',
  },
  addSubAcc: {
    marginBottom: 30,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '400',
  },
})

export default TeamTabs
