import stepZeroImage from '@assets/images/checklist/step-0.png'
import stepOneImage from '@assets/images/checklist/step-1.png'
import stepTwoImage from '@assets/images/checklist/step-2.png'
import stepThreeImage from '@assets/images/checklist/step-3.png'
import stepFourImage from '@assets/images/checklist/step-4.png'
import stepFiveImage from '@assets/images/checklist/step-5.png'
import stepSixImage from '@assets/images/checklist/step-6.png'
import stepSevenImage from '@assets/images/checklist/step-7.png'
import progressOneImage from '@assets/images/checklist/step-1-of-7.png'
import progressTwoImage from '@assets/images/checklist/step-2-of-7.png'
import progressThreeImage from '@assets/images/checklist/step-3-of-7.png'
import progressFourImage from '@assets/images/checklist/step-4-of-7.png'
import progressFiveImage from '@assets/images/checklist/step-5-of-7.png'
import progressSixImage from '@assets/images/checklist/step-6-of-7.png'
import progressSevenImage from '@assets/images/checklist/step-7-of-7.png'

export const checklistData = [
  {
    image: stepZeroImage,
    title: 'Changing Bank Accounts - 7 steps checklist to make the process easy',
    content: 'This guide will help you change your bank account effectively!',
  },
  {
    image: stepOneImage,
    progress: progressOneImage,
    title: 'Make a list of linked accounts',
    content: `Chances are good you have other accounts linked to your bank account that allow you to make payments to different creditors or utility companies. You'll want to make sure you link all of those same companies with your new account so you can seamlessly pay the bills.
    \nIf you have savings accounts or brokerage accounts linked to your bank account so you can transfer money to them, you'll also want to make note of that as well so you can set up that connection again once you've changed your bank.`,
  },
  {
    image: stepTwoImage,
    progress: progressTwoImage,
    title: 'Review recent transactions for auto-debited bills',
    content: `You may also have your account set up so payments are auto-debited from your bank account. For example, your utility company or your gym may automatically pull your monthly payment directly from your checking account.
    \nYou'll need to update all of the companies you're paying`,
  },
  {
    image: stepThreeImage,
    progress: progressThreeImage,
    title: 'Look for outstanding checks',
    content: `If you have written any checks, you'll need to make sure that they clear before you close down your old account. Look back at your checkbook and make note of any checks that are still outstanding so you can follow up on them.`,
  },
  {
    image: stepFourImage,
    progress: progressFourImage,
    title: 'Open your new bank account',
    content: `You'll likely want to open your new bank account before you close your old one down so you'll be able to avoid a lag where you have no active banking relationship. You'll also need time to get everything switched over. So research your options and find a new bank that works for you - then get your new account opened up.`,
  },
  {
    image: stepFiveImage,
    progress: progressFiveImage,
    title: 'Transfer over most of your money, leaving a cushion in your old account',
    content: `Next, transfer most of the money from your existing account to your new one. Make sure to leave enough in the old account for any checks that haven't yet cleared, and leave a little extra in case there are payments you forgot about or in case companies don't change your auto payment right away.`,
  },

  {
    image: stepSixImage,
    progress: progressSixImage,
    title: 'Set up bill pay with your new bank',
    content: `Use your list of linked accounts and auto-debited bills to set up your new account and make sure it mirrors your old one. You can add all of your linked accounts, and notify the companies you do business with about your new account number.`,
  },
  {
    image: stepSevenImage,
    progress: progressSevenImage,
    title: 'Wait a month for outstanding checks to clear and close your old account down',
    content: `Finally, once all of your outstanding checks have cleared and you've changed over all your payment information, it's time to close down your old account for good. You should receive a check with any remaining funds and can deposit it in your new account and the changeover will be complete.\nOnce you've completed these seven steps, you should`,
  },
]

export const CHECKLIST_INITAL_STEP = 0
export const CHECKLIST_FINAL_STEP = checklistData.length - 1
