import React from 'react'
import { StyleSheet, View } from 'react-native'
import { AppText } from '@views/components'
import { useAuth } from '@views/hooks'
import { UserTypesEnum } from '@assets/constants'
import { openURL } from '@views/utils'

export const AdditionalLinks = () => {
  const { user } = useAuth()
  const isSubAdmin = user?.type === UserTypesEnum.SUB_ADMIN

  if (isSubAdmin) return null

  return (
    <View>
      <AppText semiBold>Additional Links</AppText>
      <View style={styles.links}>
        <AppText onPress={() => openURL('https://thread-bank.my.site.com/playershealthinquiry/s/')}>
          Apply for Loan
        </AppText>
        <AppText onPress={() => openURL('https://finance.playershealth.com/accounting/')}>
          Apply for Accounting Services
        </AppText>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  links: {
    textDecorationLine: 'underline',
    fontWeight: '300',
    marginTop: 16,
    display: 'flex',
    gap: 20,
  },
})
