import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { BusinessPageMobile, OtherPage, MakeDepositCheck, Statements } from '@views/pages'
import {
  BUSINESS_ACCOUNT_ROUTE,
  COLORS,
  mainNavPagesMobile,
  OTHER_ROUTE,
  MAKE_DEPOSIT_CHECK_ROUTE,
  TRANSACTIONS_ROUTE,
  STATEMENTS_ROUTE,
} from '@assets/constants'
import logo from '@assets/images/logo_color.png'
import { createStackNavigator } from '@react-navigation/stack'
import { AppText, DropdownPopover } from '@views/components'
import { useAuth } from '@views/hooks'
import { TransactionsMobilePage } from '@views/pages/TransactionsPage'
import { getRoleName } from '@views/utils'
import { Image, StyleSheet, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

const Tab = createBottomTabNavigator()
const Stack = createStackNavigator()

export const MobileNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen component={Tabs} name="MainTabs" />
      <Stack.Screen component={MakeDepositCheck} name={MAKE_DEPOSIT_CHECK_ROUTE} />
    </Stack.Navigator>
  )
}

const Tabs = () => (
  <Tab.Navigator
    screenOptions={({ route }) => {
      const activeTab =
        mainNavPagesMobile.find((tab) => tab.page === route.name) || mainNavPagesMobile[0]
      return {
        title: activeTab.title,
        tabBarStyle: styles.tabStyle,
        tabBarItemStyle: styles.tabItemStyle,
        header: Header,
        tabBarIcon: ({ color }) => activeTab.getIcon(color),
        tabBarActiveTintColor: COLORS.PRIMARY,
        tabBarInactiveTintColor: COLORS.GRAY_500,
        lazy: true,
      }
    }}>
    <Tab.Screen name={BUSINESS_ACCOUNT_ROUTE} component={BusinessPageMobile} />
    <Tab.Screen name={TRANSACTIONS_ROUTE} component={TransactionsMobilePage} />
    <Tab.Screen
      name={STATEMENTS_ROUTE}
      component={Statements}
      options={{
        tabBarIcon: () => <Ionicons name="md-folder-sharp" size={20} color={COLORS.GRAY_300} />,
        tabBarButton: ({ children, style }) => (
          <DropdownPopover from={<View style={style}>{children}</View>}>
            <View style={styles.disabledBottomTab}>
              <AppText style={styles.disabledText}>Accessible only on desktop</AppText>
            </View>
          </DropdownPopover>
        ),
        unmountOnBlur: true,
      }}
    />
    <Tab.Screen name={OTHER_ROUTE} component={OtherPage} />
  </Tab.Navigator>
)

const Header = () => {
  const { user } = useAuth()
  const hasRole = !!user?.type
  return (
    <View style={styles.headerContainer}>
      <Image source={logo} style={styles.logo} />
      <View>
        <AppText semiBold>{user?.first_name}</AppText>
        {hasRole && <AppText style={styles.role}>{getRoleName(user?.type)}</AppText>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  tabStyle: {
    height: 60,
    borderTopColor: 'transparent',
  },
  tabItemStyle: { padding: 10 },
  headerContainer: {
    backgroundColor: COLORS.WHITE,
    padding: 16,
    boxShadow: `inset 0px -1px 0px ${COLORS.BACKGROUND}`,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: { width: 126, height: 30, resizeMode: 'contain' },
  role: { color: COLORS.GRAY_700 },
  disabledText: {
    textAlign: 'center',
  },
  disabledBottomTab: {
    backgroundColor: COLORS.WHITE,
  },
})
