import React, { useEffect } from 'react'
import { View, StyleSheet, Modal, TouchableOpacity } from 'react-native'
import CameraPhoto from 'jslib-html5-camera-photo'
import { AntDesign } from '@expo/vector-icons'
import { COLORS } from '@assets/constants'

export type LandscapeCameraProps = {
  takePicture: () => void
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  closeCamera: () => Promise<void>
  showBorder?: boolean
  isCameraOpen: boolean
  startCamera: (newCam?: CameraPhoto) => void
}

export const LandscapeCamera: React.FC<LandscapeCameraProps> = ({
  takePicture,
  videoRef,
  closeCamera,
  showBorder = false,
  isCameraOpen,
  startCamera,
}) => {
  useEffect(() => {
    if (videoRef.current && isCameraOpen) {
      const newCam = new CameraPhoto(videoRef.current)
      startCamera(newCam)
    }
  }, [isCameraOpen])

  return (
    <Modal animationType="slide" visible={isCameraOpen} onRequestClose={closeCamera}>
      <View style={styles.videoContainer}>
        <TouchableOpacity onPress={async () => await closeCamera()} style={styles.closeBtn}>
          <AntDesign name="closesquare" size={20} color={COLORS.DARK} style={styles.closeIcon} />
        </TouchableOpacity>
        <View style={styles.borderContainer}>
          {showBorder && <View style={styles.border} />}
          <video
            style={{ objectFit: 'fill' }}
            width={'100%'}
            height={'100%'}
            autoPlay={true}
            ref={videoRef}
            contentEditable={false}
            controls={false}
            playsInline={true}
            unselectable={'on'}
          />
        </View>
        <TouchableOpacity onPress={takePicture} style={styles.takePhoto}>
          <AntDesign name="camera" size={32} color={COLORS.DARK} style={styles.cameraIcon} />
        </TouchableOpacity>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  videoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
  },
  border: {
    borderWidth: 2,
    borderColor: COLORS.WHITE,
    position: 'absolute',
    borderStyle: 'solid',
    width: '70%',
    height: '90%',
  },
  borderContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  closeBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    position: 'absolute',
    zIndex: 10,
    padding: 16,
  },
  takePhoto: {
    height: 60,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 0,
  },
  cameraIcon: {
    transform: [{ rotate: '90deg' }],
    borderColor: COLORS.DARK,
    borderWidth: 2,
    borderRadius: 100,
    padding: 4
  },
  closeIcon: {
    transform: [{ rotate: '90deg' }],
    backgroundColor: COLORS.WHITE,
    borderRadius: 100,
    padding: 4
  },
})
