import { Transactions } from '@domain/models'
import { UnauthenticatedError } from '@microservices/errors/http'
import { useAuth } from '@views/hooks'
import React, { createContext, FC, ReactNode, useCallback, useReducer } from 'react'
import { ActionType, getTransactionsAction } from './actions'

import { ITransactionsContext, TransactionsInitialState } from './interfaces'
import { TransactionsReducer } from './reducer'

interface ITransactionsContextProps {
  children: ReactNode
}

export const TransactionsContext = createContext<ITransactionsContext>({} as ITransactionsContext)

export const TransactionsProvider: FC<ITransactionsContextProps> = ({ children }) => {
  const [transactionsState, dispatch] = useReducer(TransactionsReducer, TransactionsInitialState)
  const { logout } = useAuth()

  const setTransactions = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.SET_TRANSACTIONS,
        payload,
      })
    },
    [dispatch]
  )

  const setLoading = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.SET_LOADING,
        payload,
      })
    },
    [dispatch]
  )

  const getTransactions = useCallback(
    async (params: Transactions): Promise<void> => {
      setLoading(true)
      try {
        await getTransactionsAction(params, setTransactions)
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
      }
      setLoading(false)
    },
    [setTransactions, setLoading]
  )

  const value = {
    transactionsState,
    getTransactions,
  }
  return <TransactionsContext.Provider value={value}>{children}</TransactionsContext.Provider>
}
