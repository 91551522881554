import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'

import { AppText, RenderRow, Form, ButtonRow, ErrorMessage } from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { basicInfoFields, BasicInfoSchema, IBasicInfo } from '@views/schemas'
import { useCreateAccount, useAuth, useSystemConfig } from '@views/hooks'
import { getDirtyPayload } from '@views/utils'
import Header from './Header'

const BasicInfo = ({ navigation }: CreateAccountStackScreenProps<'basicInfo'>) => {
  const { authFlowInfo, logout } = useAuth()
  const { createAccountState, postBasicInfo, patchBasicInfo, getBasicInfo } = useCreateAccount()
  const { loading, error } = createAccountState
  let isPersisted = authFlowInfo?.isBasicInfoComplete
  const hasBankAccount = authFlowInfo?.hasBankAccount

  const {
    handleSubmit,
    control,
    clearErrors,
    reset,
    formState: { errors, isSubmitSuccessful, isDirty, dirtyFields, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(BasicInfoSchema),
  })

  const onSubmit = async (data: any) => {
    if (hasBankAccount) return
    clearErrors()
    if (!isPersisted) {
      await postBasicInfo(data)
      return
    }

    if (isPersisted && isDirty) {
      const payload = getDirtyPayload(data, dirtyFields)
      if (payload) await patchBasicInfo(payload)
    }
    return
  }

  const resetForm = useCallback(
    (keepValues = false, persistedData?: IBasicInfo) => {
      let { basicInfo } = createAccountState.data
      if (!basicInfo && persistedData) basicInfo = persistedData

      if (basicInfo)
        reset(
          {
            ...basicInfo,
            orgFormationDate:
              basicInfo.orgFormationDate !== ''
                ? moment(basicInfo.orgFormationDate).format('MM/DD/YYYY')
                : '',
            orgPhone:
              // @ts-ignore
              basicInfo.orgPhone.match(/\+1/)?.length === 1
                ? basicInfo.orgPhone.substring(2).trim()
                : basicInfo.orgPhone,
            orgEIN: basicInfo.orgEIN.replace('-', ''),
          },
          {
            keepValues: keepValues,
          }
        )
    },
    [createAccountState, reset]
  )

  // @TODO if email verification is required uncomment this code
  // const closeAndLogout = () => {
  //   closeModal()
  //   logout()
  // }

  useEffect(() => {
    if (createAccountState.data?.basicInfo) {
      resetForm(false, createAccountState.data?.basicInfo)
      return
    }
    if (isPersisted) getBasicInfo().then((persistedData) => resetForm(false, persistedData))
    // @TODO if email verification is required uncomment this code
    // else if (!user?.email_verified)
    //   openModal({
    //     dismiss: false,
    //     type: 'warning',
    //     title: 'Your email has not been verified',
    //     subtitle: 'Please check your email inbox. Be sure to check your spam folders.',
    //     onDismiss: () => closeAndLogout(),
    //     buttons: [
    //       <Button
    //         key={'BOInfo_success_modal'}
    //         style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
    //         type="primary"
    //         text="Logout"
    //         onPress={closeAndLogout}
    //       />,
    //     ],
    //   })
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful && error === '') {
      navigation.navigate('adminInfo')
      resetForm(true)
    }
  }, [isSubmitSuccessful, error, navigation, reset])

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <Header subroute="Basic info" />
        <Form showDisclaimer>
          <RenderRow
            fields={[basicInfoFields.orgLegalName, basicInfoFields.orgType]}
            disabled={
              hasBankAccount ? [basicInfoFields.orgLegalName, basicInfoFields.orgType] : undefined
            }
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgEIN,
              basicInfoFields.orgFormationDate,
              basicInfoFields.orgFormationState,
            ]}
            disabled={
              hasBankAccount
                ? [
                    basicInfoFields.orgEIN,
                    basicInfoFields.orgFormationDate,
                    basicInfoFields.orgFormationState,
                  ]
                : undefined
            }
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgPhone,
              basicInfoFields.orgEmail,
              basicInfoFields.orgLegalStructure,
            ]}
            disabled={
              hasBankAccount
                ? [
                    basicInfoFields.orgPhone,
                    basicInfoFields.orgEmail,
                    basicInfoFields.orgLegalStructure,
                  ]
                : undefined
            }
            errors={errors}
            control={control}
          />
          <View style={styles.horizontalRule} />
          <AppText bold style={styles.subTitle2}>
            Organization legal address
          </AppText>
          <RenderRow
            fields={[basicInfoFields.orgAddrStreet1, basicInfoFields.orgAddrStreet2]}
            disabled={
              hasBankAccount
                ? [basicInfoFields.orgAddrStreet1, basicInfoFields.orgAddrStreet2]
                : undefined
            }
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[
              basicInfoFields.orgAddrCity,
              basicInfoFields.orgAddrState,
              basicInfoFields.orgAddrZip,
            ]}
            disabled={
              hasBankAccount
                ? [
                    basicInfoFields.orgAddrCity,
                    basicInfoFields.orgAddrState,
                    basicInfoFields.orgAddrZip,
                  ]
                : undefined
            }
            errors={errors}
            control={control}
          />
        </Form>
      </View>
      {error !== '' && <ErrorMessage error={error} />}
      <ButtonRow
        loading={loading || isSubmitting}
        buttonLabel={loading || isSubmitting ? 'Loading...' : 'Next'}
        onSubmit={(e: SyntheticEvent) => {
          handleSubmit(onSubmit)(e).catch((err) => {
            console.error('hs', { err, isSubmitSuccessful })
          })
        }}
        onBack={logout}
      />
    </ScrollView>
  )
}

export default BasicInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: '#FFF',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  horizontalRule: {
    width: '100%',
    height: 1,
    backgroundColor: '#EEEEF0',
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
