import { PlaidLinkToken } from '@domain/models'

export interface LinkTokenRequestDTO {
  user_id: string
  given_name?: string
  family_name?: string
}

export interface LinkTokenResponseDTO {
  link_token: string
  expiration: string
  request_id: string
}

export const linkTokenDtoToLinkToken = (data: LinkTokenResponseDTO): PlaidLinkToken => ({
  expiration: data.expiration,
  link_token: data.link_token,
  request_id: data.request_id,
})
