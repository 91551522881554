import React, { SyntheticEvent, useEffect } from 'react'
import { AppText, BOForm, Button, ButtonRow, closeModal, openModal } from '@views/components'
import { useForm } from 'react-hook-form'
import { beneficialOwnerFields } from '@views/schemas'
import { ScrollView, View, StyleSheet } from 'react-native'
import { useAuth, useCreateAccount, useDisclaimer, useSystemConfig } from '@views/hooks'
import { COLORS } from '@assets/constants'
import { checkBOFormData, formatBOFormData } from '@views/utils'
import { CreateAccountStackScreenProps } from '@views/navigation/types'

const BeneficialOwnersFromForEmail =
  ({}: CreateAccountStackScreenProps<'beneficialOwnersFromEmail'>): JSX.Element => {
    const {
      control,
      setError,
      setValue,
      formState: { errors, isSubmitSuccessful, isSubmitting },
      handleSubmit,
    } = useForm()
    const { patchOwnerInfo } = useCreateAccount()
    const { user, logout } = useAuth()
    const { openDisclaimers } = useDisclaimer()
    const { isSmallDevice } = useSystemConfig()

    const onSubmit = (data: any) => {
        let totalPercent = 0
        if (user?.association?.Users && user.association.Users.length > 0) totalPercent = user.association.Users.reduce((previousValue, currentValue) => {
            if (!!currentValue.percent_ownership) previousValue += currentValue.percent_ownership
            return previousValue
        }, 0)
      if (checkBOFormData(data, setError, totalPercent))
        openDisclaimers({
          onAccepted: () => {
            patchOwnerInfo(formatBOFormData(data, 1)).then(() => {
              openModal({
                dismiss: false,
                type: 'success',
                title: 'Thank You for Your Information',
                subtitle:
                  'Your work here is done! Please contact your organization admin if you have any further questions.',
                buttons: [
                  <Button
                    key={'BOInfo_success_modal'}
                    style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
                    type="primary"
                    text="Logout"
                    onPress={() => {
                      closeModal()
                      logout()
                    }}
                  />,
                ],
              })
            })
          },
        })
    }

    useEffect(() => {
      setValue('emailBO1', user?.email)
    }, [])

    return (
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
        {user?.id_synctera || !user?.email ? (
          <>
            <AppText>
              {!user?.email
                ? 'Sorry. We could not find your information.'
                : 'You already filled out your information. If you need to update anything please get in touch with your admin.'}
            </AppText>
            <ButtonRow buttonLabel="Logout" onSubmit={logout} onBack={logout} />
          </>
        ) : (
          <>
            <View>
              <BOForm
                form={beneficialOwnerFields(1)}
                control={control}
                errors={errors}
                formIdx={-1}
                checked={false}
              />
            </View>
            <ButtonRow
              loading={isSubmitting}
              buttonLabel={isSubmitting ? 'Loading...' : 'Submit'}
              onSubmit={(e: SyntheticEvent) => {
                handleSubmit(onSubmit)(e).catch((err) => {
                  console.error('hs', { err, isSubmitSuccessful })
                })
              }}
              onBack={logout}
            />
          </>
        )}
      </ScrollView>
    )
  }

export default BeneficialOwnersFromForEmail
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
