import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import { PopoverPlacement } from 'react-native-popover-view'
import {
  AppText,
  openModal,
  DetailsModal,
  PermissionContainer,
  CardWidget,
  LoadingSpinner,
  DropdownPopover,
} from '@views/components'
import { COLORS, UserTypesEnum } from '@assets/constants'
import activateCard from '@assets/images/activate-card-icon.png'
import createPayment from '@assets/images/create-payment-icon.png'
import issueCard from '@assets/images/issue-card-icon.png'
import makeDeposit from '@assets/images/make-deposit-icon.png'
import infoIcon from '@assets/images/exclamation-icon.png'
import AccountInformation from './AccountInformation'
import { currencyFormatter, getRoleName, toTitleString } from '@views/utils'
import IssueCard from './IssueCard'
import { CreatePayment } from '@views/components/CreatePayment'
import { MakeDeposit } from '@views/components/MakeDeposit'
import { useAccount, useAuth, useMemberCards, useSystemConfig } from '@views/hooks'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { CardsProvider } from '@views/contexts'
import { UnauthenticatedError } from '@microservices/errors/http'

const BusinessAccountInfo: React.FC = () => {
  const [showDepositsTab, setShowDepositsTab] = useState(false)
  const { isSmallDevice } = useSystemConfig()
  const { user, logout } = useAuth()
  const { currentSelectedAccount } = useAccount()
  const hasRole = !!user?.type
  // @TODO use this later
  // @ts-ignore
  // const isSubAdmin = user?.type === UserTypesEnum.SUB_ADMIN
  const { getMemberCards } = useMemberCards()
  const [infoIconHover, setInfoIconHover] = useState(false)
  const [createPaymentHover, setCreatePaymentHover] = useState(false)
  const [issueCardHover, setIssueCardHover] = useState(false)
  const [makeDepositHover, setMakeDepositHover] = useState(false)
  const [activateCardHover, setActivateCardHover] = useState(false)

  const openCreatePaymentModal = () => {
    const containerStyle = isSmallDevice
      ? { height: '90%', width: '90%', maxHeight: 670 }
      : { maxWidth: 670 }

    openModal({
      contentStyle: containerStyle,
      content: <CreatePayment />,
      dismiss: false,
    })
  }

  const openMakeDepositModal = () => {
    const containerStyle = isSmallDevice
      ? { height: '90%', width: '90%', maxHeight: 670 }
      : { maxWidth: 670 }
    openModal({
      contentStyle: containerStyle,
      content: <MakeDeposit />,
      dismiss: false,
    })
  }

  const details = [
    {
      label: 'Account name',
      value: currentSelectedAccount.nickname,
    },
    {
      label: 'Account number',
      value: currentSelectedAccount.account_number,
      isMasked: true,
    },
    {
      label: 'Routing number',
      value: currentSelectedAccount.bank_routing,
    },
    {
      label: 'Account type',
      value: toTitleString(currentSelectedAccount.account_type),
    },
  ]

  const onAccountInfoClick = () => {
    openModal({
      dismiss: true,
      title: 'Account Details',
      content: <DetailsModal content={details} />,
    })
  }

  const handleActivateCard = async () => {
    if (!user?.id) return

    openModal({
      content: <LoadingSpinner />,
      dismiss: false,
    })

    try {
      const newCardWidgetURL = await bankingService(bankingRepo).getCardWidgetURL(
        user.id,
        'activate_card'
      )
      if (newCardWidgetURL?.url)
        openModal({
          content: <CardWidget url={newCardWidgetURL.url} />,
        })
      else if (newCardWidgetURL?.url === '')
        openModal({
          type: 'warning',
          title: 'No Cards Have Been Issued',
        })
      else
        openModal({
          type: 'warning',
          title: 'An Error Has Occured',
          subtitle: 'Please try again',
        })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.headerContainer}>
        <View style={styles.optionContainer}>
          <AppText style={styles.clubTeamName}>{currentSelectedAccount.nickname}</AppText>
          <TouchableOpacity
            {...{
              onMouseEnter: () => setInfoIconHover(true),
              onMouseLeave: () => setInfoIconHover(false),
            }}
            onPress={onAccountInfoClick}
            style={[
              styles.infoIconContainer,
              { backgroundColor: infoIconHover ? COLORS.PRIMARY : COLORS.GRAY_700 },
            ]}>
            <Image style={styles.infoIcon} source={infoIcon} />
          </TouchableOpacity>
        </View>
        <DropdownPopover
          from={
            <View style={styles.teamMainInfo}>
              <View style={styles.teamMainInfoText}>
                <AppText style={styles.ownerName}>{user?.first_name}</AppText>
                {hasRole && <AppText style={styles.ownerRole}>{getRoleName(user?.type)}</AppText>}
              </View>
              <MaterialCommunityIcons name="chevron-down" size={20} color={COLORS.GRAY_700} />
            </View>
          }
          placement={PopoverPlacement.BOTTOM}>
          <TouchableOpacity style={styles.popoverContainer} onPress={() => logout()}>
            <Ionicons name="exit" size={20} color={COLORS.GRAY_700} />
            <AppText style={styles.logoutText}>Log out</AppText>
          </TouchableOpacity>
        </DropdownPopover>
      </View>

      <AppText style={styles.balance}>
        {currencyFormatter(currentSelectedAccount.available_balance / 100)}
      </AppText>
      <AppText style={styles.availableBalance}>Available debit balance</AppText>

      <View style={styles.optionsContainer}>
        <TouchableOpacity
          {...{
            onMouseEnter: () => setCreatePaymentHover(true),
            onMouseLeave: () => setCreatePaymentHover(false),
          }}
          style={styles.optionContainer}
          onPress={openCreatePaymentModal}>
          <View
            style={[
              { backgroundColor: createPaymentHover ? COLORS.CYAN_100 : COLORS.CYAN_200 },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={createPayment} />
          </View>
          <AppText style={styles.optionsTitles}>Create a payment</AppText>
        </TouchableOpacity>

        <PermissionContainer>
          <TouchableOpacity
            {...{
              onMouseEnter: () => setIssueCardHover(true),
              onMouseLeave: () => setIssueCardHover(false),
            }}
            onPress={() => {
              openModal({
                dismiss: false,
                content: (
                  <CardsProvider>
                    <IssueCard onCardIssued={getMemberCards} />
                  </CardsProvider>
                ),
              })
            }}
            style={styles.optionContainer}>
            <View
              style={[
                { backgroundColor: issueCardHover ? COLORS.PURPLE_DARK : COLORS.PURPLE_LIGHT },
                styles.optionsIconContainer,
              ]}>
              <Image style={styles.optionsIcon} source={issueCard} />
            </View>
            <AppText style={styles.optionsTitles}>Issue card</AppText>
          </TouchableOpacity>
        </PermissionContainer>

        <TouchableOpacity
          {...{
            onMouseEnter: () => setMakeDepositHover(true),
            onMouseLeave: () => setMakeDepositHover(false),
          }}
          onPress={openMakeDepositModal}
          style={styles.optionContainer}>
          <View
            style={[
              { backgroundColor: makeDepositHover ? COLORS.BLUE_200 : COLORS.BLUE_300 },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={makeDeposit} />
          </View>
          <AppText style={styles.optionsTitles}>Make a deposit</AppText>
        </TouchableOpacity>

        <TouchableOpacity
          {...{
            onMouseEnter: () => setActivateCardHover(true),
            onMouseLeave: () => setActivateCardHover(false),
          }}
          onPress={handleActivateCard}
          style={styles.optionContainer}>
          <View
            style={[
              { backgroundColor: activateCardHover ? COLORS.ORANGE_DARK : COLORS.ORANGE_LIGHT },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={activateCard} />
          </View>
          <AppText style={styles.optionsTitles}>Activate card</AppText>
        </TouchableOpacity>
      </View>

      <View style={styles.tabsContainer}>
        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(false)
          }}>
          <View style={showDepositsTab ? styles.tabContainer : styles.tabContainerSelected}>
            <AppText
              style={[styles.tab, { color: !showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Member cards
            </AppText>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(true)
          }}>
          <View style={showDepositsTab ? styles.tabContainerSelected : styles.tabContainer}>
            <AppText
              style={[styles.tab, { color: showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Deposits
            </AppText>
          </View>
        </TouchableOpacity>
      </View>

      <AccountInformation showDepositsTab={showDepositsTab} />
    </View>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    height: '100%',
    flexGrow: 1,
    padding: 30,
    backgroundColor: COLORS.WHITE,
  },
  headerContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionsContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 24,
  },
  clubTeamName: {
    fontSize: 24,
    fontWeight: '700',
    color: COLORS.GRAY_700,
  },
  infoIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    height: 20,
    width: 20,
    borderRadius: 4,
  },
  infoIcon: {
    resizeMode: 'contain',
    height: 10,
    width: 10,
  },
  teamMainInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  teamMainInfoText: {
    flexDirection: 'column',
  },
  ownerName: {
    fontSize: 14,
    fontWeight: '600',
  },
  balance: {
    fontWeight: '700',
    fontSize: 22,
    marginBottom: 10,
  },
  availableBalance: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 30,
  },
  optionsIconContainer: {
    borderRadius: 10,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsIcon: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  optionsTitles: {
    fontWeight: '500',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 10,
  },
  tabsContainer: {
    paddingVertical: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  tabContainer: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
  },
  tabContainerSelected: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.PRIMARY,
  },
  tab: {
    fontSize: 16,
    fontWeight: '600',
  },
  ownerRole: { fontSize: 12 },
  popoverContainer: {
    backgroundColor: '#FFF',
    width: 120,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logoutText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
})

export default BusinessAccountInfo
