import { Divider } from '@rneui/base'
import React, { useState } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'

import { COLORS, UserTypesEnum } from '@assets/constants'
import checklistImage from '@assets/images/checklist/thumbnail.png'
import { visitSupportCenter } from '@views/helpers'
import { useAuth, useSystemConfig } from '@views/hooks'
import { AdditionalLinks, AppText, Button } from '@views/components'
import Checklist from './Checklist'

interface Props {
  onStartChecklist?: () => void
}

const HelpCenter: React.FC<Props> = ({ onStartChecklist }) => {
  const { isSmallDevice } = useSystemConfig()
  const [openChecklist, setOpenChecklist] = useState(false)
  const { user } = useAuth()
  const isSubAdmin = user?.type === UserTypesEnum.SUB_ADMIN

  const startChecklist = () => {
    setOpenChecklist(true)
    onStartChecklist?.()
  }

  return (
    <View style={isSmallDevice ? styles.containerMobile : styles.container}>
      {!isSubAdmin && openChecklist && !isSmallDevice ? (
        <Checklist />
      ) : (
        <>
          <View>
            <AppText bold style={isSmallDevice ? styles.helpCenterMobile : styles.helpCenter}>
              Help center
            </AppText>
            {!isSmallDevice && <Divider style={styles.divider} />}
            {!isSubAdmin && (
              <>
                <AppText semiBold style={styles.usefulResources}>
                  Useful resources
                </AppText>
                <TouchableOpacity style={styles.checklist} onPress={startChecklist}>
                  <Image source={checklistImage} style={styles.checklistImage} />
                  <View style={styles.checklistText}>
                    <AppText style={styles.checklistStep}>
                      Changing Bank Accounts - 7 step checklist to make the process easy
                    </AppText>
                    <AppText light style={styles.checklistDuration}>
                      15 minutes
                    </AppText>
                  </View>
                </TouchableOpacity>
              </>
            )}
          </View>
          <AdditionalLinks />
          <View>
            <AppText semiBold>Contact us</AppText>
            <AppText style={styles.haveQuestions}>
              Have questions?{'\n'}Visit the Players Health support center.
            </AppText>
            <Button
              text="Visit Support Center"
              onPress={visitSupportCenter}
              style={[styles.visitButton, !isSmallDevice ? styles.visitButtonDesktop : {}]}
            />
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerMobile: {
    display: 'flex',
    gap: 32,
  },
  helpCenter: {
    fontSize: 18,
    marginTop: -30,
    marginRight: 30,
    marginBottom: 12,
  },
  helpCenterMobile: {
    fontSize: 18,
    marginBottom: 4,
  },
  divider: {
    backgroundColor: COLORS.GRAY_300,
    marginTop: 12,
    marginRight: -20,
    marginLeft: -20,
  },
  usefulResources: {
    marginTop: 20,
    lineHeight: 21,
  },
  checklist: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.VIOLET_600,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    marginTop: 8,
    padding: 8,
    paddingRight: 32,
  },
  checklistImage: {
    width: 80,
    height: 52,
  },
  checklistText: {
    flex: 1,
    justifyContent: 'space-between',
  },
  checklistStep: {
    fontSize: 12,
    lineHeight: 18,
  },
  checklistDuration: {
    color: COLORS.GRAY_700,
    fontSize: 10,
    lineHeight: 15,
    marginTop: 2,
  },
  haveQuestions: {
    marginTop: 8,
    lineHeight: 21,
  },
  visitButton: {
    marginTop: 16,
    marginBottom: 20,
    paddingTop: 9,
    paddingBottom: 9,
    paddingRight: 40,
    paddingLeft: 40,
    height: 'fit-content',
  },
  visitButtonDesktop: {
    width: 'fit-content',
  },
})

export default HelpCenter
