import React, { useState } from 'react'
import { StyleSheet, View, Image } from 'react-native'

import {
  AppText,
  Button,
  closeModal,
  openModal,
  QRCodeModal,
  TakePictures,
} from '@views/components'
import { useAuth, useSystemConfig } from '@views/hooks'
import logo from '@assets/images/logo_color.png'
import { userService } from '@domain/service'
import { userRepo } from '@microservices/instances'
import { createFileUploadOptions } from '@views/utils'
import { Documents } from '@domain/models'
import { UnauthenticatedError } from '@microservices/errors/http'

enum IDTypes {
  PASSPORT = 'PASSPORT',
  LICENSE = 'LICENSE',
}

const PersonalDocuments = () => {
  const [IDType, setIDType] = useState<IDTypes | null>(null)
  const [front, setFront] = useState<string | undefined>(undefined)
  const [back, setBack] = useState<string | undefined>(undefined)
  const [isFront, setIsFront] = useState<boolean>(true)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isMobile } = useSystemConfig()

  const { user, logout, setUser } = useAuth()

  const onMobile = isMobile()

  const resetState = () => {
    setIDType(null)
    setFront(undefined)
    setBack(undefined)
    setIsFront(true)
    setIsComplete(false)
    setIsSubmitting(false)
  }

  const handleNavigate = (documents: Documents[]) => {
    const isFinshed = documents.length >= 3

    openModal({
      type: 'success',
      dismiss: false,
      title: 'Success',
      subtitle: isFinshed
        ? 'All documents have been sent for review.'
        : `Your ${IDType?.toLowerCase()} was successfully sent for review.`,
      buttons: [
        <Button
          type="primary"
          text={isFinshed ? 'Log out' : 'Back to personal documents'}
          onPress={() => {
            closeModal()
            isFinshed ? logout() : resetState()
          }}
        />,
      ],
      onDismiss: () => {
        closeModal()
        isFinshed ? logout() : resetState()
      },
    })
  }

  const handleSubmit = async () => {
    openModal({
      title: 'Hang tight, we are working to secure your documents.',
      type: 'warning',
      dismiss: false,
    })
    const images = [front, back]
    setIsSubmitting(true)
    const promises = await Promise.allSettled(
      images
        .filter((file) => !!file)
        .map(async (file) => {
          const description =
            file === front ? 'Personal ID File (Front)' : 'Personal ID File (Back)'

          const payload = createFileUploadOptions(description, file)
          try {
            return await userService(userRepo).postPersonalDocuments(user?.id, payload)
          } catch (err) {
            if (err instanceof UnauthenticatedError) {
              logout()
              return
            }
          }
        })
    )

    const newDocuments = user?.Documents?.length ? [...user.Documents] : []
    promises.map((p) => {
      if (p.status === 'fulfilled' && p.value !== undefined) {
        newDocuments.push(p.value)
      }
    })

    setUser((prevUser) => ({ ...prevUser, Documents: newDocuments }))
    if (promises[0].status === 'fulfilled') {
      handleNavigate(newDocuments)
    }
    setIsSubmitting(false)
  }

  if (!onMobile)
    openModal({
      contentStyle: styles.modalStyle,
      dismiss: false,
      content: (
        <QRCodeModal
          component={'personalDocuments'}
          showLogo={true}
          title={'Personal Information Required'}
          instructions={'In order to verify your organization, please upload additional documents'}
          documentsNeeded={'documents'}
          btnTitle={'Refresh'}
        />
      ),
    })

  const getTitle = () => {
    let titleMessage
    if (IDType === null)
      titleMessage = 'In order to verify your organization, please upload additional documents'
    else if (IDType === IDTypes.PASSPORT)
      titleMessage = 'Please take a photo of the information page of your passport'
    else titleMessage = `Please take a photo of the ${isFront ? 'front' : 'back'} of your lincense.`

    return titleMessage
  }

  const getIDType = () => {
    return (
      <>
        <View style={styles.btnRow}>
          {user?.Documents && user.Documents.length >= 3 ? (
            <>
              <AppText style={styles.title} bold>
                All documents have been sent for review
              </AppText>
              <Button
                style={styles.marginBtm}
                type={'secondary'}
                onPress={() => logout()}
                text={'Log out'}
              />
            </>
          ) : user?.Documents && user.Documents.length == 2 ? (
            <Button
              style={styles.marginBtm}
              type={'secondary'}
              onPress={() => setIDType(IDTypes.PASSPORT)}
              text={'Passport'}
            />
          ) : user?.Documents && user.Documents.length ? (
            <Button
              style={styles.marginBtm}
              type={'secondary'}
              onPress={() => setIDType(IDTypes.LICENSE)}
              text={'License'}
            />
          ) : (
            <>
              <Button
                style={styles.marginBtm}
                type={'secondary'}
                onPress={() => setIDType(IDTypes.PASSPORT)}
                text={'Passport'}
              />
              <Button
                style={styles.marginBtm}
                type={'secondary'}
                onPress={() => setIDType(IDTypes.LICENSE)}
                text={'License'}
              />
            </>
          )}
        </View>
      </>
    )
  }

  const getIDPictures = () => {
    return (
      <View style={styles.picturesContainer}>
        <TakePictures
          setFront={setFront}
          front={front}
          setBack={setBack}
          back={back}
          setIsComplete={setIsComplete}
          takeBackSide={IDType === IDTypes.LICENSE}
          showBorder={false}
        />
        <Button
          onPress={handleSubmit}
          text={isSubmitting ? 'Loading...' : 'Submit'}
          disabled={!isComplete}
          loading={isSubmitting}
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <Image source={logo} style={styles.logo} />
        <AppText style={styles.title} bold>
          {getTitle()}
        </AppText>
      </View>
      {IDType === null ? getIDType() : getIDPictures()}
    </View>
  )
}

export default PersonalDocuments

const styles = StyleSheet.create({
  paddedText: {
    padding: 20,
  },
  container: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  picturesContainer: {
    paddingBottom: 16,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    width: 101,
    height: 23,
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  titleView: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  btnRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    marginTop: 120,
  },
  marginBtm: {
    marginBottom: 15,
  },
  modalStyle: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '75vh',
  },
})
