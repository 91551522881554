import { CardProductsResponse, IssueCardBody } from '@domain/models'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { Dispatch } from 'react'

export enum ActionType {
  SET_CARD_PRODUCTS_LOADING = 'SET_CARD_PRODUCTS_LOADING',
  SET_CARD_PRODUCTS = 'SET_CARD_PRODUCTS',
  SET_SUBMIT_LOADING = 'SET_SUBMIT_LOADING',
}

export interface IAction {
  type: ActionType
  payload?: any
}

export const getCardProductsActiion = async (
  dispatchCallback: Dispatch<CardProductsResponse | undefined>
): Promise<void> => {
  const cardProducts = await bankingService(bankingRepo).getCardProducts()
  dispatchCallback(cardProducts)
}
export const submitIssueCardAction = async (body: IssueCardBody): Promise<void> => {
  await bankingService(bankingRepo).submitIssueCard(body)
}

export type CardActions = IAction
