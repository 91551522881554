import React from 'react'
import { View, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { IBeneficialOwnersInfoFields } from '@views/schemas'
import { RenderRow, Row, AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import Checkbox from 'expo-checkbox'

type Props = {
  form: IBeneficialOwnersInfoFields
  control: any
  errors: any
  removeForm?: (idx: number) => any
  formIdx?: number
  checked?: boolean
  changeFormType?: any
  type?: 'info' | 'invite'
  hasBankAccount?: boolean
}

export const BOForm: React.FC<Props> = ({
  form,
  control,
  errors,
  removeForm,
  formIdx = 0,
  checked = false,
  changeFormType,
  type,
  hasBankAccount,
}) => {
  const isInvite = type && type === 'invite'
  return (
    <View style={styles.formWrapper} key={`owner-${formIdx + 1}-form`}>
      <Row>
        <AppText bold style={styles.subHeader}>
          {`Beneficial owner ${formIdx === -1 ? 'info' : formIdx + 1}`}
        </AppText>
        {formIdx > 0 && removeForm !== undefined && (
          <TouchableOpacity onPress={() => removeForm(formIdx)}>
            <AppText style={styles.removeOwnerText}>Remove</AppText>
          </TouchableOpacity>
        )}
      </Row>
      {type !== undefined && (
        <Row style={styles.checkBoxWrapper}>
          <Checkbox
            value={isInvite}
            disabled={checked}
            onValueChange={() => changeFormType(formIdx)}
            style={styles.checkBox}
            color={!isInvite ? COLORS.BACKGROUND : COLORS.LIGHT_GREEN}
          />
          <AppText>Send an email</AppText>
        </Row>
      )}
      <View>
        <RenderRow
          fields={[form.firstName, form.lastName]}
          errors={errors}
          control={control}
          disabled={checked || hasBankAccount ? [form.firstName, form.lastName] : undefined}
        />
        <RenderRow
          fields={[form.phone, form.email, form.percentageOwnership]}
          disabled={
            checked
              ? [form.phone, form.email]
              : hasBankAccount
              ? [form.phone, form.email, form.percentageOwnership]
              : formIdx === -1
              ? [form.email]
              : undefined
          }
          errors={errors}
          control={control}
        />
        <RenderRow
          fields={[form.ssn, form.dob]}
          errors={errors}
          control={control}
          disabled={checked || hasBankAccount ? [form.ssn, form.dob] : undefined}
        />
        <RenderRow
          fields={[form.addrStreet1, form.addrStreet2]}
          disabled={checked || hasBankAccount ? [form.addrStreet1, form.addrStreet2] : undefined}
          errors={errors}
          control={control}
        />
        <RenderRow
          fields={[form.addrCity, form.addrState, form.addrZip]}
          disabled={
            checked || hasBankAccount ? [form.addrCity, form.addrState, form.addrZip] : undefined
          }
          errors={errors}
          control={control}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  subHeader: {
    fontSize: 16,
    marginRight: 25,
  },
  formWrapper: {
    borderBottomColor: COLORS.BACKGROUND,
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  removeOwnerText: {
    color: COLORS.PRIMARY,
    fontSize: 14,
    marginLeft: 20,
    marginTop: 2,
    textDecorationLine: 'underline',
  },
  checkBoxWrapper: {
    marginVertical: 10,
  },
  checkBox: {
    marginRight: 10,
  },
})
