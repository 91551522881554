import React from 'react'

import { Result } from '@domain/models/Transactions'
import { COLORS } from '@assets/constants'
import { StyleSheet, View } from 'react-native'
import { AppText, closeModal } from '@views/components'
import { currencyFormatter } from '@views/utils'
import moment from 'moment'
import { AntDesign } from '@expo/vector-icons'
import { mapTransactionTypes } from '@assets/constants/trasactionTypes'
import { mapSubtypes } from '@views/helpers/mapSubTypes'

interface ViewDetailsProps {
  result: Result
}

export const ViewDetails = ({ result }: ViewDetailsProps) => {
  const handleXClick = () => {
    return closeModal()
  }

  const isPosted = !!result?.posted_date

  return (
    <>
      <View style={styles.header}>
        <AppText style={styles.title}>Transaction Details</AppText>
        <AntDesign
          name="closesquare"
          size={24}
          color={COLORS.GRAY_700}
          onPress={() => handleXClick()}
        />
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>{isPosted ? 'Posted' : 'Initiated'} date</AppText>
        <AppText style={styles.subtitleFont}>
          {moment(new Date(result?.posted_date || result?.created)).format('MM/DD/YY')}
        </AppText>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Transaction type</AppText>
        <View style={styles.typeWrapper}>
          <AppText>{mapTransactionTypes(result?.data?.type || result?.type)}</AppText>
        </View>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Initated by</AppText>
        <AppText style={styles.initiatedByFont}>
          {result?.data?.user_data?.user_name_synctera || ''}
        </AppText>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Amount</AppText>
        <AppText style={styles.subtitleFont}>
          {result?.data?.amount
            ? currencyFormatter(result?.data?.amount / 100)
            : result?.data?.lines?.[0]?.amount
            ? currencyFormatter(result?.data?.lines?.[0]?.amount / 100)
            : null}
        </AppText>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Sub-type</AppText>
        <AppText style={styles.subtitleFont}>
          {mapSubtypes(result?.subtype || result?.data?.subtype)}
        </AppText>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Status</AppText>
        <AppText style={styles.statusFont}>{result?.status}</AppText>
      </View>
      <View style={styles.divider} />
      <View style={styles.infoRow}>
        <AppText style={styles.titleFont}>Comments</AppText>
        <AppText style={styles.subtitleFont}>{result?.data?.memo}</AppText>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
    marginBottom: 24,
  },
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.LIGHT,
    paddingHorizontal: 30,
  },
  scrollViewStyle: {
    marginHorizontal: -30,
  },
  title: { fontStyle: 'normal', fontWeight: '700', fontSize: 24 },
  clubInfoHeader: {
    paddingTop: 10,
    backgroundColor: COLORS.WHITE,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clubInfoRow: {
    marginTop: 16,
    padding: 24,
    height: 330,
  },
  downloadRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 26,
  },
  divider: {
    backgroundColor: COLORS.GRAY_300,
    height: 1,
    width: '100%',
    marginVertical: 13,
  },
  downloadIcon: { width: 20, height: 20, resizeMode: 'contain' },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 21,
    width: '100%',
  },
  typeWrapper: {
    paddingHorizontal: 8,
    height: 29,
    backgroundColor: COLORS.AZURE_LIGHT,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleFont: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    color: COLORS.DARK,
    width: '50%',
  },
  subtitleFont: {
    width: '50%',
  },
  statusFont: {
    width: '50%',
    textTransform: 'capitalize',
  },
  initiatedByFont: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    color: COLORS.DARK,
  },
  viewDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    width: '100%',
  },
  viewDetailsFont: {
    marginRight: 10,
  },
  shadowProp: {
    borderRadius: 8,
    shadowColor: COLORS.SHADOW_COLOR,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 24,

    elevation: 10,
  },
})
