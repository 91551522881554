import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment/moment'

import { ButtonRow } from '@views/components/Form'
import { AdminForm, ErrorMessage } from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { AdminInfoSchema, IAdminInfo } from '@views/schemas'
import { useCreateAccount, useAuth, useDisclaimer } from '@views/hooks'
import { getDirtyPayload } from '@views/utils'

import { COLORS, LEGAL_STRUCTURES } from '@assets/constants'
import Header from './Header'

const AdminInfo = ({ navigation }: CreateAccountStackScreenProps<'adminInfo'>) => {
  const { createAccountState, postAdminInfo, patchAdminInfo, getAdminInfo, getBasicInfo } = useCreateAccount()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { isDisclaimerAccepted, openDisclaimers, setIsDisclaimerAccepted } = useDisclaimer()
  const { authFlowInfo, user } = useAuth()

  const { loading, error } = createAccountState

  const isPersisted = authFlowInfo?.isAdminInfoComplete
  const hasBankAccount = authFlowInfo?.hasBankAccount

  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    formState: { errors, isSubmitSuccessful, isDirty, dirtyFields, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AdminInfoSchema),
  })

  const onSubmit = async (data: any) => {
    if (hasBankAccount){
      setSubmitted(true)
      return
    }
    clearErrors()
    if (!isDisclaimerAccepted)
      openDisclaimers({
        onAccepted: async () => {
          await sendAdminInfo(data)
        },
      })
    else await sendAdminInfo(data)
  }

  const sendAdminInfo = async (data: any) => {
    if (!isPersisted) await postAdminInfo(data)
    else if (isPersisted && isDirty) {
      const payload = getDirtyPayload(data, dirtyFields)
      if (payload) await patchAdminInfo(payload)
    }
    setSubmitted(true)
    return
  }

  const resetForm = useCallback(
    (keepValues = false, persistedData?: IAdminInfo) => {
      let { adminInfo } = createAccountState.data
      if (!adminInfo && persistedData) adminInfo = persistedData

      if (adminInfo) {
        const pattern = new RegExp(/\+1/)
        let resetPhone = false
        if (adminInfo.phone) resetPhone = pattern.test(adminInfo.phone)
        reset(
          {
            ...adminInfo,
            birthday: adminInfo.birthday ? moment(adminInfo?.birthday).format('MM/DD/YYYY') : '',
            phone: resetPhone ? adminInfo.phone.substring(2) : '',
            ssn: adminInfo.ssn.padStart(9, '0'),
          },
          { keepValues }
        )
        setIsDisclaimerAccepted(true)
      }
    },
    [createAccountState, reset, setIsDisclaimerAccepted]
  )

  useEffect(() => {
    reset({ email: user?.email ? user.email : '', birthday: '', phone: '', ssn: '' })
    if (isPersisted) getAdminInfo().then((persistedData) => resetForm(false, persistedData))
    if (!createAccountState.data?.basicInfo) getBasicInfo()
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful && submitted && error === '') {
      const { basicInfo } = createAccountState.data

      if (basicInfo?.orgLegalStructure !== LEGAL_STRUCTURES.NON_PROFIT)
        navigation.navigate('beneficialOwners')
      else navigation.navigate('sportInfo')
      resetForm(true)
    }
  }, [createAccountState, error, navigation, isSubmitSuccessful, submitted, reset])

  const onSsnFocus = () => {
    setValue('ssn', '')
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <Header subroute="Admin info" />
        <AdminForm onSsnFocus={onSsnFocus} control={control} errors={errors} hasBankAccount={hasBankAccount} />
      </View>
      {error !== '' && <ErrorMessage error={error} />}
      <ButtonRow
        loading={loading || isSubmitting}
        buttonLabel={loading || isSubmitting ? 'Loading...' : 'Next'}
        onSubmit={(e: SyntheticEvent) => {
          handleSubmit(onSubmit)(e).catch((err) => {
            console.error('hs', { err, isSubmitSuccessful })
          })
        }}
        onBack={() => navigation.navigate('basicInfo')}
      />
    </ScrollView>
  )
}

export default AdminInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  link: {
    textDecorationLine: 'underline',
  },
  formWrapper: {
    borderBottomColor: '#EEEEF0',
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  addOwnerBtn: {
    marginBottom: 50,
    width: 250,
  },
})
