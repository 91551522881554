import React, { useCallback, useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ButtonRow } from '@views/components/Form'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { sportInfoFields, SportInfoSchema } from '@views/schemas'
import { RenderRow, Form, ErrorMessage } from '@views/components'

import { LEGAL_STRUCTURES } from '@assets/constants'
import { useAuth, useCreateAccount } from '@views/hooks'
import { getDirtyPayload } from '@views/utils'
import Header from './Header'
import { SportInfoDTOToSportInfo } from '@microservices/dto/SportInfoDTO'

const SportInfo = ({ navigation }: CreateAccountStackScreenProps<'sportInfo'>) => {
  const { createAccountState, postSportInfo, patchSportInfo, getBasicInfo } = useCreateAccount()
  const { user, authFlowInfo } = useAuth()

  const isPersisted = authFlowInfo?.isSportInfoComplete
  const hasBankAccount = authFlowInfo?.hasBankAccount

  const {
    loading,
    error,
    data: { basicInfo },
  } = createAccountState

  const {
    handleSubmit,
    control,
    clearErrors,
    reset,
    formState: { errors, isSubmitSuccessful, isDirty, dirtyFields, isSubmitting },
  } = useForm({
    resolver: yupResolver(SportInfoSchema),
  })

  const onSubmit = async (data: any) => {
    if (hasBankAccount) navigation.navigate('Review')
    clearErrors()
    if (!isPersisted) await postSportInfo(data)
    else if (isPersisted && isDirty) {
      const payload = getDirtyPayload(data, dirtyFields)
      if (payload) await patchSportInfo(payload)
    }
  }

  const resetForm = useCallback(
    (keepValues = false) => {
      const sportInfo = SportInfoDTOToSportInfo(user?.association?.Sport_info)

      if (sportInfo)
        reset(
          {
            ...sportInfo,
          },
          {
            keepValues,
          }
        )
    },
    [reset, user]
  )

  useEffect(() => {
    if (isPersisted) resetForm()

    if (!basicInfo) getBasicInfo()
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful && error === '') {
      navigation.navigate('Review')
      resetForm(true)
    }
  }, [error, navigation, isSubmitSuccessful, reset])

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View style={styles.content}>
        <Header subroute="Sport information" />
        <Form>
          <RenderRow
            fields={[sportInfoFields.membersNumber, sportInfoFields.sportType]}
            errors={errors}
            disabled={
              hasBankAccount
                ? [sportInfoFields.membersNumber, sportInfoFields.sportType]
                : undefined
            }
            control={control}
          />
          <RenderRow
            fields={[sportInfoFields.seasonStartMonth]}
            disabled={hasBankAccount ? [sportInfoFields.seasonStartMonth] : undefined}
            errors={errors}
            control={control}
          />
          <RenderRow
            fields={[sportInfoFields.seasonMonthCount]}
            disabled={hasBankAccount ? [sportInfoFields.seasonMonthCount] : undefined}
            errors={errors}
            control={control}
          />
        </Form>
      </View>
      {error !== '' && <ErrorMessage error={error} />}
      <ButtonRow
        buttonLabel={loading || isSubmitting ? 'Loading...' : 'Next'}
        loading={loading || isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
        onBack={() => {
          if (basicInfo?.orgLegalStructure !== LEGAL_STRUCTURES.NON_PROFIT)
            navigation.navigate('beneficialOwners')
          else navigation.navigate('adminInfo')
        }}
      />
    </ScrollView>
  )
}

export default SportInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: '#FFF',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#DDDDDD',
    padding: 10,
  },
  content: { marginBottom: 16 },
})
