import React from 'react'
import DepositCheck from '../DepositCheck'

export const InitialDepositCheck = ({ navigation }: any) => {
  return (
    <DepositCheck
      isInitialDeposit={true}
      navigate={() => navigation.push('createAccount', { screen: 'fundAccount' })}
    />
  )
}
