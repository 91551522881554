import React from 'react'
import loading from '@assets/images/loading.png'
import { Animated, Easing } from 'react-native'

type Props = {
  size?: number | string
}

export const LoadingSpinner: React.FC<Props> = ({ size = 50 }) => {
  const spinValue = new Animated.Value(0)

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: false,
    })
  ).start()

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  return (
    <Animated.Image
      source={loading}
      style={{ width: size, height: size, transform: [{ rotate: spin }] }}
    />
  )
}
