import React, { useCallback, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { useDropzone } from 'react-dropzone'

import { CreateAccountStackScreenProps } from '@views/navigation/types'
import uploadIcon from '@assets/images/upload-icon.png'
import acceptedIcon from '@assets/images/accepted.png'
import infoIcon1 from '@assets/images/info-icon1.png'
import infoIcon2 from '@assets/images/info-icon2.png'
import failedIcon from '@assets/images/failed.png'
import removeIcon from '@assets/images/remove.png'
import { COLORS, verificationStatuses } from '@assets/constants'
import { AppText, Row, Button, ErrorMessage, openModal, closeModal } from '@views/components'
import { useAuth, useSystemConfig } from '@views/hooks'
import Header from './Header'
import { createFileUploadOptions } from '@views/utils'
import { businessService } from '@domain/service'
import { businessRepo } from '@microservices/instances'
import { UnauthenticatedError } from '@microservices/errors/http'

const BusinessDocuments = ({ navigation }: CreateAccountStackScreenProps<'businessDocuments'>) => {
  const { isSmallDevice } = useSystemConfig()
  const { user, logout } = useAuth()

  const [uploadedFiles, setUploadedFiles] = useState<object[]>([])
  const [stagedFiles, setStagedFiles] = useState<object[]>([])
  const [failedFiles, setFailedFiles] = useState<object[]>([])
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (user?.association?.kyb_status === verificationStatuses.ACCEPTED) {
      navigation.navigate('Review')
    }
    if (user?.association?.Documents && user?.association?.Documents?.length > 0)
      setError(
        `You have already uploaded ${user?.association?.Documents?.length} document${
          user?.association?.Documents?.length === 1 ? '' : 's'
        }. Submitting more will append them to your account, they will not be replaced.`
      )
  }, [user])

  const onDrop = useCallback(
    (droppedFiles) => {
      setError('')
      if ([...stagedFiles, ...failedFiles, ...uploadedFiles].length + droppedFiles.length > 3)
        setError('Maximum number of files exceeded.')
      else
        droppedFiles.forEach((file: any) => {
          const reader = new FileReader()

          reader.onabort = () => setError('Something went wrong, please try again')
          reader.onerror = () => setError('Something went wrong, please try again')
          reader.onload = () => {
            const mbSize = file.size / (1024 * 1024)

            if (mbSize > 5) setError('Maximum accepted file size exceeded.')
            else if (
              file.type === 'image/png' ||
              file.type === 'image/jpeg' ||
              file.type === 'application/pdf'
            )
              setStagedFiles((files) => [...files, file])
            else setError('Invalid file type.')
          }
          reader.readAsArrayBuffer(file)
        })
    },
    [stagedFiles, failedFiles, uploadedFiles]
  )

  const closeAndLogout = () => {
    closeModal()
    logout()
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
  })

  const remove = (idx: number, files: any, callbackFn: any) => {
    const temp = [...files]
    temp.splice(idx, 1)
    callbackFn([...temp])
  }

  const handleSubmit = async () => {
    setError('')
    setLoading(true)
    const uploadFailed: object[] = []
    const uploadSuccess: object[] = []
    await Promise.allSettled(
      [...stagedFiles, ...failedFiles].map(async (file: any, idx) => {
        try {
          const payload = createFileUploadOptions(
            `IDDoc-${user?.association?.id_synctera}-${idx}`,
            undefined,
            file
          )
          await businessService(businessRepo).postBusinessDocuments(user?.association?.id, payload)
          uploadSuccess.push(file)
          return file.name
        } catch (e) {
          if (e instanceof UnauthenticatedError) {
            logout()
            return
          }
          uploadFailed.push(file)
          throw file.name
        }
      })
    )
    setUploadedFiles((files) => [...files, ...uploadSuccess])
    setFailedFiles([...uploadFailed])
    setStagedFiles([])
    setLoading(false)

    if (uploadFailed.length > 0)
      setError(
        `${
          uploadFailed.length === 1 ? 'A file' : 'Some files'
        } failed to upload. Please press submit to retry uploading ${
          uploadFailed.length === 1 ? 'this file.' : 'these files.'
        }`
      )
    else
      openModal({
        dismiss: false,
        type: 'success',
        title: 'Your documents have been uploaded.',
        subtitle:
          'Your documents will be reviewed. We will contact you if any further steps are required.',
        onDismiss: () => closeAndLogout(),
        buttons: [
          <Button
            key={'BOInfo_success_modal'}
            style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
            type="primary"
            text="Logout"
            onPress={closeAndLogout}
          />,
        ],
      })
  }
  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <Header subtitle="Upload documents about your organization." subroute="Documents" />
        <AppText style={styles.instructions}>Upload documents from list</AppText>
        <div {...getRootProps()}>
          <View style={styles.uploadAreaContainer}>
            <View style={styles.uploadImageContainer}>
              <input {...getInputProps()} />
              <Image style={styles.uploadImage} source={uploadIcon} />
              <AppText style={{ textAlign: 'center' }}>
                Drag and drop or click to upload. (Accepts PNG or JPEG images, and PDF)
              </AppText>
            </View>
          </View>
        </div>
        <AppText style={styles.maxSizeText}>Max file size: 5 MB. Max files accepted: 3.</AppText>
        {error && <ErrorMessage error={error} />}

        <View style={styles.statusContainer}>
          <AppText style={styles.subTitle3}>Documents list</AppText>
          {stagedFiles.length === 0 ? (
            <Image style={styles.infoIcon} source={infoIcon1} />
          ) : (
            <Image style={styles.infoIcon} source={infoIcon2} />
          )}
        </View>

        <AppText style={styles.documents}>- Certificate of incorporation</AppText>
        <AppText style={styles.documents}>- 990 Tax Form</AppText>
        <AppText style={styles.documents}>- Bylaw</AppText>

        {uploadedFiles.length > 0 && (
          <View style={styles.uploadsContainer}>
            <AppText style={styles.uploadStatus}>
              {uploadedFiles.length} file{uploadedFiles.length === 1 ? '' : 's'} uploaded.
            </AppText>
            {uploadedFiles.map((element: any, index: number) => {
              return (
                <View key={`uploaded-${index}`} style={styles.uploadContainer}>
                  <View style={styles.statusContainer}>
                    <Image style={styles.statusIcon} source={acceptedIcon} />
                    <AppText> {element.name} </AppText>
                  </View>
                </View>
              )
            })}
          </View>
        )}

        {stagedFiles.length > 0 && (
          <View style={styles.uploadsContainer}>
            <AppText style={styles.uploadStatus}>
              {stagedFiles.length} file{stagedFiles.length === 1 ? '' : 's'} ready for upload.
            </AppText>
            {stagedFiles.map((element: any, index: number) => {
              return (
                <View key={`staged-${index}`} style={styles.uploadContainer}>
                  <View style={styles.statusContainer}>
                    <AppText> {element.name} </AppText>
                  </View>

                  <View style={styles.statusContainer}>
                    <TouchableOpacity
                      onPress={() => {
                        remove(index, stagedFiles, setStagedFiles)
                      }}>
                      <Image style={styles.statusIcon} source={removeIcon} />
                    </TouchableOpacity>
                  </View>
                </View>
              )
            })}
          </View>
        )}

        {failedFiles.length > 0 && (
          <View style={styles.uploadsContainer}>
            <AppText style={styles.uploadStatus}>
              {failedFiles.length} file{failedFiles.length === 1 ? '' : 's'} failed upload. (Submit
              again to retry)
            </AppText>
            {failedFiles.map((element: any, index: number) => {
              return (
                <View key={`failed-${index}`} style={styles.uploadContainer}>
                  <View style={styles.statusContainer}>
                    <Image style={styles.statusIcon} source={failedIcon} />
                    <AppText> {element.name} </AppText>
                  </View>

                  <View style={styles.statusContainer}>
                    <TouchableOpacity
                      onPress={() => {
                        remove(index, failedFiles, setFailedFiles)
                      }}>
                      <Image style={styles.statusIcon} source={removeIcon} />
                    </TouchableOpacity>
                  </View>
                </View>
              )
            })}
          </View>
        )}
      </View>
      <Row style={styles.buttonWrapper}>
        <Button
          style={!isSmallDevice ? styles.button : {}}
          onPress={handleSubmit}
          disabled={
            loading ||
            [...stagedFiles, ...failedFiles, ...uploadedFiles].length > 3 ||
            [...stagedFiles, ...failedFiles].length < 1
          }
          type="primary"
          text="Submit"
        />
      </Row>
    </ScrollView>
  )
}

export default BusinessDocuments

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.WHITE,
    paddingHorizontal: 30,
    paddingBottom: 30,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  uploadAreaContainer: {
    backgroundColor: COLORS.GRAY_200,
    borderColor: COLORS.BACKGROUND,
    justifyContent: 'center',
    borderStyle: 'dashed',
    alignItems: 'center',
    borderRadius: 10,
    display: 'flex',
    borderWidth: 2,
    width: '100%',
    height: 150,
  },
  uploadImageContainer: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  uploadImage: {
    marginBottom: 10,
    resizeMode: 'contain',
    height: 50,
    width: 50,
  },
  statusContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  uploadsContainer: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  uploadContainer: {
    justifyContent: 'space-between',
    borderColor: COLORS.BACKGROUND,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    display: 'flex',
    borderWidth: 2,
    width: '100%',
    marginVertical: 5,
    padding: 20,
  },
  infoIcon: {
    resizeMode: 'contain',
    marginLeft: 15,
    height: 20,
    width: 20,
  },
  statusIcon: {
    resizeMode: 'contain',
    marginLeft: 20,
    height: 20,
    width: 20,
  },
  maxSizeText: {
    color: COLORS.GRAY_500,
    marginTop: 10,
    maxWidth: 850,
    fontSize: 10,
  },
  subTitle3: {
    fontWeight: 'bold',
    marginTop: 32.5,
    marginBottom: 5,
    maxWidth: 850,
    fontSize: 14,
  },
  instructions: {
    marginTop: 32.5,
    marginBottom: 5,
    maxWidth: 850,
    fontSize: 12,
  },
  uploadStatus: {
    marginVertical: 10,
    color: COLORS.GRAY_700,
    maxWidth: 850,
    fontSize: 12,
  },
  documents: {
    marginVertical: 3,
    maxWidth: 850,
    fontSize: 14,
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  button: {
    maxWidth: 270,
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
