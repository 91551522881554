import React from 'react'
import { ActivityIndicator, StyleSheet, View, Image } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AppText, Row, openModal, StatementDownloadModal } from '@views/components'
import { useStatements } from '@views/hooks'
import { formatStatementPeriod } from '@views/utils'
import { COLORS } from '@assets/constants'
import bulletListIcon from '@assets/images/bullet_list.svg'
import downloadIcon from '@assets/images/download.svg'
import { Statements } from '@domain/models'

export const StatementsList: React.FC = () => {
  const { loadingStatements, filteredStatements } = useStatements()

  const onPressDownload = (statement: Statements[0], formattedDate: string) => {
    openModal({
      title: 'Download Statement',
      isInlineTitle: true,
      content: <StatementDownloadModal statement={statement} formattedDate={formattedDate} />,
    })
  }

  return (
    <View style={styles.statementsList}>
      {loadingStatements ? (
        <ActivityIndicator />
      ) : !filteredStatements.length ? (
        <AppText bold style={styles.noStatementsText}>
          You do not have any statements
        </AppText>
      ) : (
        filteredStatements.map((statement, idx) => {
          const formattedDate = formatStatementPeriod(statement.start_date)

          return (
            <View key={`statement_${statement.id}`}>
              <Row style={styles.statementRow}>
                <Row style={styles.statementText}>
                  <View style={styles.bulletListIconContainer}>
                    <Image style={styles.bulletListIcon} source={bulletListIcon} />
                  </View>
                  <AppText semiBold style={styles.statementPeriod}>
                    {formattedDate}
                  </AppText>
                </Row>
                <TouchableOpacity onPress={() => onPressDownload(statement, formattedDate)}>
                  <Image style={styles.downloadIcon} source={downloadIcon} />
                </TouchableOpacity>
              </Row>
              {idx !== filteredStatements.length - 1 && <View style={styles.seperator} />}
            </View>
          )
        })
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  statementsList: {
    marginTop: 12,
  },
  noStatementsText: {
    fontSize: 22,
  },
  statementRow: {
    alignItems: 'center',
    marginBottom: 12,
    width: 325,
    justifyContent: 'space-between',
  },
  bulletListIconContainer: {
    width: 44,
    height: 44,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f6fb',
  },
  bulletListIcon: {
    resizeMode: 'contain',
    height: 24,
    width: 24,
  },
  statementPeriod: {
    marginLeft: 12,
  },
  downloadIcon: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  seperator: {
    height: 1,
    width: '100%',
    backgroundColor: COLORS.GRAY_300,
    marginBottom: 12,
  },
  statementText: {
    alignItems: 'center',
  },
})
