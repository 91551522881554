export const isAdult = (birthday: string | Date) => {
  const dob = new Date(birthday)
  if (!dob) return false
  const today = new Date()
  const month = today.getMonth() - dob.getMonth()
  const age = today.getFullYear() - dob.getFullYear()

  if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) return age - 1 >= 18
  return age >= 18
}
