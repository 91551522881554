import React, { useEffect, useState } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { RootStackParamList } from '@views/navigation/types'
import {
  LandingPage,
  CreateAccount,
  LoginWithRedirect,
  SignupWithRedirect,
  SubAdminInfo,
  PersonalDocuments,
} from '@views/pages'
import { documentTitle } from '@views/utils'
import { useAuth } from '@views/hooks'
import { PERSONAL_DOCUMENTS_ROUTE, SUB_ADMIN_SIGNUP_ROUTE, UserTypesEnum } from '@assets/constants'
import { LocalStorage } from '@microservices/instances'

const Stack = createStackNavigator<RootStackParamList>()

const localStorage = new LocalStorage()

export default function AuthNavigation() {
  const { isAuthenticatedAuth0, user } = useAuth()
  const [isSubAccount, setIsSubAccount] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (user?.id_synctera && user.type !== UserTypesEnum.SUB_ADMIN) {
        setIsSubAccount(false)
        return
      }
      const subaccount_name = await localStorage.getItem<string>('subaccount_name')
      if (user?.type === UserTypesEnum.SUB_ADMIN || subaccount_name) {
        setIsSubAccount(true)
      }
    })()
  }, [user?.type, user?.id_synctera])

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isAuthenticatedAuth0 ? (
        <>
          {isSubAccount ? (
            <Stack.Screen
              name={SUB_ADMIN_SIGNUP_ROUTE}
              component={SubAdminInfo}
              options={{ title: documentTitle('Create Account', 'Sub Account') }}
            />
          ) : (
            <Stack.Screen name="createAccount" component={CreateAccount} />
          )}
          <Stack.Screen name={PERSONAL_DOCUMENTS_ROUTE} component={PersonalDocuments} />
        </>
      ) : (
        <>
          <Stack.Screen
            name="landingPage"
            component={LandingPage}
            options={{ title: documentTitle('Players Health', 'Home') }}
          />
          <Stack.Screen name="login" component={LoginWithRedirect} />
          <Stack.Screen name="signup" component={SignupWithRedirect} />
        </>
      )}
    </Stack.Navigator>
  )
}
