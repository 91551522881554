import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Statements } from '@domain/models'
import { RNRadioButton, AppText, Button, openModal, closeModal } from '@views/components'
import { useStatements } from '@views/hooks'

type Props = {
  statement: Statements[0]
  formattedDate: string
}

export const StatementDownloadModal: React.FC<Props> = ({ formattedDate, statement }) => {
  const { downloadStatement, loadingOneStatement } = useStatements()
  const [pdfSelected, setPdfSelected] = useState(true)

  const items = [
    { label: 'PDF', value: 'pdf' },
    { label: 'CSV', value: 'csv' },
  ]

  const onChange = (value: string) => {
    if (value === 'pdf') setPdfSelected(true)
    else setPdfSelected(false)
  }

  const handleDownload = async () => {
    const successfulDownload = await downloadStatement(statement.id, pdfSelected)
    if (!successfulDownload) {
      openModal({
        title: 'Something Went Wrong',
        type: 'error',
        dismiss: false,
        buttons: [
          <Button
            key={`cancel-${statement.id}`}
            text={'Back To Statements'}
            onPress={closeModal}
            style={styles.cancelButton}
            type={'secondary'}
          />,
        ],
      })
      return
    }
    openModal({
      title: 'Your File Is Downloading',
      type: 'success',
      dismiss: false,
      buttons: [
        <Button
          key={`cancel-${statement.id}`}
          text={'Back To Statements'}
          onPress={closeModal}
          style={styles.cancelButton}
          type={'secondary'}
        />,
      ],
    })
  }

  return (
    <View style={styles.container}>
      <AppText light style={styles.date}>
        {formattedDate}
      </AppText>
      <AppText>Type of statement file</AppText>
      <RNRadioButton
        container={styles.radioButtonContainer}
        items={items}
        onChange={onChange}
        initialSelected={0}
      />
      <Button
        style={styles.button}
        onPress={handleDownload}
        text={loadingOneStatement ? 'Loading...' : 'Download Statement'}
        loading={loadingOneStatement}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 350,
  },
  date: {
    fontSize: 16,
    marginBottom: 24,
  },
  radioButtonContainer: {
    marginTop: 15,
    marginBottom: 30,
  },
  button: {
    width: '100%',
  },
  cancelButton: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
    height: '100%',
    width: 'fit-content',
    alignSelf: 'center',
  },
})
