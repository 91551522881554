import React from 'react'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { Button, AppText, QRCodeGenerator, closeModal, UL } from '@views/components'
import { useSystemConfig } from '@views/hooks'
import info_attention from '@assets/images/info_attention.png'
import { COLORS } from '@assets/constants'
import { useAuth } from '@views/hooks'

type Props = {
  component: string
  showLogo?: boolean
  title?: string
  instructions?: string
  documentsNeeded?: string
  btnTitle?: string
}

export const QRCodeModal: React.FC<Props> = ({
  component,
  showLogo = false,
  title = 'Make A Deposit By Check',
  instructions = 'To prevent fraud, we require:',
  documentsNeeded = 'check',
  btnTitle = 'View Account',
}) => {
  const { isSmallDevice } = useSystemConfig()
  const isCheck = documentsNeeded === 'check'

  const checkRequirements = [
    `Front and back photos of the check`,
    `Your signature on the back of the check and an endorsement that says: "For Mobile Deposit Only"`,
  ]

  return (
    <View style={isSmallDevice ? styles.containerSmall : styles.container}>
      <ScrollView>
        {showLogo && <Image source={info_attention} style={styles.modalLogo} />}
        <AppText bold style={styles.title}>
          {title}
        </AppText>
        <AppText style={[styles.sectionContent, isCheck && styles.marginBottomZero]} bold={isCheck}>
          {instructions}
          {!isCheck && `.`}
        </AppText>
        {isCheck && (
          <UL
            data={checkRequirements}
            textStyle={styles.listText}
            listStyle={styles.listContainer}
            underlineMap={{ 1: 'and' }}
          />
        )}
        <AppText style={styles.sectionContent}>
          Scan the QR code below with your mobile device to upload photographs of your{' '}
          {documentsNeeded}.
        </AppText>
        <View style={styles.qrContainer}>
          <QRCodeGenerator qrURL={`${window.location.origin}/login?redirect_uri=${component}`} />
        </View>
        <AppText bold style={styles.sectionContent}>
          Once you have submitted the photographs on your mobile device, click the {btnTitle} button
          to continue.
        </AppText>
      </ScrollView>
      <Button
        style={styles.button}
        onPress={() => {
          closeModal()
          document.location.reload()
        }}
        type="primary"
        text={btnTitle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: { maxHeight: '75vh', marginBottom: 10 },
  containerSmall: { maxHeight: '60vh', marginBottom: 10 },
  title: { fontSize: 24, marginBottom: 10 },
  sectionContent: { lineHeight: 24, marginBottom: 10 },
  button: { marginTop: 10 },
  modalLogo: { height: 86, width: 112, marginBottom: 24, display: 'flex', alignSelf: 'center' },
  qrContainer: {
    padding: 15,
    borderStyle: 'solid',
    borderRadius: 8,
    borderColor: COLORS.DARK,
    borderWidth: 1,
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 10,
  },
  listContainer: {
    marginBottom: 10,
  },
  listText: {
    lineHeight: 24,
  },
  marginBottomZero: {
    marginBottom: 0,
  },
})
