import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import Popover, { PopoverMode } from 'react-native-popover-view'
import { Placement } from 'react-native-popover-view/dist/Types'
import { COLORS } from '@assets/constants'
import { useOnClickOutside } from '@views/hooks'

interface Props {
  from: ReactNode
  placement?: Placement | Array<Placement>
  index?: number
  indexShowing?: number
  setIndexShowing?: React.Dispatch<React.SetStateAction<number>>
}

export const DropdownPopover: React.FC<PropsWithChildren<Props>> = ({
  children,
  from,
  placement,
  index,
  indexShowing,
  setIndexShowing,
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const ref = useRef(null)
  const isIndexShowing =
    index !== undefined && indexShowing !== undefined && setIndexShowing !== undefined

  const handleClickOutside = () => {
    // hide the popover on click outside
    if (isIndexShowing) setIndexShowing?.(-1)
    else setShowPopover(false)
  }

  useOnClickOutside(ref, handleClickOutside)

  if (isIndexShowing)
    return (
      <Popover
        mode={PopoverMode.JS_MODAL}
        isVisible={indexShowing === index}
        onRequestClose={() => setIndexShowing?.(-1)}
        arrowSize={{ height: 0, width: 0 }}
        from={
          <Pressable
            onPress={() => {
              indexShowing === index
                ? setIndexShowing?.(-1)
                : setIndexShowing?.(index !== undefined ? index : -1)
            }}>
            {from}
          </Pressable>
        }
        placement={placement}>
        <View ref={ref} style={styles.shadowProp}>
          {children}
        </View>
      </Popover>
    )
  else
    return (
      <Popover
        mode={PopoverMode.JS_MODAL}
        isVisible={showPopover}
        onRequestClose={() => setShowPopover(false)}
        backgroundStyle={{ opacity: 1, backgroundColor: 'blue' }}
        arrowSize={{ height: 0, width: 0 }}
        from={<Pressable onPress={() => setShowPopover(!showPopover)}>{from}</Pressable>}
        placement={placement}>
        <View ref={ref} style={styles.shadowProp}>
          {children}
        </View>
      </Popover>
    )
}

const styles = StyleSheet.create({
  shadowProp: {
    shadowColor: COLORS.SHADOW_COLOR,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    borderRadius: 8,
  },
})
