import React, { useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet, Image } from 'react-native'
import {
  AppText,
  Row,
  ButtonRow,
  openModal,
  Button,
  closeModal,
  RNInput,
  InputTypes,
  QRCodeModal,
} from '@views/components'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import { useAuth, useCreateAccount, useSystemConfig } from '@views/hooks'

import pending from '@assets/images/pending.png'
import blackCheck from '@assets/images/black_check.png'
import { COLORS, LEGAL_STRUCTURES, UserTypesEnum, verificationStatuses } from '@assets/constants'
import { User } from '@domain/models'
import Header from './Header'

const Review = ({ navigation }: CreateAccountStackScreenProps<'Review'>) => {
  const { createBankAccount, createBusinessVerification, createAccountState } = useCreateAccount()
  const { loading } = createAccountState
  const { user, authFlowInfo } = useAuth()
  const { isMobile } = useSystemConfig()

  const [isSubmitting, setIsSubmitting] = useState(false)

  let kycStatus = user?.kyc_status
  let kybStatus = user?.association?.kyb_status
  let beneficialOwners = user?.association?.Users || []
  const hasBankAccount = authFlowInfo?.hasBankAccount
  const isReviewComplete = authFlowInfo?.isReviewComplete

  const checkKybStatus = (kybStatus: string | undefined): boolean => {
    switch (kybStatus) {
      case verificationStatuses.ACCEPTED:
      case verificationStatuses.PROVISIONAL:
        return true
      case verificationStatuses.REVIEW:
      case verificationStatuses.REJECTED:
        openModal({
          dismiss: false,
          type: 'warning',
          title: 'Business Documents Required',
          subtitle: 'In order to verify your organization, please upload additional documents.',
          buttons: [
            <Button
              type="primary"
              text="Upload Documents"
              onPress={() => {
                navigation.navigate('businessDocuments')
                closeModal()
              }}
            />,
          ],
        })
        return false
      case verificationStatuses.UNVERIFIED:
      case verificationStatuses.PENDING:
        openModal({
          dismiss: false,
          type: 'loading',
          title: 'Your Account Is Being Verified',
          subtitle:
            'Please wait while your account is being verified. This should only take a few moments',
        })
        return false
      default:
        return false
    }
  }
  const checkKycStatus = (kycStatus: string | undefined): boolean => {
    switch (kycStatus) {
      case verificationStatuses.ACCEPTED:
      case verificationStatuses.PROVISIONAL:
        return true
      case verificationStatuses.REVIEW:
      case verificationStatuses.REJECTED:
        if (isMobile()) navigation.navigate('personalDocuments')
        else
          openModal({
            dismiss: false,
            content: (
              <QRCodeModal
                component={'personalDocuments'}
                showLogo={true}
                title={'Personal Information Required'}
                instructions={
                  'In order to verify your organization, please upload additional documents'
                }
                documentsNeeded={'documents'}
                btnTitle={'Refresh'}
              />
            ),
          })
        return false
      case verificationStatuses.UNVERIFIED:
      case verificationStatuses.PENDING:
        openModal({
          dismiss: false,
          type: 'loading',
          title: 'Your Account Is Being Verified',
          subtitle:
            'Please wait while your account is being verified. This should only take a few moments',
        })
        return false
      default:
        return false
    }
  }

  const onSubmit = async () => {
    console.log({ hasBankAccount, isReviewComplete })
    if (hasBankAccount) {
      navigation.navigate('fundAccount')
      return
    }

    if (!isReviewComplete) {
      openModal({
        dismiss: true,
        type: 'error',
        title: 'Not all users have filled out their information',
        subtitle: 'Please check with the users marked as pending or resend the invite email.',
        contentStyle: styles.errorModelContent,
      })
      return
    }
    if (kybStatus === null || kycStatus === null) {
      setIsSubmitting(true)
      const statuses = await createBusinessVerification()
      if (!statuses) return
      kycStatus = statuses.kyc_status
      kybStatus = statuses.kyb_status
      setIsSubmitting(false)
    }
    if (!checkKybStatus(kybStatus)) return
    if (!checkKycStatus(kycStatus)) return

    openModal({
      dismiss: false,
      type: 'success',
      title: 'Success',
      subtitle: 'Your account has been verified.',
      confirmationButtonMessage: 'Continue',
      onConfirmPressed: async () => {
        setIsSubmitting(true)
        await createBankAccount()
        setIsSubmitting(false)
        navigation.navigate('fundAccount')
        closeModal()
      },
    })
  }

  const isUserBeneficialOwner = (bo: User) => {
    return bo.percent_ownership && bo.type === UserTypesEnum.ORG_ADMIN
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.content}>
        {user?.association?.structure !== LEGAL_STRUCTURES.NON_PROFIT ? (
          <Header
            subtitle="Keep track of which beneficial owners have entered their personal information. Once all beneficial owners have entered their information, you may submit your application."
            subroute="Beneficial owners review"
          />
        ) : (
          <Header
            subtitle="Because you are the administrative authority for your non-profit, there is no further action required. Please press the Submit button so we can start the review process."
            subroute=""
            route=""
          />
        )}
        <Row style={styles.reviewRow}>
          {beneficialOwners.map((bo, i) => {
            if (user?.email === bo.email && !isUserBeneficialOwner(bo)) return null
            return (
              <BoReviewInfo
                key={bo.email}
                boEmail={bo.email}
                isPending={!bo.percent_ownership || !bo.id_synctera}
                index={i + 1}
              />
            )
          })}
        </Row>
      </View>
      <ButtonRow
        loading={loading || isSubmitting}
        buttonLabel={
          loading || isSubmitting ? 'Loading...' : hasBankAccount ? 'Fund Account' : 'Submit'
        }
        onSubmit={onSubmit}
        onBack={() => navigation.navigate('sportInfo')}
      />
    </ScrollView>
  )
}

interface BoProps {
  isPending: boolean
  boEmail: string
  index: number
}

const BoReviewInfo = ({ isPending, boEmail, index }: BoProps) => {
  const [email, setEmail] = useState(boEmail)
  const {
    postOwnerEmails,
    createAccountState: { error },
  } = useCreateAccount()
  const [isEmailSend, setIsEmailSend] = useState(false)

  const getPendingSpinner = () => {
    return <Image source={pending} style={styles.pendingIcon} />
  }

  const getCheckIcon = () => {
    return <Image source={blackCheck} style={styles.checkIcon} />
  }

  useEffect(() => {
    if (isEmailSend && !error)
      openModal({
        dismiss: false,
        type: 'success',
        title: 'Success',
        subtitle: 'Your emails have been sent.',
        confirmationButtonMessage: 'Continue',
        onConfirmPressed: () => setIsEmailSend(false),
      })
    else if (isEmailSend && error)
      openModal({
        dismiss: false,
        type: 'warning',
        title: 'Error',
        subtitle: 'It was not possible to send the email.',
        confirmationButtonMessage: 'Continue',
        onConfirmPressed: () => setIsEmailSend(false),
      })
  }, [error, isEmailSend])

  const resendEmail = async () => {
    const data = {
      email,
      type: UserTypesEnum.BENEFICIAL_OWNER,
      resend_email: true,
    }
    await postOwnerEmails(data)
    setIsEmailSend(true)
  }

  return (
    <View style={styles.boReviewInfoContainer}>
      <AppText style={styles.label} semiBold>
        Beneficial owner/administrative authority {index}
      </AppText>
      <RNInput
        type={InputTypes.text}
        onChangeText={(text: string) => {
          setEmail(text)
        }}
        value={email}
      />
      <Row style={[styles.statusContainer, styles.statusRow]}>
        <Row style={styles.statusRow}>
          <View style={styles.statusIcon}>{isPending ? getPendingSpinner() : getCheckIcon()}</View>
          <AppText style={styles.status} semiBold>
            Information {isPending ? 'Pending' : 'Received'}
          </AppText>
        </Row>
        <AppText
          style={[styles.resendEmail, !isPending && styles.resendEmailDisabled]}
          onPress={isPending ? resendEmail : undefined}>
          Resend email
        </AppText>
      </Row>
    </View>
  )
}

export default Review

const styles = StyleSheet.create({
  boReviewInfoContainer: { marginRight: 130, marginBottom: 80 },
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  link: {
    textDecorationLine: 'underline',
  },
  reviewRow: {
    flexWrap: 'wrap',
    maxWidth: 1000,
  },
  reviewRowLarge: {
    paddingRight: 300,
  },
  reviewCol: {
    flexDirection: 'column',
    marginBottom: 85,
  },
  label: {
    fontSize: 16,
    marginBottom: 22.5,
  },
  status: {
    fontSize: 14,
  },
  statusIcon: {
    marginRight: 20,
  },
  checkIcon: {
    height: 11.5,
    width: 16.5,
    marginTop: 5,
  },
  pendingIcon: {
    height: 24,
    width: 24,
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
  modalStyle: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '75vh',
  },
  statusContainer: {
    marginTop: 17.5,
    justifyContent: 'space-between',
  },
  statusRow: {
    alignItems: 'center',
  },
  resendEmail: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.VIOLET_600,
    color: COLORS.VIOLET_600,
  },
  resendEmailDisabled: {
    borderBottomColor: COLORS.GRAY_700,
    color: COLORS.GRAY_700,
  },
  errorModelContent: {
    padding: 32,
  },
  content: { marginBottom: 32 },
})
