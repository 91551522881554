import BasicInfo from '@views/pages/CreateAccount/BasicInfo'
import AdminInfo from '@views/pages/CreateAccount/AdminInfo'
import BeneficialOwners from '@views/pages/CreateAccount/BeneficialOwners'
import SportInfo from '@views/pages/CreateAccount/SportInfo'
import BusinessDocuments from '@views/pages/CreateAccount/BusinessDocuments'
import Review from '@views/pages/CreateAccount/Review'
import FundAccount from '@views/pages/CreateAccount/FundAccount'
import BeneficialOwnersFromForEmail from '@views/pages/CreateAccount/BeneficialOwners/BeneficialOwnerFormFromEmail'
import { documentTitle } from '@views/utils'
import { InitialDepositCheck } from '@views/pages/DepositCheck/InitialDepositCheck'

export interface CreateAccountPage {
  name: string
  component: (props?: any) => JSX.Element
  options: { title: string, lazy?: boolean }
}

export const createAccountPages: CreateAccountPage[] = [
  {
    name: 'basicInfo',
    component: BasicInfo,
    options: { title: documentTitle('Create Account', 'Basic Info') },
  },
  {
    name: 'adminInfo',
    component: AdminInfo,
    options: { title: documentTitle('Create Account', 'Admin Info') },
  },
  {
    name: 'beneficialOwners',
    component: BeneficialOwners,
    options: { title: documentTitle('Create Account', 'Beneficial Owners') },
  },
  {
    name: 'sportInfo',
    component: SportInfo,
    options: { title: documentTitle('Create Account', 'Sport Info') },
  },
  {
    name: 'Review',
    component: Review,
    options: { title: documentTitle('Create Account', `Owners' Info Review`) },
  },
  {
    name: 'businessDocuments',
    component: BusinessDocuments,
    options: { title: documentTitle('Create Account', 'Business Documents'), lazy: true  },
  },
  {
    name: 'fundAccount',
    component: FundAccount,
    options: { title: documentTitle('Create Account', 'Fund Account'), lazy: true },
  },
  {
    name: 'initialDepositCheck',
    component: InitialDepositCheck,
    options: { title: documentTitle('Create Account', 'Deposit Check') },
  },
]

export const beneficialOwnersFromForEmail: CreateAccountPage = {
  name: 'beneficialOwnersFromEmail',
  component: BeneficialOwnersFromForEmail,
  options: { title: documentTitle('Create Account', 'Beneficial Owner') },
}
