import React from 'react'
import { StyleSheet, View } from 'react-native'
import { DetailsItem } from './DetailsItem'
import { useSystemConfig } from '@views/hooks'

interface Item {
  label: string
  value: any
  isMasked?: boolean
  id?: string
}

interface Props {
  content: Item[]
}

export const DetailsModal: React.FC<Props> = ({ content }) => {
  const { isSmallDevice } = useSystemConfig()

  return (
    <View style={!isSmallDevice && styles.container}>
      {content.map((item) => {
        return (
          <DetailsItem
            key={item.label}
            label={item.label}
            value={item.value}
            isMasked={item.isMasked}
            id={item.id}
          />
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    marginTop: 24,
  },
})
