import { ISportInfo } from '@views/schemas'

export interface SportInfoDTO {
  sport_type: string
  members_number: number
  season_start_month: number
  season_month_count: number
}

export const SportInfoToSportInfoDTO = (sportInfo: Partial<ISportInfo>): Partial<SportInfoDTO> => {
  return {
    ...(sportInfo.sportType && { sport_type: sportInfo.sportType }),
    ...(sportInfo.membersNumber && { members_number: parseInt(sportInfo.membersNumber, 10) }),
    ...(sportInfo.seasonStartMonth && {
      season_start_month: parseInt(sportInfo.seasonStartMonth, 10),
    }),
    ...(sportInfo.seasonMonthCount && { season_month_count: sportInfo.seasonMonthCount }),
  }
}

export const SportInfoDTOToSportInfo = (
  sportInfoDTO: SportInfoDTO | undefined
): ISportInfo | undefined => {
  if (!sportInfoDTO) return
  return {
    sportType: sportInfoDTO.sport_type,
    membersNumber: `${sportInfoDTO.members_number}`,
    seasonStartMonth: `${sportInfoDTO.season_start_month}`,
    seasonMonthCount: sportInfoDTO.season_month_count,
  }
}
