import { AUTH_TOKEN_KEY } from '@assets/constants'
import { HttpRepository, HttpResponse } from '@domain/repositories/HttpRepository'
import { ErrorFromRes, ServerError } from '@microservices/errors/http'
import axios, { AxiosInstance, AxiosError } from 'axios'
import { LocalStorage } from './LocalStorage'

const headers = {
  'Content-Type': 'application/json',
}

const localStorage = new LocalStorage()

export class Http implements HttpRepository {
  private api: AxiosInstance
  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers,
    })
    this.setUpInterceptor()
  }

  setUpInterceptor() {
    this.api.interceptors.request.use(
      async (config) => {
        const token = await localStorage.getItem(AUTH_TOKEN_KEY)
        if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )
  }

  async post<T>(
    path: string,
    params?: Record<string, any> | undefined,
    config?: any
  ): Promise<HttpResponse<T> | undefined> {
    try {
      const response = await this.api.post(path, params, { ...config })
      return {
        status: response.status,
        data: response.data as T,
      }
    } catch (error) {
      if (error instanceof AxiosError) throw this.handleAxiosError(error)
    }
  }

  async get<T>(
    path: string,
    params?: Record<string, any> | undefined,
    config?: any
  ): Promise<HttpResponse<T> | undefined> {
    try {
      const response = await this.api.get(path, { ...config, params })
      return {
        status: response.status,
        data: response.data as T,
      }
    } catch (error) {
      if (error instanceof AxiosError) throw this.handleAxiosError(error)
    }
  }

  async patch<T>(
    path: string,
    params?: Record<string, any> | undefined,
    config?: any
  ): Promise<HttpResponse<T> | undefined> {
    try {
      const response = await this.api.patch(path, params, { ...config })
      return {
        status: response.status,
        data: response.data as T,
      }
    } catch (error) {
      if (error instanceof AxiosError) throw this.handleAxiosError(error)
      throw new ServerError()
    }
  }

  handleAxiosError(error: AxiosError<any, any>) {
    const code = error.code
    const status = error.response?.data.status ?? error.status
    const message = error.response?.data.message ?? error.message
    const errorFromRes = ErrorFromRes(status, code, message)
    if (errorFromRes) return errorFromRes
    return new ServerError(message)
  }
}
