import { TransactionActions } from './actions'
import { ITransactionsState } from './interfaces'

export const TransactionsReducer = (
  state: ITransactionsState,
  action: TransactionActions
): ITransactionsState => {
  const reducerActions = {
    SET_TRANSACTIONS: () => {
      return { ...state, transactions: action.payload }
    },
    SET_LOADING: () => {
      return { ...state, loading: action.payload }
    },
    default: () => state,
  }
  return <ITransactionsState>(reducerActions[action.type] || reducerActions.default)()
}
