import React from 'react'

import { adminInfoFields } from '@views/schemas'
import { RenderRow, Form } from '@views/components'

type Props = {
  control: any
  errors: any
  onSsnFocus?: () => void
  hasBankAccount?: boolean
}

export const AdminForm: React.FC<Props> = ({ control, errors, onSsnFocus, hasBankAccount }) => {
  return (
    <Form showDisclaimer>
      <RenderRow
        fields={[adminInfoFields.first_name, adminInfoFields.last_name]}
        disabled={
          hasBankAccount ? [adminInfoFields.first_name, adminInfoFields.last_name] : undefined
        }
        errors={errors}
        control={control}
      />
      <RenderRow
        fields={[adminInfoFields.phone, adminInfoFields.email]}
        disabled={
          hasBankAccount ? [adminInfoFields.phone, adminInfoFields.email] : [adminInfoFields.email]
        }
        errors={errors}
        control={control}
      />
      <RenderRow
        fields={[{ ...adminInfoFields.ssn, onFocus: onSsnFocus }, adminInfoFields.birthday]}
        disabled={hasBankAccount ? [adminInfoFields.ssn, adminInfoFields.birthday] : undefined}
        errors={errors}
        control={control}
      />
      <RenderRow
        fields={[adminInfoFields.address_line_1, adminInfoFields.address_line_2]}
        disabled={
          hasBankAccount
            ? [adminInfoFields.address_line_1, adminInfoFields.address_line_2]
            : undefined
        }
        errors={errors}
        control={control}
      />
      <RenderRow
        fields={[adminInfoFields.city, adminInfoFields.state, adminInfoFields.zip_code]}
        disabled={
          hasBankAccount
            ? [adminInfoFields.city, adminInfoFields.state, adminInfoFields.zip_code]
            : undefined
        }
        errors={errors}
        control={control}
      />
    </Form>
  )
}
