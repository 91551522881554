import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { AntDesign } from '@expo/vector-icons'
import { AppText, openModal, PermissionContainer } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter, formatDate, toTitleString } from '@views/utils'
import { useAccount, useAuth, useMemberCards } from '@views/hooks'
import { lockCardInteractionsTypes } from '@views/contexts'
import { MemberCard } from '@domain/models'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { UnauthenticatedError } from '@microservices/errors/http'

type Props = {
  card: MemberCard
  cardIndex: number
  indexCardShowing: number
  setIndexCardShowing: React.Dispatch<React.SetStateAction<number>>
}

export const MemberCardMobileVerion: React.FC<Props> = ({
  card,
  cardIndex,
  indexCardShowing,
  setIndexCardShowing,
}) => {
  const { logout } = useAuth()
  const { onLockCardInteractions, marqetaBootstrap } = useMemberCards()
  const cardHolderName = `${card.account_info.user.first_name} ${card.account_info.user.last_name}`
  const isExpanded = cardIndex === indexCardShowing

  const hanldeLockInteraction = (type: lockCardInteractionsTypes) => {
    onLockCardInteractions({
      type,
      cardId: card.id,
      userId: card.account_info.user_id,
      currentCardStatus: card.card_status,
    })
  }

  const onFailure = () => {
    openModal({
      dismiss: true,
      type: 'error',
      title: 'An Error Has Occured',
      subtitle: 'Please try again',
    })
  }

  const handleExpand = async () => {
    if (isExpanded) {
      setIndexCardShowing(-1)
      return
    }
    setIndexCardShowing(cardIndex)

    if (card.form === 'VIRTUAL') {
      window.marqeta.destroy()
      try {
        const tokenRes = await bankingService(bankingRepo).getCardClientToken(card.id)

        if (!tokenRes) {
          onFailure()
          return
        }

        marqetaBootstrap(tokenRes.client_token, onFailure)
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
      }
    }
  }

  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.subInfoContainer}>
        <View style={styles.buttonsContainer}>
          <View style={styles.memberCardHeader}>
            <PermissionContainer>
              <>
                {card.card_status === 'ACTIVE' && (
                  <TouchableOpacity
                    style={styles.memberDetailsHeaderButton}
                    onPress={() => hanldeLockInteraction(lockCardInteractionsTypes.LOCK)}>
                    <AntDesign name="lock1" size={24} color={COLORS.GRAY_700} />
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  onPress={() => hanldeLockInteraction(lockCardInteractionsTypes.SUSPEND)}>
                  <AntDesign name="closesquare" size={24} color={COLORS.GRAY_700} />
                </TouchableOpacity>
              </>
            </PermissionContainer>
          </View>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Cardholder Name</AppText>
          <AppText style={styles.member}>{cardHolderName}</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Card type</AppText>
          <AppText style={styles.status}>{toTitleString(card.form)}</AppText>
        </View>
        <View style={styles.clubInfoRow}>
          <AppText style={styles.tabHeader}>Status</AppText>
          <AppText style={styles.status}>{toTitleString(card.card_status)}</AppText>
        </View>
        <View style={isExpanded ? styles.clubInfoRow : styles.clubInfoRowLast}>
          <AppText style={styles.tabHeader}>Amount</AppText>
          <AppText style={styles.status}>
            {currencyFormatter(card.account_info.available_balance / 100)}
          </AppText>
        </View>
        {isExpanded ? (
          <>
            {card.form === 'VIRTUAL' ? (
              <View>
                <View style={styles.clubInfoRow}>
                  <AppText style={styles.tabHeader}>Card number</AppText>
                  <AppText style={[styles.status, styles.pan]} id="display-card-pan" />
                </View>
                <View style={styles.clubInfoRow}>
                  <AppText style={styles.tabHeader}>CVV</AppText>
                  <AppText style={[styles.status, styles.cvv]} id="display-card-cvv" />
                </View>
                <View style={styles.clubInfoRow}>
                  <AppText style={styles.tabHeader}>Expiration date</AppText>
                  <AppText style={[styles.status, styles.exp]} id="display-card-exp" />
                </View>
              </View>
            ) : (
              <View style={styles.clubInfoRow}>
                <AppText style={styles.tabHeader}>Card number</AppText>
                <AppText style={styles.status}>{card.last_four}</AppText>
              </View>
            )}
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Cardholder role</AppText>
              <AppText style={styles.status}>
                {card.account_info.is_subaccount ? 'Team Admin' : 'Org Admin'}
              </AppText>
            </View>
            <View style={styles.clubInfoRow}>
              <AppText style={styles.tabHeader}>Date created</AppText>
              <AppText style={styles.status}>{formatDate(card.creation_time, 'MM/DD/YY')}</AppText>
            </View>
          </>
        ) : null}
        <TouchableOpacity style={styles.details} onPress={handleExpand}>
          <AppText>{isExpanded ? 'Hide' : 'View'} Details</AppText>
          {isExpanded ? (
            <AntDesign size={16} name="caretup" />
          ) : (
            <AntDesign size={16} name="caretdown" />
          )}
        </TouchableOpacity>
      </View>
    </View>
  )
}

export const MemberCardsMobileVersion: React.FC = () => {
  const { memberCards } = useMemberCards()
  const { userAccount, currentSelectedAccount } = useAccount()
  const isOrgSelected = userAccount.id === currentSelectedAccount.id

  const renderCardsByCurrentSelecterdAccount = () => {
    const cards = memberCards.filter((card) => card.account_info.id === currentSelectedAccount.id)
    return cards.map((card, index) => (
      <MemberCardMobileVerion
        key={card.id}
        card={card}
        cardIndex={index}
        indexCardShowing={indexCardShowing}
        setIndexCardShowing={setIndexCardShowing}
      />
    ))
  }

  const renderCards = () => {
    return memberCards.map((card, i) => (
      <MemberCardMobileVerion
        key={card.id}
        card={card}
        cardIndex={i}
        indexCardShowing={indexCardShowing}
        setIndexCardShowing={setIndexCardShowing}
      />
    ))
  }

  const [indexCardShowing, setIndexCardShowing] = useState(-1)
  return (
    <View style={styles.memberCardsList}>
      {isOrgSelected ? renderCards() : renderCardsByCurrentSelecterdAccount()}
    </View>
  )
}

const styles = StyleSheet.create({
  memberCardsList: {
    paddingVertical: 30,
  },
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
  },
  subInfoContainer: {
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    padding: 24,
    marginBottom: 16,
    shadowColor: COLORS.GRAY_700,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
  },
  tabHeader: {
    fontSize: 14,
    fontWeight: '600',
  },
  details: {
    paddingTop: 20,
    paddingVertical: 10,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '600',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  member: {
    fontSize: 14,
    fontWeight: '800',
  },
  status: {
    fontWeight: '300',
    fontSize: 14,
    height: 20,
  },
  clubInfoRow: {
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 3,
    borderBottomColor: COLORS.GRAY_200,
  },
  clubInfoRowLast: {
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 0,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  memberCardHeader: {
    flexDirection: 'row',
  },
  memberDetailsHeaderButton: {
    marginRight: 8,
  },
  pan: {
    width: 150,
  },
  exp: {
    width: 40,
  },
  cvv: {
    width: 30,
  },
})
