import React, { useEffect } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { AppText } from '@views/components'
import { DepositsTabWebVersion } from './DepositsTabWebVersion'
import { useAccount, useSystemConfig, useDeposits } from '@views/hooks'
import { DepositsTabMobileVersion } from './DepositsTabMobileVersion'

export const DepositsTab: React.FC = () => {
  const { isSmallDevice } = useSystemConfig()
  const { userAccount } = useAccount()
  const { deposits, getDeposits, loadingDeposits } = useDeposits()

  useEffect(() => {
    if (!loadingDeposits && !deposits.length) {
      getDeposits(userAccount.id)
    }
  }, [])

  if (loadingDeposits) return <ActivityIndicator />

  if (!deposits.length) return <AppText style={styles.noDeposits}>No deposits made yet.</AppText>

  if (isSmallDevice) return <DepositsTabMobileVersion />

  return <DepositsTabWebVersion />
}

const styles = StyleSheet.create({
  noDeposits: {
    fontSize: 16,
  },
})
