import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { COLORS } from '@assets/constants'
import { visitSupportCenter } from '@views/helpers'
import { AppText } from '../AppText'

interface Props {
  logout?: any
}

export const NeedHelp: React.FC<Props> = ({ logout }) => (
  <View>
    <TouchableOpacity onPress={visitSupportCenter}>
      <AppText bold style={styles.needHelp}>
        Need help?
      </AppText>
    </TouchableOpacity>
    {logout !== undefined && (
      <TouchableOpacity onPress={logout}>
        <AppText bold style={styles.needHelp}>
          Logout
        </AppText>
      </TouchableOpacity>
    )}
  </View>
)

const styles = StyleSheet.create({
  needHelp: {
    textDecorationLine: 'underline',
    color: COLORS.SECONDARY,
    marginBottom: 6,
  },
})
