import React from 'react'
import { ImageBackground, View, StyleSheet } from 'react-native'

import { COLORS } from '@assets/constants'
import cardBg from '@assets/images/card-bg.png'
import { AppText, Button, openModal, closeModal } from '@views/components'
import HelpCenter from './HelpCenter'
import Checklist from './Checklist'

export const SupportCard: React.FC = () => {
  const startChecklist = () => {
    closeModal()
    openModal({
      containerStyle: styles.modalContainer,
      contentStyle: styles.modalContent,
      content: <Checklist />,
      dismiss: false,
    })
  }

  const openHelpCenter = () => {
    openModal({
      containerStyle: styles.modalContainer,
      contentStyle: styles.modalContent,
      content: <HelpCenter onStartChecklist={startChecklist} />,
    })
  }

  return (
    <ImageBackground source={cardBg} style={styles.container}>
      <View>
        <AppText bold style={styles.cardTitle}>
          NEED HELP?
        </AppText>
        <AppText light>Our team is ready to assist.</AppText>
      </View>
      {/* @TODO: ADD SUPORT ACTION  */}
      <Button
        type="light"
        text="Go to Support"
        onPress={openHelpCenter}
        style={{ height: undefined }}
      />
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PURPLE_LIGHT,
    alignSelf: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    padding: 15,
    height: 200,
  },
  cardTitle: { fontSize: 20 },
  modalContainer: {
    backgroundColor: 'rgba(0,0,0)',
    overflow: 'hidden',
  },
  modalContent: {
    position: 'absolute',
    left: 30,
    bottom: 4,
    boxShadow: '0px 6px 24px rgba(54, 55, 70, 0.08)',
    borderRadius: 8,
    width: 420,
    height: 617,
    margin: 0,
    padding: 20,
  },
})

export { default as HelpCenter } from './HelpCenter'
export { default as Checklist } from './Checklist'
