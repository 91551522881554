import React, { useState } from 'react'
import { View, TouchableOpacity, Image, StyleSheet, ScrollView } from 'react-native'
import { AppText, closeModal, openModal } from '@views/components'
import { useSystemConfig } from '@views/hooks'

import { COLORS } from '@assets/constants'
import trasferFundsCard from '@assets/images/transfer-funds-card.png'
import internalTransferCard from '@assets/images/internal-transfer-card.png'

import closeIcon from '@assets/images/close-icon.png'
import { TransferFunds } from '../TransferFunds'
import { InternalTransfer } from '../InternalTransfer'

export const CreatePayment = () => {
  const { isSmallDevice } = useSystemConfig()
  const [transferHover, setTransferHover] = useState(false)
  const [internalTransferHover, setInternalTransferHover] = useState(false)

  const openTransferFunds = () => {
    openModal({
      dismiss: false,
      content: <TransferFunds />,
      contentStyle: styles.transferFunds,
    })
  }

  const openInternalTransfer = () => {
    openModal({
      dismiss: false,
      content: <InternalTransfer />,
      contentStyle: styles.internalTransfer,
    })
  }

  return (
    <>
      <View style={styles.headerContainer}>
        <View>
          <AppText bold style={styles.title}>
            Create a Payment
          </AppText>
          <AppText style={styles.subtitle}>Choose payment type</AppText>
        </View>
        <TouchableOpacity onPress={closeModal}>
          <Image source={closeIcon} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.optionsContainer}>
        <TouchableOpacity 
          {...{onMouseEnter: () => setTransferHover(true), onMouseLeave: () => setTransferHover(false)}}
          style={[styles.imageContainer, { 
            borderColor: transferHover? COLORS.PRIMARY : COLORS.BACKGROUND, 
            marginRight: isSmallDevice? 0 : 10, 
            marginBottom: isSmallDevice? 10 : 0 }]}
          onPress={openTransferFunds}
        >
          <Image source={trasferFundsCard} style={styles.optionsImage} />
        </TouchableOpacity>
        <TouchableOpacity 
          {...{onMouseEnter: () => setInternalTransferHover(true), onMouseLeave: () => setInternalTransferHover(false)}}
          style={[styles.imageContainer, { 
            borderColor: internalTransferHover? COLORS.PRIMARY : COLORS.BACKGROUND }]} 
          onPress={openInternalTransfer}
        >
          <Image source={internalTransferCard} style={styles.optionsImage} />
        </TouchableOpacity>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  headerContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  title: { fontSize: 24, marginBottom: 8 },
  subtitle: { fontSize: 16 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  optionsContainer: {
    flexDirection: 'row',
    marginTop: 20,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  optionsImage: { width: 240, height: 200, borderRadius: 8 },
  imageContainer: { padding: 15, borderWidth: 2, borderRadius: 8 },
  internalTransfer: { minWidth: undefined, width: '80%', maxHeight: 630 },
  transferFunds: { minWidth: undefined, width: '80%', maxHeight: 580 },
})
