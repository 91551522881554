import { HttpRepository } from '@domain/repositories/HttpRepository'
import { BusinessRepository } from '@domain/repositories/BusinessRepository'
import { BasicInfoToBusinessDTO } from '@microservices/dto/BusinessDTO'
import { IBasicInfo, ISportInfo } from '@views/schemas'
import {
  SportInfoDTO,
  SportInfoDTOToSportInfo,
  SportInfoToSportInfoDTO,
} from '@microservices/dto/SportInfoDTO'
import { Association, User } from '@domain/models'

export const businessRepository = (client: HttpRepository): BusinessRepository => ({
  getBusinessById: async (id: string) => {
    return await client.get<Association>(`business/${id}`)
  },

  createBusiness: async (basicInfo: IBasicInfo) => {
    const businessDto = BasicInfoToBusinessDTO(basicInfo)
    return await client.post<User>('business', businessDto)
  },

  getBasicInfo: async (id?: string) => {
    return await client.get(`business/basic-info/${id}`)
  },

  patchBasicInfo: async (id: string, basicInfo: Partial<IBasicInfo>) => {
    const businessDto = BasicInfoToBusinessDTO(basicInfo)
    return await client.patch(`business/basic-info/${id}`, businessDto)
  },

  createSportInfo: async (sportInfo: ISportInfo, association_id) => {
    const sportInfoDto = SportInfoToSportInfoDTO(sportInfo)
    return await client.post<SportInfoDTO>(`business/sport-info/${association_id}`, sportInfoDto)
  },
  getSportInfo: async (association_id: string) => {
    const response = await client.get<SportInfoDTO>(`business/sport-info/${association_id}`)
    if (response) return SportInfoDTOToSportInfo(response.data)

    return
  },

  patchSportInfo: async (sportInfo: Partial<ISportInfo>, association_id) => {
    const sportInfoDto = SportInfoToSportInfoDTO(sportInfo)
    const response = await client.patch<SportInfoDTO>(
      `business/sport-info/${association_id}`,
      sportInfoDto
    )

    if (response) return SportInfoDTOToSportInfo(response.data)

    return
  },
  postBusinessDocuments: async (business_id?: string, payload?: any) => {
    await client.post(`documents/business/${business_id}`, payload.body, payload.headers)
    return
  },
  createBusinessVerification: async (business_id?: string) => {
    return await client.post('verification', { business_id })
  },
  createPersonVerification: async (person_id?: string) => {
    return await client.post('verification', { person_id })
  },
})
