import { legalAddressProps } from '@assets/constants'

export const getDirtyPayload = (data: any, dirtyFields: any) => {
  let payload = null
  const dirtyEntries = Object.entries(data).filter(([key]) => dirtyFields[key])
  if (dirtyEntries.length > 0) {
    payload = Object.fromEntries(dirtyEntries)
    if (Object.keys(payload).some((key) => legalAddressProps.includes(key)))
      for (const addressKey of legalAddressProps)
        Object.assign(payload, {
          ...(data.hasOwnProperty(addressKey) && { [addressKey]: data[addressKey] }),
        })
  }
  return payload
}
