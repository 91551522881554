import React, { FC, useCallback, useEffect, useState } from 'react'
import { usePlaidLink, PlaidLinkOnEvent } from 'react-plaid-link'
import { Button } from '@views/components'
import { ViewStyle } from 'react-native'
import { PlaidLinkToken } from '@domain/models'
import { ExternalAccountData } from '@views/contexts'

interface PlaidLinkProps {
  buttonText: string
  style: ViewStyle
  createPlaidLinkToken: () => Promise<PlaidLinkToken>
  exchangePublicToken: (data: ExternalAccountData) => Promise<void>
  callbackFunction: () => any
  user: any
}

export const PlaidLink: FC<PlaidLinkProps> = ({
  buttonText,
  style,
  createPlaidLinkToken,
  exchangePublicToken,
  callbackFunction,
  user,
}) => {
  const [linkToken, setLinkToken] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const shouldCreatePlaidToken = user.Banking_account.length && !user.External_account.length
    if (shouldCreatePlaidToken)
      createPlaidLinkToken().then((response: PlaidLinkToken) => {
        setLinkToken(response.link_token)
      })
  }, [user, createPlaidLinkToken])

  const onSuccess = (public_token: string, metadata: any) => {
    exchangePublicToken({
      public_token,
      accounts: metadata.accounts.map((acct: any) => acct.id),
      institution: metadata.institution.institution_id,
    }).then(() => {
      setLoading(false)
      callbackFunction()
    })
  }

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName) => {
    if (eventName && eventName === 'ERROR') exit({ force: true })
  }, [])

  const { open, ready, exit } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onEvent,
    onExit: () => setLoading(false),
  })
  return (
    <Button
      onPress={() => {
        setLoading(true)
        open()
      }}
      text={loading ? 'Loading...' : buttonText}
      disabled={!ready}
      style={style}
      loading={!ready || loading}
    />
  )
}
