// @ts-nocheck
import { AddressDTO } from '@microservices/dto/AddressDTO'
import { IBasicInfo } from '@views/schemas'

export interface BusinessCreateDTO {
  entity_name: string
  type: string
  ein: string
  formation_date: Date
  formation_state: string
  email: string
  phone_number: string
  structure: string
  legal_address: AddressDTO
}

export const BasicInfoToBusinessDTO = (
  basicInfo: Partial<IBasicInfo>
): Partial<BusinessCreateDTO> => {
  let legal_address = null
  if (basicInfo.orgAddrStreet1)
    legal_address = {
      address_line_1: basicInfo.orgAddrStreet1,
      address_line_2: basicInfo.orgAddrStreet2,
      city: basicInfo.orgAddrCity,
      state: basicInfo.orgAddrState,
      postal_code: basicInfo.orgAddrZip,
    }

  return {
    ...(basicInfo.orgLegalName && { entity_name: basicInfo.orgLegalName }),
    ...(basicInfo.orgType && { type: basicInfo.orgType }),
    ...(basicInfo.orgEIN && { ein: basicInfo.orgEIN }),
    ...(basicInfo.orgFormationDate && { formation_date: new Date(basicInfo.orgFormationDate) }),
    ...(basicInfo.orgFormationState && { formation_state: basicInfo.orgFormationState }),
    ...(basicInfo.orgPhone && { phone_number: basicInfo.orgPhone }),
    ...(basicInfo.orgEmail && { email: basicInfo.orgEmail }),
    ...(basicInfo.orgLegalStructure && { structure: basicInfo.orgLegalStructure }),
    ...(legal_address && { legal_address }),
  }
}
