import React from 'react'
import {
  AppText,
  Button,
  FormInput,
  InputTypes,
  Form,
  openModal,
  closeModal,
} from '@views/components'
import { View, StyleSheet } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { COLORS, emailRegex, SUB_ADMIN_SIGNUP_ROUTE } from '@assets/constants'
import * as yup from 'yup'
import { useSystemConfig, useAuth } from '@views/hooks'
import { AntDesign } from '@expo/vector-icons'
import { userService } from '@domain/service'
import { userRepo } from '@microservices/instances'
import { UnauthenticatedError } from '@microservices/errors/http'

const addSubAccountSchema = yup.object().shape({
  subAccountName: yup.string().required('Required'),
  subAccountEmail: yup.string().required('Required').matches(emailRegex, 'Invalid Format'),
})

type Props = {
  subAccountName?: string
  subAccountEmail?: string
  mainAccountName: string
}

export const AddSubAccount: React.FC<Props> = ({
  subAccountEmail = '',
  subAccountName = '',
  mainAccountName,
}) => {
  const { isSmallDevice } = useSystemConfig()
  const { user, logout } = useAuth()

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(addSubAccountSchema),
    defaultValues: {
      subAccountEmail,
      subAccountName,
    },
  })

  const onSubmit = async (data: any) => {
    const dataWithAccountName = {
      ...data,
      mainAccountName,
      url: `${window.location.origin}/signup?redirect_uri=${SUB_ADMIN_SIGNUP_ROUTE}&business_id=${user?.association?.id}&subaccount_name=${data.subAccountName}`,
    }
    try {
      const res = await userService(userRepo).sendSubAccountEmail(dataWithAccountName)

      if (res)
        openModal({
          type: 'success',
          dismiss: false,
          title: 'Email Successfully Sent',
          subtitle: 'New sub-account has been added.',
          confirmationButtonMessage: 'Back to Business Account',
        })
    } catch (err) {
      if (err instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  const onClose = () => {
    openModal({
      type: 'warning',
      title: 'The Entered Data Was Not Saved',
      subtitle:
        'Are you sure you want to close and go back to your Business account? You may lose the data you have entered.',
      dismiss: false,
      buttons: [
        <Button
          key="confirmation"
          text="Continue to Sub-Account Creation"
          onPress={() => {
            const { subAccountEmail, subAccountName } = getValues()
            openModal({
              content: (
                <AddSubAccount
                  subAccountEmail={subAccountEmail}
                  subAccountName={subAccountName}
                  mainAccountName={mainAccountName}
                />
              ),
              dismiss: false,
            })
          }}
        />,
        <Button
          key="cancel"
          onPress={closeModal}
          type="secondary"
          text="Back to Business Account"
          style={styles.cancelButton}
        />,
      ],
    })
  }

  return (
    <>
      <View style={[isSmallDevice ? styles.headerMobile : styles.headerBig, styles.header]}>
        <AppText bold style={styles.title}>
          Add New Sub-Account
        </AppText>
        <AntDesign name="closesquare" size={24} color={COLORS.GRAY_700} onPress={onClose} />
      </View>
      <AppText semiBold style={styles.subTitle}>
        Sub-account information
      </AppText>
      <Form>
        <FormInput
          type={InputTypes.text}
          name={'subAccountName'}
          label={'Sub-account name'}
          control={control}
          error={errors.subAccountName as unknown as FieldError}
          placeholder={'Enter sub-account name'}
        />
        <FormInput
          type={InputTypes.text}
          name={'subAccountEmail'}
          label={'Sub-account email'}
          control={control}
          error={errors.subAccountEmail as unknown as FieldError}
          placeholder={'Enter sub-account email'}
        />
        <Button
          style={styles.btn}
          onPress={handleSubmit(onSubmit)}
          text={isSubmitting ? 'Loading...' : 'Submit'}
          loading={isSubmitting}
        />
      </Form>
    </>
  )
}

const styles = StyleSheet.create({
  title: { fontSize: 24 },
  subTitle: { fontSize: 16 },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  headerBig: {
    width: 400,
  },
  headerMobile: {
    width: 281,
  },
  btn: {
    marginTop: 16,
  },

  cancelButton: {
    marginTop: 16,
    borderWidth: 0,
  },
})
