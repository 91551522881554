import AsyncStorage from '@react-native-async-storage/async-storage'

export const getData = async (key: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key)
    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (e) {
    console.error('Error reading local store.', { e })
  }
}

export const storeData = async (key: string, data: any): Promise<void> => {
  try {
    const jsonValue = JSON.stringify(data)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (e) {
    console.error('Error writing to local store.', { e })
  }
}
