import React, { useEffect, useState } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppText,
  Form,
  FormInput,
  Button,
  Card,
  Row,
  openModal,
  closeModal,
  InputModal,
} from '@views/components'
import { fundAccountFields, FundAccountSchema, achAmountField, AchSchema } from '@views/schemas'
import { useAuth, useCreateAccount, useSystemConfig } from '@views/hooks'
import { QRCodeModal } from '@views/components'

import { COLORS } from '@assets/constants'
import cardsExample from '@assets/images/cards_example.png'
import depositExample from '@assets/images/deposit_example.png'
import transferExample from '@assets/images/transfer_example.png'
import autoPayExample from '@assets/images/autopay_example.png'
import { PlaidLink } from '@views/components/PlaidLink'
import { bankingService } from '@domain/service/BankingService'
import { bankingRepo } from '@microservices/instances/BankingRepo'
import Header from './Header'
import { UnauthenticatedError } from '@microservices/errors/http'

const FundAccount = ({ navigation }: { navigation: any }) => {
  const { isSmallDevice, isMobile } = useSystemConfig()
  const [isACHSubmit, setIsACHSubmit] = useState<boolean>(false)
  const { user, logout } = useAuth()
  const {
    createAccountState: { data, loading },
    createPlaidLinkToken,
    exchangePublicToken,
  } = useCreateAccount()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      [fundAccountFields.sportsOrgName.name]: user?.association.name,
    },
    resolver: yupResolver(FundAccountSchema),
  })

  const sendACH = async (sendData: any) => {
    if (!bankAccount || !externalAccount) throw Error('Missing account information')
    const achData = {
      user_id: user?.id,
      amount: sendData.amount,
      originating_account_id: bankAccount.id,
      receiving_account_id: externalAccount.id,
    }
    try {
      await bankingService(bankingRepo).sendACH(achData)
      closeModal()
      openModal({
        dismiss: false,
        type: 'success',
        title: 'Sports Organization Activated',
        subtitle: 'Your deposit was successful.',
        buttons: [
          <Button
            key={'ach_success_modal'}
            style={styles.modalButton}
            type="primary"
            text="View Account"
            onPress={() => {
              window.location.reload()
              closeModal()
            }}
          />,
        ],
      })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  const onSubmit = (submitData: any) => {
    if (submitData.depositType === 'ach') {
      setIsACHSubmit(true)
      return
    } else if (isMobile()) navigation.navigate('initialDepositCheck')
    else
      openModal({
        content: (
          <QRCodeModal component={'initialDepositCheck'} title={'Remote Deposit By Check'} />
        ),
      })
  }

  useEffect(() => {
    if (isACHSubmit && !externalAccount)
      openModal({
        title: 'Integrate your Bank',
        type: 'warning',
        subtitle: 'We use plaid to connect to your bank',
        content: (
          <PlaidLink
            buttonText={'Link Bank Account'}
            style={!isSmallDevice ? styles.button : {}}
            createPlaidLinkToken={createPlaidLinkToken}
            exchangePublicToken={exchangePublicToken}
            callbackFunction={closeModal}
            user={user}
          />
        ),
      })
    else if (isACHSubmit && externalAccount)
      openModal({
        title: 'Deposit by ACH',
        content: (
          <InputModal
            field={achAmountField}
            schema={AchSchema}
            subLabel="Minimum $50"
            onSubmit={sendACH}
          />
        ),
      })
    setIsACHSubmit(false)
  }, [isACHSubmit])

  let { bankAccount, externalAccount } = data
  if (bankAccount === undefined && user?.Banking_account) [bankAccount] = user?.Banking_account
  if (externalAccount === undefined && user?.External_account)
    [externalAccount] = user?.External_account

  return (
    <ScrollView contentContainerStyle={!isSmallDevice ? styles.container : styles.containerMobile}>
      <View>
        <Header
          subtitle="Make a remote deposit ($50 minimum) to set up your account."
          route="Fund your account"
        />
        <View style={!isSmallDevice && styles.inputWrapper}>
          <Form showButtons={false}>
            <FormInput
              name={fundAccountFields.sportsOrgName.name}
              label={fundAccountFields.sportsOrgName.label}
              type={fundAccountFields.sportsOrgName.type}
              control={control}
              error={errors[fundAccountFields.sportsOrgName.name] as unknown as FieldError}
              placeholder={user?.association.name || fundAccountFields.sportsOrgName.placeholder} // association name must be set
              disabled={!!user?.association.name} // will be always true
            />
            <View style={styles.radioButtons}>
              <FormInput
                name={fundAccountFields.depositType.name}
                label={fundAccountFields.depositType.label}
                type={fundAccountFields.depositType.type}
                control={control}
                error={errors[fundAccountFields.depositType.name] as unknown as FieldError}
                items={fundAccountFields.depositType.items}
              />
            </View>
          </Form>
        </View>
        <AppText style={styles.featuresTitle} bold>
          Sports organization features
        </AppText>
        <ScrollView horizontal contentContainerStyle={styles.cardRow}>
          <Card style={styles.card} color={COLORS.CYAN_200} imgUrl={cardsExample} title="Cards">
            <AppText>Create virtual/physical cards</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.BLUE_300}
            imgUrl={depositExample}
            title="Make deposits">
            <AppText>Make deposits by check</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.AZURE_LIGHT}
            imgUrl={transferExample}
            title="Sending funds">
            <AppText>Transfer or wire funds</AppText>
          </Card>
          <Card
            style={styles.card}
            color={COLORS.PURPLE_LIGHT}
            imgUrl={autoPayExample}
            title="Auto pay">
            <AppText>Create regular payments</AppText>
          </Card>
        </ScrollView>
      </View>
      <Row style={styles.buttonWrapper}>
        <Button
          style={!isSmallDevice ? styles.button : {}}
          disabled={watch('depositType') === undefined}
          loading={isSubmitting || loading}
          onPress={handleSubmit(onSubmit)}
          type="primary"
          text={
            isSubmitting || loading
              ? 'Loading...'
              : bankAccount && !externalAccount && watch('depositType') === 'ach'
              ? 'Link a bank account'
              : 'Make a deposit'
          }
        />
      </Row>
    </ScrollView>
  )
}

export default FundAccount

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: COLORS.WHITE,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  containerMobile: {
    paddingHorizontal: 16,
    backgroundColor: COLORS.WHITE,
    flexGrow: 1,
  },
  subTitle: {
    fontSize: 18,
    marginTop: 32.5,
  },
  inputWrapper: {
    maxWidth: '50%',
  },
  radioButtons: {
    marginTop: 30,
  },
  featuresTitle: {
    fontSize: 16,
    marginBottom: 16,
  },
  cardRow: {
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  card: {
    width: '24%',
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginBottom: 30,
    marginTop: 14,
  },
  button: {
    maxWidth: 270,
  },
  modalButton: {
    width: 400,
  },
})
