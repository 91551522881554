import { Divider } from '@rneui/base'
import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'

import {
  COLORS,
  checklistData,
  CHECKLIST_INITAL_STEP,
  CHECKLIST_FINAL_STEP,
} from '@assets/constants'
import { AppText } from '../AppText'
import { Button } from '../Button'
import { closeModal } from '../Modal'
import { useSystemConfig } from '@views/hooks'

const Checklist: React.FC = () => {
  const [step, setStep] = useState(CHECKLIST_INITAL_STEP)
  const { isSmallDevice } = useSystemConfig()

  const getNextLabel = () => {
    switch (step) {
      case CHECKLIST_INITAL_STEP:
        return 'Get started'
      case CHECKLIST_FINAL_STEP:
        return 'Finish'
      default:
        return 'Next'
    }
  }

  const onSkip = () => {
    closeModal()
  }

  const onNext = () => {
    if (step === CHECKLIST_FINAL_STEP) onSkip()
    else setStep(step + 1)
  }

  return (
    <View style={styles.container}>
      {step === CHECKLIST_INITAL_STEP ? (
        <AppText semiBold style={[styles.title, styles.header]}>
          Let`s start!
        </AppText>
      ) : (
        <View style={styles.header}>
          <Image source={checklistData[step].progress} style={styles.progress} />
          <AppText semiBold style={styles.stepTitle}>
            Step {step}/{CHECKLIST_FINAL_STEP}
          </AppText>
        </View>
      )}
      <Divider style={styles.divider} />
      <View style={styles.container}>
        <Image
          source={checklistData[step].image}
          style={!isSmallDevice ? styles.checklistImage : styles.checklistImageMobile}
        />
        <AppText semiBold style={styles.title}>
          {checklistData[step].title}
        </AppText>
        <AppText style={styles.content}>{checklistData[step].content}</AppText>
      </View>
      <Divider style={styles.divider} />
      <View style={styles.footer}>
        <Button
          type="secondary"
          text="Skip"
          onPress={onSkip}
          style={styles.button}
          textStyle={styles.buttonText}
        />
        <Button
          text={getNextLabel()}
          onPress={onNext}
          style={styles.button}
          textStyle={styles.buttonText}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginTop: -8,
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    lineHeight: 27,
    marginTop: 20,
  },
  progress: {
    width: 24,
    height: 24,
  },
  stepTitle: {
    fontSize: 16,
    lineHeight: 24,
  },
  divider: {
    backgroundColor: COLORS.GRAY_300,
    marginRight: -20,
    marginBottom: 20,
    marginLeft: -20,
  },
  checklistImage: {
    width: 380,
    height: 187,
    borderRadius: 8,
  },
  checklistImageMobile: {
    width: '100%',
    height: 187,
    borderRadius: 8,
  },
  content: {
    flex: 1,
    marginTop: 4,
    paddingBottom: 10,
    maxHeight: 190,
    overflowY: 'auto',
    '-webkit-mask-image':
      '-webkit-linear-gradient(270deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.1) 100%)',
  },
  footer: {
    display: 'flex',
    gap: 16,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 43,
    paddingTop: 13,
    paddingBottom: 13,
  },
  buttonText: {
    fontSize: 12,
  },
})

export default Checklist
