import { isAdult } from './isAdult'

export const checkBOFormData = (info: any, setError: any, previousTotal: number, numFormsAdded = 1) => {
  let success = true
  let totalPercentage = !!previousTotal ? previousTotal : 0
  const emails: string[] = []
  for (let i = 1; i <= numFormsAdded; i++) {
    if (!info[`percentageOwnershipBO${i}`]) continue
    const percent = parseInt(info[`percentageOwnershipBO${i}`].replace(/\D/g, ''), 10)

    totalPercentage += percent
    if (percent < 25) {
      setError(`percentageOwnershipBO${i}`, {
        type: 'custom',
        message: 'Must be at least 25%',
      })
      success = false
    }

    if (!isAdult(info[`dobBO${i}`])) {
      setError(`dobBO${i}`, {
        type: 'custom',
        message: 'Must be 18 or older',
      })
      success = false
    }

    if (totalPercentage > 100) {
      setError(`percentageOwnershipBO${i}`, {
        type: 'custom',
        message: 'Total percentage of all beneficial owners adds up to more than 100%',
      })
      success = false
    }

    if (emails.find((email) => email === info[`emailBO${i}`])) {
      setError(`emailBO${i}`, {
        type: 'custom',
        message: 'Email already used above.',
      })
      success = false
    }
    emails.push(info[`emailBO${i}`])
  }
  return success
}
