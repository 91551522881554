import React, { useEffect, useState } from 'react'
import {
  Row,
  AppText,
  openModal,
  TransactionsFilterModal,
  RNRadioButton,
  IInputItems,
} from '@views/components'
import { COLORS, UserTypesEnum } from '@assets/constants'
import { Image, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { TransactionsFormValues } from '@views/schemas'
import filterIcon from '@assets/images/filter-icon.png'
import { useTransactions } from '@views/hooks/useTransactions'
import { useAccount, useAuth, useSystemConfig } from '@views/hooks'
import moment from 'moment'

type TransactionsFilterBarProps = {
  pendingOrPosted: 'pending' | 'posted'
  filterParams: TransactionsFormValues
  setFilterParams: React.Dispatch<React.SetStateAction<TransactionsFormValues>>
  bankAccountId: string
  setBankAccountId: React.Dispatch<React.SetStateAction<string>>
}

export const TransactionsFilterBar: React.FC<TransactionsFilterBarProps> = ({
  pendingOrPosted,
  filterParams,
  setFilterParams,
  bankAccountId,
  setBankAccountId,
}) => {
  const { user } = useAuth()
  const { subAccounts, userAccount } = useAccount()
  const [radioButtonItems, setRadioButtonItems] = useState<IInputItems[]>([])

  const { getTransactions } = useTransactions()
  const { isMediumDevice } = useSystemConfig()

  const isSubAccount = user?.type === UserTypesEnum.SUB_ADMIN

  useEffect(() => {
    if (!isSubAccount) {
      setRadioButtonItems(
        [userAccount, ...subAccounts].map((item) => {
          return {
            label: item.nickname || `U-${item.account_type}`,
            value: item.id,
          }
        })
      )
    }
  }, [subAccounts])

  const filterTransactionsByParams = ({
    startDate,
    endDate,
    transactionType,
  }: TransactionsFormValues) => {
    const fromDate = moment(new Date(startDate))
    const toDate = moment(new Date(endDate))
    getTransactions({
      pendingOrPosted,
      fromDate: fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '',
      toDate: toDate.isValid() ? toDate.format('YYYY-MM-DD') : '',
      type: transactionType,
      bankAccountId,
    })
    setFilterParams({ startDate, endDate, transactionType })
  }

  const switchAccountTransactions = (newBankAccountId: string) => {
    const fromDate = moment(new Date(filterParams.startDate))
    const toDate = moment(new Date(filterParams.endDate))
    setBankAccountId(newBankAccountId)
    getTransactions({
      pendingOrPosted,
      fromDate: fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '',
      toDate: toDate.isValid() ? toDate.format('YYYY-MM-DD') : '',
      type: filterParams.transactionType,
      bankAccountId: newBankAccountId,
    })
  }

  const showFilterModal = () => {
    openModal({
      content: (
        <TransactionsFilterModal
          endDate={filterParams.endDate}
          startDate={filterParams.startDate}
          transactionType={filterParams.transactionType}
          handleFilter={filterTransactionsByParams}
        />
      ),
      dismiss: false,
      contentStyle: { maxWidth: 'unset' },
    })
  }

  return (
    <Row style={styles.filterBar}>
      {!isSubAccount && (
        <AppText light style={styles.title}>
          Select the account to display transactions
        </AppText>
      )}
      <Row
        style={[
          isMediumDevice ? styles.smallContainer : styles.container,
          isSubAccount && styles.subAccountContainer,
        ]}>
        {!isSubAccount && (
          <RNRadioButton
            items={radioButtonItems}
            onChange={switchAccountTransactions}
            initialSelected={0}
            buttonInnerColor={COLORS.GRAY_300}
            buttonOuterColor={COLORS.GRAY_300}
            container={isMediumDevice ? styles.smallCheckBoxes : styles.checkBoxes}
            radioButtonStyle={isMediumDevice ? styles.smallCheckBoxItem : styles.checkBoxItem}
          />
        )}

        <TouchableOpacity
          style={[styles.addButton, isMediumDevice ? styles.smallButton : styles.largeButton]}
          onPress={showFilterModal}>
          <Image style={styles.actionIcon} source={filterIcon} />
          <AppText style={{ color: COLORS.WHITE }}>Filter</AppText>
        </TouchableOpacity>
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  filterBar: {
    justifyContent: 'space-between',
    minHeight: 60,
    padding: 4,
    flexDirection: 'column',
  },
  checkBoxItem: {
    paddingRight: 32,
    paddingBottom: 30,
  },
  smallCheckBoxItem: { paddingBottom: 20, paddingRight: 10 },
  addButton: {
    backgroundColor: COLORS.DARK,
    minWidth: 220,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 14,
    borderRadius: 4,
    borderColor: COLORS.DARK,
    borderWidth: 2,
  },
  smallButton: {
    height: 44,
    marginBottom: 20,
    marginLeft: 0,
  },
  largeButton: {
    height: 38,
    marginBottom: 30,
    marginLeft: 16,
  },
  actionIcon: {
    marginRight: 10,
    resizeMode: 'contain',
    height: 10,
    width: 15,
  },
  title: { fontSize: 16, marginBottom: 15 },
  container: {
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  smallContainer: {
    width: '100%',
    flexDirection: 'column',
  },
  checkBoxes: {
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  smallCheckBoxes: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
  },
  subAccountContainer: {
    justifyContent: 'flex-end',
  },
})
