import React, { useEffect, useState } from 'react'
import { AppText, Button, FormInput, InputTypes, openModal, closeModal } from '@views/components'
import { View, ScrollView, StyleSheet, Image } from 'react-native'
import { FieldError, FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { onlyNumberRegex } from '@assets/constants'
import * as yup from 'yup'
import { useAccount, useAuth, useSystemConfig } from '@views/hooks'
import { TransferFundsData } from '@views/contexts'
import { checkAmount, parseMoney } from '@views/helpers'
import { COLORS } from '@assets/constants'
import { AntDesign } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import warningImg from '@assets/images/warning.png'

const transferFundsSchema = yup.object().shape({
  accountOwnerName: yup.string().required('Required'),
  bankName: yup.string().required('Required'),
  routingOrABANumber: yup.string().required('Required').matches(onlyNumberRegex, 'Invalid format'),
  accountNumber: yup.string().required('Required').matches(onlyNumberRegex, 'Invalid format'),
  amount: yup.string().required('Required'),
  comments: yup.string(),
})

export const TransferFunds: React.FC = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(transferFundsSchema),
  })
  const { transferFunds, loadingTransfer, userAccount } = useAccount()
  const { user } = useAuth()
  const { isSmallDevice } = useSystemConfig()
  const [showWarning, setShowWarning] = useState(false)

  const onSubmit = (data: FieldValues) => {
    const amount = Number(parseMoney(data.amount)); 
    const amountCheck = checkAmount(amount, userAccount.available_balance)

    if(!amountCheck.valid){
      setError("amount", { message: amountCheck.message })
      return
    }

    const request = {
      ...data,
      user_id: user?.id,
      originating_account_id: userAccount.id,
      amount
    } as TransferFundsData
    transferFunds(request)
  }

  useEffect(() => {
    reset({
      from: userAccount.nickname,
    })
  }, [])

  return (
    <>
      { showWarning ? (
        <View style={styles.messageWrapper}>
          <Image source={warningImg} style={styles.image} />
          <AppText style={styles.successTitle}>The Entered Data Was Not Saved</AppText>
          <AppText style={styles.successSubtitle}>
            Are you sure you want to close and go back to your Business Account? You can lose your
            data.
          </AppText>
          <Button
            onPress={() => {
              setShowWarning(false)
            }}
            text="Continue Transfer"
          />
          <TouchableOpacity style={styles.backButton} onPress={() => closeModal()}>
            <AppText>Back to Business Account</AppText>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <View style={isSmallDevice ? styles.headerMobile : styles.header}>
            <AppText style={styles.title}>Transfer Funds</AppText>
            <AntDesign
              name="closesquare"
              size={24}
              color={COLORS.GRAY_700}
              onPress={() => setShowWarning(true)}
            />
          </View>
          <ScrollView>
            <FormInput
              disabled
              type={InputTypes.text}
              name={'from'}
              label={'From'}
              control={control}
              error={errors.from as unknown as FieldError}
            />

            <AppText semiBold style={styles.destinyText}>
              To
            </AppText>

            <FormInput
              type={InputTypes.text}
              name={'accountOwnerName'}
              label={'Account owner name'}
              control={control}
              error={errors.accountOwnerName as unknown as FieldError}
              placeholder={'Account owner name'}
            />
            <FormInput
              type={InputTypes.text}
              name={'bankName'}
              label={'Bank name'}
              control={control}
              error={errors.bankName as unknown as FieldError}
              placeholder={'Bank name'}
            />
            <FormInput
              type={InputTypes.text}
              name={'routingOrABANumber'}
              label={'Routing or ABA number'}
              control={control}
              error={errors.routingOrABANumber as unknown as FieldError}
              placeholder={'Routing or ABA number'}
            />
            <FormInput
              type={InputTypes.text}
              name={'accountNumber'}
              label={'Account number'}
              control={control}
              error={errors.accountNumber as unknown as FieldError}
              placeholder={'Account number'}
            />
            <FormInput
              type={InputTypes.money}
              name={'amount'}
              label={'Amount'}
              control={control}
              error={errors.amount as unknown as FieldError}
              placeholder={'Enter amount'}
            />
            <FormInput
              type={InputTypes.text}
              name={'comments'}
              label={'Comments'}
              control={control}
              error={errors.comments as unknown as FieldError}
              placeholder={'Enter comments'}
            />

            <Button
              onPress={handleSubmit(onSubmit)}
              text={loadingTransfer ? 'Loading...' : 'Transfer Funds'}
              disabled={loadingTransfer}
            />
          </ScrollView>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  successTitle: { fontStyle: 'normal', fontWeight: '700', fontSize: 24, marginBottom: 10 },
  successSubtitle: { marginBottom: 32, paddingHorizontal: 5 },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  backButton: {
    marginTop: 28,
    textAlign: 'center',
  },
  title: { fontSize: 24, marginBottom: 8 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 281,
  },
  destinyText: { marginBottom: 20 },
})
