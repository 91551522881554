import { EOrgInfo, LEGAL_STRUCTURES, UserTypesEnum, verificationStatuses } from '@assets/constants'
import { User } from '@domain/models'

export interface CompleteFlow {
  isComplete: boolean
  isBasicInfoComplete: boolean
  isAdminInfoComplete: boolean
  isBeneficialOwnersComplete: boolean
  isSportInfoComplete: boolean
  isReviewComplete: boolean
  hasBankAccount: boolean
  hasExternalAccount: boolean
  isCompleteAndFunded: boolean
  redirectPage?: EOrgInfo
}

export const checkForIncompleteCreateFlow = (user: User): CompleteFlow => {
  const isSubAdmin = user.type === UserTypesEnum.SUB_ADMIN
  let redirectPage: EOrgInfo = EOrgInfo.basicInfo
  let hasBeneficialOwner = 0
  let isReviewComplete = false
  if (user?.association?.Users?.length > 0) {
    hasBeneficialOwner = user?.association?.Users.filter(
      (userItem) => userItem.type === UserTypesEnum.BENEFICIAL_OWNER || userItem.percent_ownership
    ).length
    isReviewComplete =
      user?.association?.Users.filter((bo) => {
        if (!bo.id_synctera) return true
        if (!bo.percent_ownership) return bo.type === UserTypesEnum.BENEFICIAL_OWNER
      }).length === 0
  }

  const isBasicInfoComplete = !!user.association?.id
  const isAdminInfoComplete = !!user.id_synctera
  const isBeneficialOwnersComplete =
    hasBeneficialOwner > 0 || user?.association?.structure === LEGAL_STRUCTURES.NON_PROFIT
  const isSportInfoComplete = Array.isArray(user.association?.Sport_info)
    ? !!user.association?.Sport_info.length
    : !!user.association?.Sport_info
  const hasBankAccount = !!user.Banking_account?.length
  const hasExternalAccount = !!user.External_account?.length || !!user?.association?.is_complete
  const isCompleteAndFunded = !!user?.association?.is_complete
  const isKYCApproved =
    user?.kyc_status === verificationStatuses.ACCEPTED ||
    user?.kyc_status === verificationStatuses.PROVISIONAL ||
    user?.kyc_status === null

  const isFlowComplete =
    (isSubAdmin && isAdminInfoComplete && isCompleteAndFunded && isKYCApproved && hasBankAccount) ||
    (isBasicInfoComplete &&
      isAdminInfoComplete &&
      isBeneficialOwnersComplete &&
      isSportInfoComplete &&
      isReviewComplete &&
      hasBankAccount &&
      hasExternalAccount &&
      isCompleteAndFunded &&
      isKYCApproved &&
      !isSubAdmin)

  if (isFlowComplete)
    return {
      isComplete: true,
      isBasicInfoComplete,
      isAdminInfoComplete,
      isBeneficialOwnersComplete,
      isSportInfoComplete,
      isReviewComplete,
      hasBankAccount,
      hasExternalAccount,
      isCompleteAndFunded,
    }

  if (isBasicInfoComplete) redirectPage = EOrgInfo.adminInfo

  if (isAdminInfoComplete) redirectPage = EOrgInfo.beneficialOwners

  if (isBeneficialOwnersComplete) redirectPage = EOrgInfo.sportInfo

  if (isSportInfoComplete) redirectPage = EOrgInfo.Review

  if (hasBankAccount) redirectPage = EOrgInfo.fundAccount

  return {
    isComplete: false,
    isBasicInfoComplete,
    isAdminInfoComplete,
    isBeneficialOwnersComplete,
    isSportInfoComplete,
    isReviewComplete,
    hasBankAccount,
    hasExternalAccount,
    isCompleteAndFunded,
    redirectPage,
  }
}
