import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer'
import { BusinessPageWeb, InitialDepositCheck, MakeDepositCheck, Statements } from '@views/pages'
import React from 'react'
import {
  BUSINESS_ACCOUNT_ROUTE,
  INITIAL_DEPOSIT_CHECK_ROUTE,
  MAKE_DEPOSIT_CHECK_ROUTE,
  TRANSACTIONS_ROUTE,
  STATEMENTS_ROUTE,
} from '@assets/constants'
import { DetailsDrawerContent, LeftPanel } from '@views/components'
import { TransactionsWebPage } from '@views/pages/TransactionsPage'
import { documentTitle } from '@views/utils'

const Drawer = createDrawerNavigator()

const DrawerContent = ({ navigation }: DrawerContentComponentProps) => {
  const state = navigation.getState()
  return <LeftPanel content={<DetailsDrawerContent currentPageIdx={state.index} />} />
}

export const WebNavigator = () => {
  return (
    <Drawer.Navigator
      initialRouteName={BUSINESS_ACCOUNT_ROUTE}
      useLegacyImplementation
      screenOptions={{
        drawerStyle: { width: 270 },
        headerShown: false,
        drawerType: 'permanent',
      }}
      drawerContent={DrawerContent}>
      <Drawer.Screen
        name={BUSINESS_ACCOUNT_ROUTE}
        component={BusinessPageWeb}
        options={{ title: documentTitle('Account', 'Business Account') }}
      />
      <Drawer.Screen
        name={TRANSACTIONS_ROUTE}
        component={TransactionsWebPage}
        options={{ title: documentTitle('Account', 'Transactions') }}
      />
      <Drawer.Screen
        name={STATEMENTS_ROUTE}
        component={Statements}
        options={{ title: documentTitle('Account', 'Statements') }}
      />
      <Drawer.Screen
        name={MAKE_DEPOSIT_CHECK_ROUTE}
        component={MakeDepositCheck}
        options={{ title: documentTitle('Account', 'Make Deposit Check') }}
      />
    </Drawer.Navigator>
  )
}
