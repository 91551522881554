import { PermissionContainer } from '@views/components'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import BusinessAccountDrawer from './BusinessAccountDrawer'
import BusinessAccountInfo from './BusinessAccountInfo'

const BusinessAccountPage: React.FC = () => {
  return (
    <View style={styles.businessAccContainer}>
      <PermissionContainer>
        <BusinessAccountDrawer />
      </PermissionContainer>
      <BusinessAccountInfo />
    </View>
  )
}

const styles = StyleSheet.create({
  businessAccContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default BusinessAccountPage
