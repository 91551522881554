export function mapTransactionTypes(type: string | undefined) {
  switch (type) {
    case 'ach':
      return 'ACH'
    case 'internal_transfer':
      return 'Internal Transfer'
    case 'external_transfer':
      return 'External Transfer'
    case 'card':
      return 'Card'
    default:
      return type
  }
}
