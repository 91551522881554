import { BankingAccount } from './BankAccount'

interface RecipientName {
  first_name: string

  last_name: string

  middle_name?: string
}

interface Address {
  address_line_1: string

  address_line_2?: string

  city: string

  country_code: string

  postal_code: string

  state: string
}
interface Shipping {
  address?: Address

  care_of_line?: string

  is_expedited_fulfillment?: string

  method?: string

  phone_number?: string

  recipient_name?: RecipientName
}
export interface IssueCardBody {
  form: 'VIRTUAL' | 'PHYSICAL'

  account_id: string

  card_product_id: string

  type: 'DEBIT'

  shipping?: Shipping
}

interface CardProduct {
  form: string
  active: boolean
  card_program_id: string
  creation_time: string
  digital_wallet_tokenization: {
    provisioning_controls: {
      in_app_provisioning: {
        address_verification: {
          validate: boolean
        }
        enabled: boolean
      }
      manual_entry: {
        address_verification: {
          validate: boolean
        }
        enabled: boolean
      }
      wallet_provider_card_on_file: {
        address_verification: {
          validate: boolean
        }
        enabled: boolean
      }
    }
  }
  end_date: string
  id: string
  image: boolean
  issue_without_kyc: boolean
  last_modified_time: string
  name: string
  return_address: {}
  start_date: string
  txn_enhancer: string
}
export interface CardProductsResponse {
  card_products: Array<CardProduct>
}

export interface Card {
  id: string
  form: 'PHYSICAL' | 'VIRTUAL'
  card_product_id: string
  creation_time: string
  emboss_name: {
    line_1: string
    line_2: string
  }
  expiration_month: string
  expiration_time: string
  expiration_year: string
  is_pin_set: boolean
  last_four: string
  last_modified_time: string
  type: 'DEBIT' | 'CREDIT'
  shipping?: Shipping
  card_status: CardStatuses
  status_reason: string
  card_fulfillment_status?: 'ISSUED' | 'ORDERED' | 'REJECTED' | 'REORDERED' | 'SHIPPED'
  fulfillment_details?: {}
  bin: string
  card_brand: string
  physical_card_format?: string
}

export interface MemberCard extends Card {
  account_info: BankingAccount
}

export enum CardStatusReasonEnum {
  NEW = 'NEW',
  REQ = 'REQ',
  INA = 'INA',
  UNK = 'UNK',
  NEG = 'NEG',
  REV = 'REV',
  SUS = 'SUS',
  OUT = 'OUT',
  FRD = 'FRD',
  MAT = 'MAT',
  LOS = 'LOS',
  CLO = 'CLO',
  COM = 'COM',
  TMP = 'TMP',
  PRC = 'PRC',
  ISS = 'ISS',
  EXP = 'EXP',
  KYC = 'KYC',
  INF = 'INF',
  ACT = 'ACT',
  AUX = 'AUX',
  PIN = 'PIN',
  STO = 'STO',
  ADD = 'ADD',
  NAM = 'NAM',
  SSN = 'SSN',
  DOB = 'DOB',
  EML = 'EML',
  PHO = 'PHO',
  FUL = 'FUL',
  OTH = 'OTH',
}
export interface PatchCardDTO {
  customer_id?: string
  memo?: string
  card_status?: CardStatuses
  reason: CardStatusReasonEnum
}

export type CardStatuses = 'ACTIVE' | 'UNACTIVATED' | 'SUSPENDED' | 'TERMINATED'
