import { BusinessRepository } from '@domain/repositories/BusinessRepository'

export const businessService = (repository: BusinessRepository): BusinessRepository => ({
  getBusinessById: (id, basicInfo) => {
    return repository.getBusinessById(id, basicInfo)
  },
  getBasicInfo: (id: string) => {
    return repository.getBasicInfo(id)
  },
  patchBasicInfo: (id: string, basicInfo) => {
    return repository.patchBasicInfo(id, basicInfo)
  },
  createBusiness: (business) => {
    return repository.createBusiness(business)
  },
  createSportInfo: (payload, business_id) => {
    return repository.createSportInfo(payload, business_id)
  },
  postBusinessDocuments: (business_id?: string, payload?: any) => {
    return repository.postBusinessDocuments(business_id, payload)
  },
  createBusinessVerification: (business_id) => {
    return repository.createBusinessVerification(business_id)
  },
  createPersonVerification: (person_id) => {
    return repository.createPersonVerification(person_id)
  },
  getSportInfo: (business_id) => {
    return repository.getSportInfo(business_id)
  },
  patchSportInfo: (payload, business_id) => {
    return repository.patchSportInfo(payload, business_id)
  },
})
