import { COLORS, UserTypesEnum } from '@assets/constants'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { AppText, DropdownPopover, TransactionsFilterBar } from '@views/components'
import { TransactionsProvider } from '@views/contexts'
import { useAccount, useAuth } from '@views/hooks'
import { TransactionsFormValues } from '@views/schemas'
import { getRoleName } from '@views/utils'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import TransactionsList from './TransactionsList'

const TransactionsPage: React.FC = () => {
  const { user, logout } = useAuth()
  const { userAccount } = useAccount()
  const [showPendingTab, setShowPendingTab] = useState(false)
  const [filterParams, setFilterParams] = useState<TransactionsFormValues>({
    startDate: '',
    endDate: '',
    transactionType: '',
  })

  const [selectedAccountId, setSelectedAccountId] = useState(userAccount.id)
  const hasRole = !!user?.type

  return (
    <TransactionsProvider>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.optionContainer}>
            <AppText style={styles.title}>Transactions</AppText>
          </View>
          <View style={styles.teamMainInfo}>
            <View style={styles.teamMainInfoText}>
              <AppText style={styles.ownerName}>{user?.first_name}</AppText>
              {hasRole && <AppText style={styles.ownerRole}>{getRoleName(user?.type)}</AppText>}
            </View>
            <DropdownPopover
              from={
                <MaterialCommunityIcons name="chevron-down" size={20} color={COLORS.GRAY_700} />
              }>
              <TouchableOpacity style={styles.popoverContainer} onPress={() => logout()}>
                <Ionicons name="exit" size={20} color={COLORS.GRAY_700} />
                <AppText style={styles.logoutText}>Log out</AppText>
              </TouchableOpacity>
            </DropdownPopover>
          </View>
        </View>
        <View style={styles.tabsContainer}>
          <TouchableOpacity
            onPress={() => {
              setShowPendingTab(false)
            }}>
            <View style={showPendingTab ? styles.tabContainer : styles.tabContainerSelected}>
              <AppText
                style={[styles.tab, { color: !showPendingTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
                Transaction history
              </AppText>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setShowPendingTab(true)
            }}>
            <View style={showPendingTab ? styles.tabContainerSelected : styles.tabContainer}>
              <AppText
                style={[styles.tab, { color: showPendingTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
                Pending transactions
              </AppText>
            </View>
          </TouchableOpacity>
        </View>
        <TransactionsFilterBar
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          pendingOrPosted={showPendingTab ? 'pending' : 'posted'}
          bankAccountId={selectedAccountId}
          setBankAccountId={setSelectedAccountId}
        />
        <TransactionsList
          pendingOrPosted={showPendingTab ? 'pending' : 'posted'}
          fromDate={filterParams.startDate}
          toDate={filterParams.endDate}
          type={filterParams.transactionType}
          bankAccountId={selectedAccountId}
        />
      </View>
    </TransactionsProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flexGrow: 1,
    padding: 30,
    backgroundColor: COLORS.WHITE,
  },
  headerContainer: {
    paddingBottom: 28,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionsContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 24,
  },

  tabsContainer: {
    paddingVertical: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  tabContainer: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
  },
  tabContainerSelected: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.PRIMARY,
  },
  tab: {
    fontSize: 16,
    fontWeight: '600',
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: COLORS.DARK,
    fontStyle: 'normal',
  },
  infoIcon: {
    marginLeft: 10,
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  teamMainInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  teamMainInfoText: {
    flexDirection: 'column',
  },
  ownerName: {
    fontSize: 14,
    fontWeight: '600',
  },
  ownerRole: { fontSize: 12 },
  popoverContainer: {
    backgroundColor: '#FFF',
    width: 120,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logoutText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
})

export default TransactionsPage
