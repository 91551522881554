export const ssnRegex = /^\d{9}$/
export const einRegex = /^\d{2}\-?\d{7}$/
export const zipRegex = /^\d{5}$/
export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
export const noSelectionRegex = /[^-]/
export const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
export const hasUpperCaseRegex = /[A-Z]/
export const hasLowerCaseRegex = /[a-z]/
export const hasNumberRegex = /\d/
export const depositAmountRegex = /^[$]?([0-9]{1,2})?,?([0-9]{3})?,?([0-9]{3})?(\.[0-9]{2})?[$]?$/
export const percentageRegex = /^([1-9][0-9]?|100)%?$/
export const onlyNumberRegex = /^\d+$/
export const notNumRegex = /^[0-9]+$/
export const stringRegex = /^[a-zA-Z ]*$/
export const alphaNumericRegex = /^[a-zA-Z0-9 ]*$/
export const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/
export const dateRegex = /((0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/[12]\d{3})/
export const moneyRegex = /^\$?\d+(,\d{3})*(\.\d*)?$/
export const currencyIntRegex = /^\$?(0|[1-9][0-9]{0,2})(,?\d{3})*$/
