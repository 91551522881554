export const createFileUploadOptions = (description: string, uri?: string, file?: any) => {
  const formData = new FormData()
  formData.append('description', description)
  if (uri) {
    const [info] = uri.split(',')
    const match = /:([^;]*)/.exec(info)
    const type = match ? `${match[1]}` : `image`
    const extension = type.split('/').pop()
    formData.append('mimetype', type)
    if (extension) formData.append('extension', extension)
    formData.append('file', uri)
  }

  // @ts-ignore
  if (file) {
    const extension = file.type.split('/').pop()
    formData.append('mimetype', file.type)
    formData.append('extension', extension)
    formData.append('file', file)
  }
  return {
    body: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  }
}
