import React, { FC } from 'react'
import { StyleSheet, TextInput, TextInputProps } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { COLORS, FONT_TYPES } from '@assets/constants'

export enum InputTypes {
  password = 'password',
  text = 'text',
  picker = 'picker',
  hidden = 'hidden',
  slider = 'slider',
  radioButton = 'radioButton',
  date = 'datetime',
  phone = 'phone',
  ssn = 'ssn',
  money = 'money',
}

interface IInputProps extends TextInputProps {
  onChange?: any
  onBlur?: any
  value: any
  type: InputTypes
  placeholder?: string
  onChangeText?: (text: string) => void
}

const getMaskOptionsByType = (type: InputTypes) => {
  if (type === InputTypes.date) return { format: 'MM/DD/YYYY' }
  if (type === InputTypes.phone) return { mask: '(999) 999-9999' }
  if (type === InputTypes.ssn) return { mask: '999999999' }
  if (type === InputTypes.money) return { precision: 2, separator: '.', delimiter: ',', unit: '$' }
}

const getMaskType = (type: InputTypes) => {
  if (type === InputTypes.date) return 'datetime'
  if (type === InputTypes.money) return 'money'
  return 'custom'
}

export const RNInput: FC<IInputProps> = ({
  type,
  onChange,
  onBlur,
  value,
  onChangeText = null,
  placeholder = '',
  editable,
  ...rest
}) => {
  if (type === InputTypes.hidden) return null
  const isDisabled = !editable

  const isSSN = type === InputTypes.ssn
  const isDate = type === InputTypes.date
  const isPhone = type === InputTypes.phone
  const isMoney = type === InputTypes.money

  const shouldAddMask = isDate || isPhone || isMoney || isSSN
  if (shouldAddMask)
    return (
      <TextInputMask
        type={getMaskType(type)}
        options={getMaskOptionsByType(type)}
        style={[styles.input, isDisabled && styles.disabled]}
        onBlur={onBlur}
        onChangeText={(val) => {
          onChange?.(val)
          if (onChangeText) onChangeText(val)
        }}
        value={value}
        secureTextEntry={isSSN}
        placeholder={placeholder}
        placeholderTextColor={COLORS.GRAY_700}
        editable={editable}
        {...rest}
      />
    )

  return (
    <TextInput
      style={[styles.input, isDisabled && styles.disabled]}
      onBlur={onBlur}
      onChangeText={(val) => {
        onChange?.(val)
        if (onChangeText) onChangeText(val)
      }}
      value={value}
      secureTextEntry={type === InputTypes.password}
      placeholder={placeholder}
      placeholderTextColor={COLORS.GRAY_700}
      editable={editable}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  disabled: { backgroundColor: COLORS.BACKGROUND, color: COLORS.DARK },
  input: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: COLORS.GRAY_200,
    borderRadius: 4,
    height: 50,
    fontFamily: FONT_TYPES.FONT_REGULAR,
  },
})
