import React, { useEffect, useState } from 'react'
import { GestureResponderEvent, Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { AppText } from '@views/components'
import { manipulateAsync } from 'expo-image-manipulator'
import ImgPlaceholder from '@assets/images/img_placeholder.png'

export type ImageContentProps = {
  imgSrc: string
  title: string
  onPress: ((event: GestureResponderEvent) => void) | undefined
}
export const ImageContent: React.FC<ImageContentProps> = ({ imgSrc, title, onPress }) => {
  const [image, setImage] = useState(imgSrc)

  const rotateImage = async () => {
    const { uri } = await manipulateAsync(imgSrc, [{ rotate: -90 }])
    setImage(uri)
  }

  useEffect(() => {
    if (ImgPlaceholder !== imgSrc) {
      rotateImage()
    }
  }, [imgSrc])

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.imgTitle}>
        <Image source={{ uri: image }} style={styles.image} />
        <AppText>{title}</AppText>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  image: {
    width: 130,
    height: '100%',
  },
  imgTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
})
