import { COLORS } from '@assets/constants'
import downloadImg from '@assets/images/download.png'
import { AppText } from '@views/components'
import { useTransactions } from '@views/hooks/useTransactions'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Image, StyleSheet, View, ScrollView } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { currencyFormatter, formatDate } from '@views/utils'
import moment from 'moment'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { mapTransactionTypes } from '@assets/constants/trasactionTypes'
import { mapSubtypes } from '@views/helpers/mapSubTypes'

interface TransactionsListProps {
  pendingOrPosted: 'pending' | 'posted'
  fromDate: string
  toDate: string
  type: string
  bankAccountId: string
}
const TransactionsList = ({
  pendingOrPosted,
  fromDate,
  toDate,
  type,
  bankAccountId,
}: TransactionsListProps) => {
  const {
    getTransactions,
    transactionsState: { transactions, loading },
  } = useTransactions()

  const [displayDetails, setDisplayDetails] = useState(false)
  useEffect(() => {
    const newFromDate = moment(new Date(fromDate))
    const newToDate = moment(new Date(toDate))

    getTransactions({
      pendingOrPosted,
      fromDate: newFromDate.isValid() ? newFromDate.format('YYYY-MM-DD') : '',
      toDate: newToDate.isValid() ? newToDate.format('YYYY-MM-DD') : '',
      type,
      bankAccountId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTransactions, pendingOrPosted])

  if (loading) return <ActivityIndicator />

  return (
    <ScrollView contentContainerStyle={styles.clubTeamInfocontainer} style={styles.scrollViewStyle}>
      {transactions?.result?.length > 0
        ? transactions?.result?.map((item) => (
            <View
              style={[styles.clubInfoRow, styles.shadowProp]}
              key={`${item.id}-${item.created}`}>
              <View style={styles.downloadRow}>
                <Image source={downloadImg} style={styles.downloadIcon} />
              </View>
              <View style={styles.infoRow}>
                <AppText style={styles.titleFont}>Recipient</AppText>
                <AppText style={styles.initiatedByFont}>
                  {item?.data?.user_data?.recipient_name || ''}
                </AppText>
              </View>
              <View style={styles.divider} />
              <View style={styles.infoRow}>
                <AppText style={styles.titleFont}>
                  {pendingOrPosted === 'pending' ? 'Initiated' : 'Posted'} date
                </AppText>
                <AppText>{formatDate(item.posted_date || item.created, 'MM/DD/YY')}</AppText>
              </View>
              <View style={styles.divider} />
              <View style={styles.infoRow}>
                <AppText style={styles.titleFont}>Transaction type</AppText>
                <View style={styles.typeWrapper}>
                  <AppText>{mapTransactionTypes(item?.data?.type || item?.type)}</AppText>
                </View>
              </View>
              <View style={styles.divider} />
              <View style={styles.infoRow}>
                <AppText style={styles.titleFont}>Initated by</AppText>
                <AppText style={styles.initiatedByFont}>
                  {item?.data?.user_data?.user_name_synctera || ''}
                </AppText>
              </View>
              <View style={styles.divider} />
              <View style={styles.infoRow}>
                <AppText style={styles.titleFont}>Amount</AppText>
                <AppText>
                  {item.data.dc_sign === 'debit' && `- `}
                  {item?.data?.amount
                    ? currencyFormatter(item?.data?.amount / 100)
                    : item?.data?.lines?.[0]?.amount
                    ? currencyFormatter(item?.data?.lines?.[0]?.amount / 100)
                    : null}
                </AppText>
              </View>
              <View style={styles.divider} />
              {!displayDetails && (
                <TouchableOpacity onPress={() => setDisplayDetails(true)}>
                  <View style={styles.viewDetailsRow}>
                    <AppText style={styles.viewDetailsFont}>View Details</AppText>
                    <FontAwesome5 name="caret-down" size={19} color={COLORS.DARK} />
                  </View>
                </TouchableOpacity>
              )}
              {displayDetails && (
                <>
                  <View style={styles.infoRow}>
                    <AppText style={styles.titleFont}>Sub-type</AppText>
                    <AppText>{mapSubtypes(item.subtype || item.data?.subtype)}</AppText>
                  </View>
                  <View style={styles.divider} />
                  <View style={styles.infoRow}>
                    <AppText style={styles.titleFont}>Status</AppText>
                    <AppText style={styles.statusFont}>{item?.status}</AppText>
                  </View>
                  <View style={styles.divider} />
                  <View style={styles.infoRow}>
                    <AppText style={styles.titleFont}>Comments</AppText>
                    <AppText>{item?.data?.memo}</AppText>
                  </View>

                  <View style={styles.divider} />
                  <TouchableOpacity onPress={() => setDisplayDetails(false)}>
                    <View style={styles.viewDetailsRow}>
                      <AppText style={styles.viewDetailsFont}>Hide Details</AppText>
                      <FontAwesome5 name="caret-up" size={19} color={COLORS.DARK} />
                    </View>
                  </TouchableOpacity>
                </>
              )}
            </View>
          ))
        : null}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.LIGHT,
    paddingHorizontal: 30,
  },
  scrollViewStyle: {
    marginHorizontal: -30,
  },
  clubInfoHeader: {
    paddingTop: 10,
    backgroundColor: COLORS.WHITE,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clubInfoRow: {
    marginTop: 16,
    padding: 24,
    minHeight: 330,
  },
  downloadRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 26,
  },
  statusFont: {
    textTransform: 'capitalize',
  },
  divider: {
    backgroundColor: COLORS.GRAY_300,
    height: 1,
    width: '100%',
    marginVertical: 13,
  },
  downloadIcon: { width: 20, height: 20, resizeMode: 'contain' },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 21,
    width: '100%',
  },
  typeWrapper: {
    paddingHorizontal: 8,
    height: 29,
    backgroundColor: COLORS.AZURE_LIGHT,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleFont: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    color: COLORS.DARK,
  },
  initiatedByFont: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    color: COLORS.DARK,
  },
  viewDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    width: '100%',
  },
  viewDetailsFont: {
    marginRight: 10,
  },
  shadowProp: {
    borderRadius: 8,
    shadowColor: COLORS.SHADOW_COLOR,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 24,

    elevation: 10,
  },
})

export default TransactionsList
