import * as yup from 'yup'
import { months } from '@assets/constants'
import { IInputProps, InputTypes } from '@views/components'

export const filterStatementsFields: IFilterStatementFields = {
  statementMonth: {
    name: 'statementMonth',
    type: InputTypes.picker,
    placeholder: 'Select month',
    items: months,
  },
  statementYear: {
    name: 'statementYear',
    type: InputTypes.picker,
    placeholder: 'Select year',
    items: [],
  },
}

export interface IFilterStatementFields {
  statementMonth: IInputProps
  statementYear: IInputProps
}

export type statementsFormValues = {
  statementMonth: string
  statementYear: string
}

export const statementsFilterSchema = yup.object().shape({
  statementMonth: yup.string(),
  statementYear: yup.string(),
})
