import React from 'react'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { openModal } from '@views/components'
import { createContext, ReactNode, useState } from 'react'
import { getErrorMessage } from '@views/helpers'
import { BankingAccount, Deposit } from '@domain/models'
import { useAccount, useAuth } from '@views/hooks'
import { UnauthenticatedError } from '@microservices/errors/http'

interface IDepositsContext {
  deposits: Deposit[]
  initiatedBy: BankingAccount
  getDeposits: (account_id: string) => Promise<void>
  loadingDeposits: boolean
}

interface Props {
  children: ReactNode
}

export const DepositsContext = createContext<IDepositsContext>({} as IDepositsContext)

const service = bankingService(bankingRepo)

export function DepositsContextProvider({ children }: Props) {
  const { logout } = useAuth()
  const { orgAccount, subAccounts, userAccount } = useAccount()
  const [loadingDeposits, setLoadingDeposits] = useState(false)
  const [deposits, setDeposits] = useState<Deposit[]>([])
  const [initiatedBy, setInitiatedBy] = useState<BankingAccount>(userAccount)

  const getDeposits = async (account_id: string) => {
    try {
      setLoadingDeposits(true)
      const initiatedName = [orgAccount, ...subAccounts].find(
        (acc) => acc.id === account_id
      ) as BankingAccount
      setInitiatedBy(initiatedName)
      const response = await service.getDeposits(account_id)
      if (!response) throw new Error('Something went wrong')
      setDeposits(response.deposits)
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openModal({
        type: 'warning',
        title: 'Error',
        dismiss: false,
        subtitle: getErrorMessage(error),
        confirmationButtonMessage: 'Continue',
        contentStyle: { width: '80%', maxWidth: 400 },
      })
    } finally {
      setLoadingDeposits(false)
    }
  }

  return (
    <DepositsContext.Provider
      value={{
        deposits,
        initiatedBy,
        getDeposits,
        loadingDeposits,
      }}>
      {children}
    </DepositsContext.Provider>
  )
}
