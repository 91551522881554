import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { useFieldArray, useForm } from 'react-hook-form'
import moment from 'moment'
import Checkbox from 'expo-checkbox'
import { TouchableOpacity } from 'react-native-gesture-handler'

import {
  AppText,
  BOForm,
  Button,
  ButtonRow,
  closeModal,
  ErrorMessage,
  Form,
  openModal,
  Row,
} from '@views/components'
import {
  beneficialOwnerFields,
  beneficialOwnerInviteFields,
  IAdminInfo,
  IBeneficialOwnerInviteFields,
  IBeneficialOwnersInfoFields,
} from '@views/schemas'

import plus from '@assets/images/plus.png'
import { COLORS, LEGAL_STRUCTURES, UserTypesEnum, phoneRegex } from '@assets/constants'
import { useAuth, useCreateAccount, useSystemConfig } from '@views/hooks'
import { checkBOFormData, formatBOFormData, formatPhone } from '@views/utils'
import Header from '../Header'
import BeneficialOwnersEmail from '@views/pages/CreateAccount/BeneficialOwners/BeneficialOwnersEmail'

type TProps = {
  onSubmit: () => any
  onBack: () => any
}

interface IBOState {
  type: 'info' | 'invite'
  form: IBeneficialOwnersInfoFields | IBeneficialOwnerInviteFields
  prefilled: boolean
  value?: any
}

const BeneficialOwnersForm: React.FC<TProps> = ({ onSubmit, onBack }) => {
  const [isNonProfit, setIsNonProfit] = useState<boolean>(false)
  const [hasSubmittedEmails, setHasSubmittedEmails] = useState<boolean>(false)
  const [useAdminInfo, setUseAdminInfo] = useState<IAdminInfo | undefined>(undefined)
  const [submittedAll, setSubmittedAll] = useState<boolean>(false)
  const [beneficialOwnerForms, setBeneficialOwnerForms] = useState<IBOState[]>([])
  const [checked, setChecked] = useState<boolean>(false)
  const [numFormsAdded, setNumFormsAdded] = useState<number>(1)
  const [totalPercent, setTotalPercent] = useState<number>(0)
  const [persistedEmails, setPersistedEmails] = useState<string[]>([])

  const { createAccountState, postOwnerInfo, patchOwnerInfo, postOwnerEmails, getAdminInfo } =
    useCreateAccount()

  const { loading, error, data } = createAccountState
  const { adminInfo } = data

  const { user, authFlowInfo } = useAuth()

  const { isSmallDevice } = useSystemConfig()

  const isPersisted = authFlowInfo?.isBeneficialOwnersComplete
  const hasBankAccount = authFlowInfo?.hasBankAccount

  useEffect(() => {
    setIsNonProfit(user?.association?.structure === LEGAL_STRUCTURES.NON_PROFIT)
    if (adminInfo) setUseAdminInfo(adminInfo)
    if (isPersisted || hasBankAccount) getOwnerInfo()
    else addBeneficialOwnerForm('info')
  }, [])

  useEffect(() => {
    const getInfo = async () => await getAdminInfo()
    
    if (checked && !useAdminInfo)
      getInfo().then((info) => {
        setUseAdminInfo(info)
        isOwnerClick(info)
      })
  }, [checked])

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'BOForms',
  })

  useEffect(() => {
    beneficialOwnerForms.forEach((item) => {
      if (item.value) setValue(item.form.email.name, item.value)
    })
  }, [beneficialOwnerForms])

  const getOwnerInfo = () => {
    const ownerInfo = user?.association?.Users.filter(
      (BOUser) =>
        BOUser.type === UserTypesEnum.BENEFICIAL_OWNER ||
        (BOUser.type === UserTypesEnum.ORG_ADMIN && !!BOUser.percent_ownership)
    ).map((BOUser) => {
      if (BOUser.percent_ownership) setTotalPercent(totalPercent + BOUser.percent_ownership)
      return BOUser.email
    })
    if (ownerInfo && ownerInfo.length > 0) {
      const BOForms: IBOState[] = []
      const BOFields = []
      for (let i = 0; i < ownerInfo.length; i++) {
        let payload: IBOState
        let newForm
        if (ownerInfo[i] === user?.email) {
          newForm = beneficialOwnerFields(i + 1)
          payload = {
            type: 'info',
            form: newForm,
            prefilled: false,
          }
          BOFields.push({ [newForm.firstName.name]: '' })
          BOFields.push({ [newForm.lastName.name]: '' })
          BOFields.push({ [newForm.phone.name]: '' })
          BOFields.push({ [newForm.email.name]: '' })
          BOFields.push({ [newForm.percentageOwnership.name]: '' })
          BOFields.push({ [newForm.ssn.name]: '' })
          BOFields.push({ [newForm.dob.name]: '' })
          BOFields.push({ [newForm.addrStreet1.name]: '' })
          BOFields.push({ [newForm.addrStreet2.name]: '' })
          BOFields.push({ [newForm.addrCity.name]: '' })
          BOFields.push({ [newForm.addrState.name]: '' })
          BOFields.push({ [newForm.addrZip.name]: '' })
          setChecked(true)
        } else {
          newForm = beneficialOwnerInviteFields(i + 1)
          payload = {
            type: 'invite',
            form: newForm,
            prefilled: true,
            value: ownerInfo[i],
          }
          BOFields.push({ [newForm.email.name]: ownerInfo[i] })
        }

        BOForms.push(payload)
      }
      setNumFormsAdded(ownerInfo.length)
      setBeneficialOwnerForms(BOForms)
      setPersistedEmails(ownerInfo)
      append(BOFields)
    }
  }

  const closeAndSubmit = () => {
    closeModal()
    onSubmit()
  }

  const submitForm = async (info: any) => {
    if (hasBankAccount) {
      onSubmit()
      return
    }
    if (checkBOFormData(info, setError, totalPercent, numFormsAdded))
      for (let i = 1; i <= numFormsAdded; i++) {
        if (!info[`emailBO${i}`] || !!persistedEmails.find((item) => item === info[`emailBO${i}`]))
          continue
        if (checked && i === 1 && info.percentageOwnershipBO1 !== user?.percent_ownership) {
          await patchOwnerInfo({
            percent_ownership: parseInt(info.percentageOwnershipBO1.replace(/\D/g, ''), 10),
          })
          continue
        }

        const formattedUser = formatBOFormData(info, i)
        if (!formattedUser.first_name) {
          await postOwnerEmails({
            email: formattedUser.email,
            type: UserTypesEnum.BENEFICIAL_OWNER,
          })
          setHasSubmittedEmails(true)
        } else await postOwnerInfo(formattedUser)
        setPersistedEmails([...persistedEmails, formattedUser.email])
      }
    setSubmittedAll(true)
  }

  useEffect(() => {
    if (isSubmitSuccessful && submittedAll && error === '') {
      if (hasSubmittedEmails)
        openModal({
          dismiss: false,
          type: 'success',
          title: 'Your invitation email(s) has been sent',
          onDismiss: () => closeAndSubmit(),
          buttons: [
            <Button
              key={'BOInfo_success_modal'}
              style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
              type="primary"
              text="Continue"
              onPress={closeAndSubmit}
            />,
          ],
        })
      else onSubmit()
      reset({}, { keepValues: true })
    }
    return
  }, [isSubmitSuccessful, submittedAll, error, onSubmit])

  const findAvailableIndex = (): number => {
    for (let i = 1; i <= 4; i++) if (getValues(`emailBO${i}`) === undefined) return i

    return 0
  }

  const appendFieldsToForm = (
    type: 'invite' | 'info',
    BONum?: number,
    defaultValue?: string
  ): IBeneficialOwnerInviteFields | IBeneficialOwnersInfoFields => {
    const formNum = BONum || findAvailableIndex()
    const newForm =
      type === 'invite' ? beneficialOwnerInviteFields(formNum) : beneficialOwnerFields(formNum)

    const newFormMap = Object.values(newForm).map((field) => ({ [field.name]: defaultValue ?? '' }))
    append(newFormMap)
    return newForm
  }

  const addBeneficialOwnerForm = (
    type: 'invite' | 'info',
    defaultValue?: string,
    prefilled?: boolean,
    BONum?: number
  ) => {
    const newForm = appendFieldsToForm(type, BONum, defaultValue)
    const payload = { type, form: newForm, prefilled: prefilled ?? false }
    if (errors.BOForms?.length) clearErrors('BOForms')

    setBeneficialOwnerForms((prevState) => [...prevState, payload])
    if (numFormsAdded < 4) setNumFormsAdded(numFormsAdded + 1)
  }

  const removeFieldsFromForm = (idx: number) => {
    if (!beneficialOwnerForms[idx]) return
    const formId = beneficialOwnerForms[idx].form.email.name.slice(-3)
    const pattern = new RegExp(formId, 'gi')
    const formIndexes: number[] = []
    fields.forEach((field, fieldIdx) => {
      let matched = false
      Object.keys(field).forEach((key) => {
        if (pattern.test(key)) matched = true
      })
      if (matched) formIndexes.push(fieldIdx)
    })
    remove(formIndexes)
  }

  const removeBeneficialOwnerForm = (idx: number) => {
    removeFieldsFromForm(idx)
    setBeneficialOwnerForms((prevState) => {
      prevState.splice(idx, 1)
      return [...prevState]
    })
    if (errors.BOForms?.length) clearErrors('BOForms')
  }

  const changeFormType = (idx: number) => {
    removeFieldsFromForm(idx)
    const prevForm = beneficialOwnerForms[idx]
    const formNum = parseInt(prevForm.form.email.name.slice(-1), 10)
    const newType: 'info' | 'invite' = prevForm.type === 'invite' ? 'info' : 'invite'
    const newForm = appendFieldsToForm(newType, formNum)
    const payload = { type: newType, form: newForm, prefilled: false }
    setBeneficialOwnerForms((prevState) => {
      prevState[idx] = payload
      return [...prevState]
    })
  }

  const isOwnerClick = (BEInfo?: IAdminInfo) => {
    if ((!checked && useAdminInfo) || BEInfo !== undefined) {
      const infoValues = useAdminInfo !== undefined ? useAdminInfo : BEInfo
      const phone = infoValues?.phone.match(phoneRegex)
        ? infoValues.phone
        : infoValues?.phone
        ? formatPhone(infoValues.phone)
        : ''
      setValue('firstNameBO1', infoValues?.first_name)
      setValue('lastNameBO1', infoValues?.last_name)
      setValue('phoneBO1', phone)
      setValue('emailBO1', infoValues?.email)
      setValue('percentageOwnershipBO1', infoValues?.percent_ownership)
      setValue('ssnBO1', infoValues?.ssn.padStart(9, '0'))
      setValue('dobBO1', moment(infoValues?.birthday).format('MM/DD/YYYY'))
      setValue('addrStreet1BO1', infoValues?.address_line_1)
      setValue('addrStreet2BO1', infoValues?.address_line_2)
      setValue('addrCityBO1', infoValues?.city)
      setValue('addrStateBO1', infoValues?.state)
      setValue('addrZipBO1', infoValues?.zip_code)
    } else {
      setValue('firstNameBO1', '')
      setValue('lastNameBO1', '')
      setValue('phoneBO1', '')
      setValue('emailBO1', '')
      setValue('percentageOwnershipBO1', '')
      setValue('ssnBO1', '')
      setValue('dobBO1', '')
      setValue('addrStreet1BO1', '')
      setValue('addrStreet2BO1', '')
      setValue('addrCityBO1', '')
      setValue('addrStateBO1', '')
      setValue('addrZipBO1', '')
    }
    if (BEInfo === undefined) {
      setChecked(!checked)
      if (beneficialOwnerForms[0].type === 'invite') changeFormType(0)
    }
  }
  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <Header subroute="Beneficial owners info" />
        <AppText style={styles.subTitle2}>
          We need to collect information about each beneficial owner or administrative authority
          (for non-profits). Don't know your beneficial owners' or administrative authorities'
          information? We can send them an email so they can add their own information.
        </AppText>
        <Row style={styles.checkBoxWrapper}>
          <Checkbox
            value={checked}
            onValueChange={() => isOwnerClick(undefined)}
            style={styles.checkBox}
            color={!checked ? COLORS.BACKGROUND : COLORS.LIGHT_GREEN}
            disabled={isPersisted || hasBankAccount}
          />
          <AppText>
            I am {isNonProfit ? 'an administrative authority' : 'a beneficial owner'}
          </AppText>
        </Row>
        <Form showDisclaimer>
          {beneficialOwnerForms.map((item, idx) => {
            if (item.type === 'invite')
              return (
                <BeneficialOwnersEmail
                  form={item.form}
                  control={control}
                  errors={errors}
                  formIdx={idx}
                  key={`BOForm-key-${idx}`}
                  checked={checked && idx === 0}
                  removeForm={!item.prefilled ? removeBeneficialOwnerForm : undefined}
                  changeFormType={!item.prefilled ? changeFormType : undefined}
                  disabled={item.prefilled || hasBankAccount}
                />
              )
            return (
              <BOForm
                // @ts-ignore
                form={item.form}
                type={item.type}
                control={control}
                errors={errors}
                removeForm={removeBeneficialOwnerForm}
                formIdx={idx}
                key={`BOForm-key-${idx}`}
                checked={checked && idx === 0}
                changeFormType={changeFormType}
                hasBankAccount={hasBankAccount}
              />
            )
          })}
          {beneficialOwnerForms.length <= 3 && !isNonProfit && !hasBankAccount && (
            <View style={styles.addOwnerBtn}>
              <TouchableOpacity onPress={() => addBeneficialOwnerForm('info')}>
                <Row>
                  <Image source={plus} style={styles.plusIcon} />
                  <AppText style={styles.addOwnerText}>
                    Add Another {isNonProfit ? 'administrative authority' : 'beneficial owner'}
                  </AppText>
                </Row>
              </TouchableOpacity>
            </View>
          )}
        </Form>
      </View>
      {error !== '' && <ErrorMessage error={error} />}
      <ButtonRow
        loading={loading || isSubmitting}
        buttonLabel={loading || isSubmitting ? 'Loading...' : 'Next'}
        onSubmit={(e: SyntheticEvent) => {
          handleSubmit(submitForm)(e).catch((err) => {
            console.error('hs', { err, isSubmitSuccessful })
          })
        }}
        onBack={onBack}
      />
    </ScrollView>
  )
}

export default BeneficialOwnersForm

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  subTitle2: {
    fontSize: 16,
    marginTop: 32,
    maxWidth: 850,
  },
  plusIcon: {
    height: 24,
    width: 24,
  },
  addOwnerText: {
    marginTop: 4,
  },
  addOwnerBtn: {
    marginBottom: 50,
    width: 250,
  },
  checkBoxWrapper: {
    marginTop: 35,
  },
  checkBox: {
    marginRight: 10,
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
