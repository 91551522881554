import { AuthenticateUserData, UserRepository } from '@domain/repositories/UserRepository'

export const userService = (repository: UserRepository): UserRepository => ({
  authenticateUser: (data: AuthenticateUserData) => {
    return repository.authenticateUser(data)
  },
  createAdminWithDisclosures: (user, business_id) => {
    return repository.createAdminWithDisclosures(user, business_id)
  },
  getAdminInfo: (user_id) => {
    return repository.getAdminInfo(user_id)
  },
  patchAdminInfo: (user_id, adminInfo) => {
    return repository.patchAdminInfo(user_id, adminInfo)
  },
  createBeneficialOwners: (owners, businessId) => {
    return repository.createBeneficialOwners(owners, businessId)
  },
  getBeneficialOwners: (businessId) => {
    return repository.getBeneficialOwners(businessId)
  },
  updateBeneficialOwner: (user_id, ownerInfo) => {
    return repository.updateBeneficialOwner(user_id, ownerInfo)
  },
  postPersonalDocuments: (user_id: string | undefined, payload: any) => {
    return repository.postPersonalDocuments(user_id, payload)
  },
  postBeneficialOwnersEmails: (data, businessId) => {
    return repository.postBeneficialOwnersEmails(data, businessId)
  },
  sendSubAccountEmail: (data) => {
    return repository.sendSubAccountEmail(data)
  },
  createSubAccountAdmin: (data, business_id) => {
    return repository.createSubAccountAdmin(data, business_id)
  },
})
