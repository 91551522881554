import React, { useState, useEffect } from 'react'
import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'

import {
  beneficialOwnersFromForEmail,
  CreateAccountPage,
  createAccountPages,
} from '@assets/constants/createAccountNav'
import { CreateAccountParamList } from '@views/navigation/types'
import { useAuth, useSystemConfig } from '@views/hooks'
import { LeftPanel, CreateAccountContent, TopTabBar } from '@views/components'
import { CreateAccountProvider } from '@views/contexts'

import { LEGAL_STRUCTURES, UserTypesEnum } from '@assets/constants'
import { View } from 'react-native'

const Drawer = createDrawerNavigator<CreateAccountParamList>()
const Tab = createMaterialTopTabNavigator<CreateAccountParamList>()

interface IDrawerContent extends DrawerContentComponentProps {
  isVisible: boolean
  isNonProfit: boolean
  hasBankAccount: boolean
  checkComplete: (route: string) => boolean
}

const DrawerContent = ({
  navigation,
  isVisible = true,
  isNonProfit = false,
  checkComplete,
  hasBankAccount,
}: IDrawerContent) => {
  const state = navigation.getState()
  return (
    <LeftPanel
      content={
        <CreateAccountContent
          currentPageIdx={state.index}
          isVisible={isVisible}
          isNonProfit={isNonProfit}
          checkComplete={checkComplete}
          hasBankAccount={hasBankAccount}
        />
      }
    />
  )
}

export default function CreateAccount() {
  const [nav, setNav] = useState<CreateAccountPage[]>(createAccountPages)
  const [isNonProfit, setIsNonProfit] = useState(false)

  const { authFlowInfo, user } = useAuth()
  const { isSmallDevice } = useSystemConfig()

  const checkComplete = (route: string): boolean => {
    if (authFlowInfo) {
      const pattern = new RegExp(route, 'gi')
      const key = Object.keys(authFlowInfo).find((keyName) => pattern.test(keyName))
      // @ts-ignore
      if (key) return authFlowInfo[key]
    }
    return false
  }

  const isBeneficialOwner = user?.type === UserTypesEnum.BENEFICIAL_OWNER
  const hasBankAccount = authFlowInfo?.hasBankAccount

  const redirect = !isBeneficialOwner
    ? authFlowInfo?.redirectPage || 'basicInfo'
    : 'beneficialOwnersFromEmail'

  useEffect(() => {
    if (isBeneficialOwner) {
      setNav([beneficialOwnersFromForEmail])
      return
    }
    if (user?.association?.structure === LEGAL_STRUCTURES.NON_PROFIT) {
      setIsNonProfit(true)
      const updatedNav = nav.filter((page) => page.name !== 'beneficialOwners')

      setNav(updatedNav)
      return
    }
    setNav(createAccountPages)
  }, [user?.association?.structure])

  if (!nav.length) return <View />

  const renderMobileNavigator = (
    <Tab.Navigator
      initialRouteName={redirect}
      screenOptions={{
        swipeEnabled: false,
      }}
      style={{ maxHeight: '110vh' }}
      tabBar={(props) => (
        <TopTabBar
          {...props}
          isVisible={!isBeneficialOwner}
          isNonProfit={isNonProfit}
          checkComplete={checkComplete}
          hasBankAccount={!!hasBankAccount}
        />
      )}>
      {nav.map((screen) => (
        <Tab.Screen
          key={screen.name}
          name={screen.name as any}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </Tab.Navigator>
  )

  const renderDesktopNavigator = (
    <Drawer.Navigator
      initialRouteName={redirect}
      useLegacyImplementation
      screenOptions={{
        headerShown: false,
        drawerType: 'permanent',
      }}
      drawerContent={(props) => (
        <DrawerContent
          {...props}
          isVisible={!isBeneficialOwner}
          isNonProfit={isNonProfit}
          checkComplete={checkComplete}
          hasBankAccount={!!hasBankAccount}
        />
      )}>
      {nav.map((screen) => (
        <Drawer.Screen
          key={screen.name}
          name={screen.name as any}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </Drawer.Navigator>
  )

  return (
    <CreateAccountProvider>
      {isSmallDevice ? renderMobileNavigator : renderDesktopNavigator}
    </CreateAccountProvider>
  )
}
