import * as yup from 'yup'
import { transactionTypes } from '@assets/constants'
import { IInputProps } from '@views/components/Form'
import { InputTypes } from '@views/components/Input'

export const filterInfoFields: IFilterInfoFields = {
  startDate: {
    name: 'startDate',
    label: 'Start date',
    type: InputTypes.date,
    placeholder: 'MM/DD/YYYY',
  },
  endDate: {
    name: 'endDate',
    label: 'End date',
    type: InputTypes.date,
    placeholder: 'MM/DD/YYYY',
  },
  transactionType: {
    name: 'transactionType',
    label: 'Type',
    type: InputTypes.picker,
    placeholder: 'Select type',
    items: transactionTypes,
  },
}

export interface IFilterInfoFields {
  startDate: IInputProps
  endDate: IInputProps
  transactionType: IInputProps
}

export type TransactionsFormValues = {
  startDate: string
  endDate: string
  transactionType: string
}

export const transactionsFilterSchema = yup.object().shape({
  startDate: yup.string(),
  endDate: yup.string(),
  transactionType: yup.string(),
})
