import React, { createContext, ReactNode, useState } from 'react'

import { closeModal, DisclaimersList, openModal } from '@views/components'

interface DisclaimerContextProps {
  children: ReactNode
}

type DisclaimerArgs = { onAccepted: () => void }

interface DisclaimerContextInfo {
  openDisclaimers: ({ onAccepted }: DisclaimerArgs) => void
  isDisclaimerAccepted: boolean
  setIsDisclaimerAccepted: (val: boolean) => void
}

export const DisclaimerContext = createContext<DisclaimerContextInfo>({} as DisclaimerContextInfo)

export function DisclaimerContextProvider({ children }: DisclaimerContextProps) {
  const [disclaimersListAccepted, setDisclaimersListAccepted] = useState(false)
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState<boolean>(disclaimersListAccepted)

  const handleDisclaimersList = ({ onAccepted }: DisclaimerArgs) => {
    openModal({
      dismiss: false,
      content: (
        <DisclaimersList
          onAccepted={() => {
            setDisclaimersListAccepted(true)
            onAccepted()
            closeModal()
          }}
        />
      ),
    })
  }

  const openDisclaimers = ({ onAccepted }: DisclaimerArgs) => {
    handleDisclaimersList({ onAccepted })
  }

  return (
    <DisclaimerContext.Provider
      value={{ openDisclaimers, isDisclaimerAccepted, setIsDisclaimerAccepted }}>
      {children}
    </DisclaimerContext.Provider>
  )
}
