import React, { useEffect, useState } from 'react'
import { AppText, Button, FormInput, InputTypes, LoadingSpinner, openModal, closeModal } from '@views/components'
import { View, ScrollView, StyleSheet, Image } from 'react-native'
import { FieldError, FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAccount, useAuth, useSystemConfig } from '@views/hooks'
import { CreateInternalTransferData } from '@views/contexts'
import { UserTypesEnum } from '@assets/constants'
import { checkAmount, parseMoney } from '@views/helpers'
import { BankingAccount } from '@domain/models'
import { COLORS } from '@assets/constants'
import { AntDesign } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import warningImg from '@assets/images/warning.png'

const internalTransferSchema = yup.object().shape({
  amount: yup.string().required('Required'),
  from: yup.string().required('Required'),
  to: yup.string().required('Required'),
})


const getAccountsOptions = (accounts: Array<BankingAccount>) => {
    return accounts.map((account) => ({
      label: account.nickname!,
      value: account.id,
    }))
}

export const InternalTransfer: React.FC = () => {
  const { 
    createInternalTransfer, 
    loadingTransfer, 
    orgAccount, 
    subAccounts, 
    userAccount,
    loadingAccounts, 
  } = useAccount()
  const { user } = useAuth()
  const isSubAccount = user?.type === UserTypesEnum.SUB_ADMIN
  const { isSmallDevice } = useSystemConfig()
  const [showWarning, setShowWarning] = useState(false)

  const originAccountOptions: BankingAccount[] = isSubAccount
  ? [userAccount]
  : [orgAccount, ...subAccounts]

  const destinyAccountOptions: BankingAccount[] = isSubAccount
  ? [orgAccount]
  : subAccounts
  
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(internalTransferSchema),
    defaultValues: {
      from: userAccount.id, 
      to: "", 
      amount: ""
    }
  })


  useEffect(() => {
    const prefillIndex = isSubAccount? 0 : 1

    const hasOrgAccountLoaded = !loadingAccounts && orgAccount.id
    if(isSubAccount && hasOrgAccountLoaded){
      reset({
        from: userAccount.id, 
        to: destinyAccountOptions[prefillIndex]?.id || "",
        amount: ""
      })
    }
  }, [loadingAccounts, orgAccount])

  const onSubmit = async (data: FieldValues) => {
    const amount = Number(parseMoney(data.amount)); 
    const amountCheck = checkAmount(amount, userAccount.available_balance)
    if(data.to === data.from){
      setError("to", { message: "You cannot send money to the same account." })
      return
    }

    if(!amountCheck.valid){
      setError("amount", { message: amountCheck.message })
      return
    }

    const transferData = {
      ...data,
      amount,
    } as unknown as CreateInternalTransferData
   
    await createInternalTransfer(transferData)
  }

  if(loadingAccounts) {
    return (
      <View style={styles.loading}>
        <LoadingSpinner />
      </View>
      )
    }

  return (
    <View>
      { showWarning? (
        <View style={styles.messageWrapper}>
          <Image source={warningImg} style={styles.image} />
          <AppText style={styles.successTitle}>The Entered Data Was Not Saved</AppText>
          <AppText style={styles.successSubtitle}>
            Are you sure you want to close and go back to your Business Account? You can lose your
            data.
          </AppText>
          <Button
            onPress={() => {
              setShowWarning(false)
            }}
            text="Continue Transfer"
          />
          <TouchableOpacity style={styles.backButton} onPress={() => closeModal()}>
            <AppText>Back to Business Account</AppText>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <View style={isSmallDevice ? styles.headerMobile : styles.header}>
            <AppText style={styles.title}>Internal Transfer</AppText>
            <AntDesign
              name="closesquare"
              size={24}
              color={COLORS.GRAY_700}
              onPress={() => setShowWarning(true)}
            />
          </View>
          <ScrollView>
            <FormInput
              disabled={isSubAccount}
              type={InputTypes.picker}
              items={getAccountsOptions(originAccountOptions)}
              name={'from'}
              label={'From'}
              control={control}
              error={errors.from as unknown as FieldError}
              placeholder={'Select account'}
            />
            <FormInput
              disabled={isSubAccount}
              type={InputTypes.picker}
              items={getAccountsOptions(destinyAccountOptions)}
              name={'to'}
              label={'To'}
              control={control}
              error={errors.to as unknown as FieldError}
              placeholder={'Select Sub-account name'}
            />
            <FormInput
              type={InputTypes.money}
              name={'amount'}
              label={'Amount'}
              control={control}
              error={errors.amount as unknown as FieldError}
              placeholder={'Enter amount'}
            />
            <Button
              onPress={handleSubmit(onSubmit)}
              text={loadingTransfer ? 'Loading...' : 'Transfer'}
              disabled={loadingTransfer}
            />
          </ScrollView>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  successTitle: { fontStyle: 'normal', fontWeight: '700', fontSize: 24, marginBottom: 10 },
  successSubtitle: { marginBottom: 32, paddingHorizontal: 5 },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  backButton: {
    marginTop: 28,
    textAlign: 'center',
  },
  title: { fontSize: 24, marginBottom: 8 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 281,
  },
  loading: {
    justifyContent: "center", 
    alignItems: "center"
  }
})