import { Transactions, TransactionsResponse } from '@domain/models'

export interface ITransactionsContext {
  getTransactions: (params: Transactions) => Promise<void>
  transactionsState: ITransactionsState
}

export interface ITransactionsState {
  transactions: TransactionsResponse
  loading: boolean
}
export const TransactionsInitialState: ITransactionsState = {
  transactions: {
    next_page_token: undefined,

    result: [],
  },
  loading: false,
}
