export enum CameraPermissionStatus {
  GRANTED = 'granted',
  UNDETERMINED = 'undetermined',
  DENIED = 'denied',
}

export const askCameraPermission = async (): Promise<CameraPermissionStatus> => {
  return await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((res) => res.getTracks().forEach((track) => track.stop()))
    .then(() => CameraPermissionStatus.GRANTED)
    .catch(() => CameraPermissionStatus.DENIED)
}
