import {
  BankingAccount,
  CardProductsResponse,
  Deposit,
  ExternalAccount,
  MemberCard,
  PlaidLinkToken,
  Transactions,
  TransactionsResponse,
  User,
  Card,
  Statement,
  StatementsResponse,
} from '@domain/models'
import { IssueCardBody, PatchCardDTO } from '@domain/models/Card'
import { BankingRepository } from '@domain/repositories/BankingRepository'
import { HttpRepository } from '@domain/repositories/HttpRepository'
import {
  AchDTO,
  CreateBankAccountDTO,
  GetMemberCardsDTO,
  InternalTransferDtoToInternalTransfer,
  InternalTransferResponseDTO,
  linkTokenDtoToLinkToken,
  LinkTokenResponseDTO,
} from '@microservices/dto'

export const bankingRepository = (client: HttpRepository): BankingRepository => ({
  createLinkToken: async (association_id?: string): Promise<PlaidLinkToken | undefined> => {
    const response = await client.post<LinkTokenResponseDTO>(
      `banking/business/link-token/${association_id}`
    )
    if (response) return linkTokenDtoToLinkToken(response.data)
    throw new Error('Link token error')
  },
  exchangePublicToken: async (
    public_token: string,
    accounts: string[],
    institution: any,
    association_id?: string
  ): Promise<User | undefined> => {
    const response = await client.post<User>(`banking/business/exchange-token/${association_id}`, {
      public_token,
      accounts,
      institution,
    })
    if (response) return response.data
  },
  getTransactions: async (info: Transactions): Promise<TransactionsResponse | undefined> => {
    const response = await client.get<TransactionsResponse>(
      `banking/transactions/${info.pendingOrPosted}/${info.bankAccountId}`,
      {
        ...info,
      }
    )
    if (response) return response.data
  },
  getCardProducts: async (): Promise<CardProductsResponse | undefined> => {
    const response = await client.get<CardProductsResponse>(`banking/cards/products`)
    if (response) return response.data
  },
  submitIssueCard: async (body: IssueCardBody): Promise<void> => {
    await client.post(`banking/cards/issue/${body.form.toLowerCase()}`, body)
  },
  createBankAccount: async (data: CreateBankAccountDTO) => {
    const response = await client.post<User>('banking', data)
    if (response) return response.data
  },
  sendACH: async (data: AchDTO) => {
    const response = await client.post('banking/ach', data)
    if (response) return response.data as unknown
  },
  createCheckDeposit: async (business_id, payload) => {
    const response = await client.post<any>(
      `banking/check/${business_id}`,
      payload.body,
      payload.headers
    )
    if (response) return response.data
  },
  createInternalTransfer: async (data) => {
    const response = await client.post<InternalTransferResponseDTO>(
      'banking/transactions/internal-transfer',
      data
    )
    if (response) return InternalTransferDtoToInternalTransfer(response.data)
  },
  async findOrCreateExternalAccount(data) {
    const response = await client.post<ExternalAccount>('banking/external-account', data)
    return response?.data
  },
  getCardWidgetURL: async (user_id, widget_type, card_id) => {
    const payload = { user_id, widget_type, card_id }
    const response = await client.post<{ url: string }>('banking/card-widget', payload)
    if (response) return response.data
  },
  getAllAccounts: async (association_id: string) => {
    const response = await client.get<Array<BankingAccount>>('banking', { association_id })
    if (response) return response.data
  },
  getDeposits: async (account_id) => {
    const payload = { account_id }
    const response = await client.get<{ deposits: Deposit[] }>('banking/check', payload)
    if (response) return response.data
  },
  getCards: async (accountInfo: GetMemberCardsDTO) => {
    const response = await client.get<MemberCard[]>('banking/cards', accountInfo)
    if (response) return response.data
  },
  patchCardInfo: async (card_id?: string, cardInfo?: PatchCardDTO) => {
    if (!cardInfo) return
    return await client.patch<Card>(`banking/cards/${card_id}`, cardInfo)
  },
  getCardClientToken: async (card_id: string) => {
    const response = await client.post<{ client_token: string }>(
      `banking/cards/${card_id}/client_token`
    )
    if (response) return response.data
  },
  getStatementsByAccountId: async (account_id: string) => {
    const response = await client.get<StatementsResponse>(`banking/statements`, { account_id })
    if (response) return response.data
  },
  getStatement: async (statement_id: string) => {
    const response = await client.get<Statement>(`banking/statements/${statement_id}`)
    if (response) return response.data
  },
})
