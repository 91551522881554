import React from 'react'
import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { COLORS, FONT_TYPES } from '@assets/constants'
import { AppText } from '@views/components'
import { currencyFormatter, formatDate, toTitleString } from '@views/utils'
import { useDeposits } from '@views/hooks'

export const DepositsTabMobileVersion: React.FC = () => {
  const { deposits, initiatedBy } = useDeposits()

  return (
    <View style={styles.listContainer}>
      {deposits.map((item, i) => (
        <View key={item.id}>
          <View style={styles.container}>
            <View style={styles.row}>
              <AppText style={styles.header}>Sender</AppText>
              <AppText semiBold style={styles.dataItem}>
                {`${initiatedBy.user.first_name} ${initiatedBy.user.last_name}`}
              </AppText>
            </View>
            <View style={styles.row}>
              <AppText style={styles.header}>Date</AppText>
              <AppText light style={styles.dataItem}>
                {formatDate(item.creation_time, 'MM/DD/YY')}
              </AppText>
            </View>
            <View style={styles.row}>
              <AppText style={styles.header}>Status</AppText>
              <AppText light style={styles.dataItem}>
                {toTitleString(item.status)}
              </AppText>
            </View>
            <View style={styles.row}>
              <AppText style={styles.header}>Amount</AppText>
              <AppText light style={styles.dataItem}>
                + {currencyFormatter(item.check_amount / 100)}
              </AppText>
            </View>
          </View>
          {i !== deposits.length - 1 && <View style={styles.seperator} />}
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.GRAY_200,
  },
  header: {
    fontSize: 14,
    fontFamily: FONT_TYPES.FONT_SEMIBOLD,
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    padding: 24,
    shadowColor: COLORS.SHADOW_COLOR,
    shadowOffset: { width: 2, height: -1 },
    shadowOpacity: 0.12,
    shadowRadius: 24,
  },
  dataItem: {
    fontSize: 14,
  },
  seperator: {
    height: 16,
  },
  listContainer: {
    marginVertical: 24,
  },
})
