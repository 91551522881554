import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { AppText, closeModal, openModal } from '@views/components'
import ImgPlaceholder from '@assets/images/img_placeholder.png'
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo'
import { ImageContent } from './ImageContent'
import { LandscapeCamera } from './LandscapeCamera'
import { CameraPermissionStatus } from '@views/utils'
import { useAuth, useCameraPermission } from '@views/hooks'
import { NavigationProp, useNavigation } from '@react-navigation/native'

type TakePicturesProps = {
  setFront: React.Dispatch<React.SetStateAction<string | undefined>>
  front: string | undefined
  setBack: React.Dispatch<React.SetStateAction<string | undefined>>
  back: string | undefined
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>
  takeBackSide?: boolean
  navigate?: () => void
  showBorder?: boolean
}
export const TakePictures: React.FC<TakePicturesProps> = ({
  setFront,
  front,
  setBack,
  back,
  setIsComplete,
  takeBackSide,
  navigate,
  showBorder = false,
}) => {
  const [camera, setCamera] = useState<CameraPhoto | null>(null)
  const [takingFrontPhoto, setTakingFrontPhoto] = useState(true)
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { logout } = useAuth()
  const { cameraPermission, changeCameraPermission } = useCameraPermission()

  const startCamera = async (newCam?: CameraPhoto) => {
    let activeCamera = camera

    if (newCam) {
      activeCamera = newCam
      setCamera(newCam)
    }

    if (activeCamera) {
      try {
        await activeCamera.startCameraMaxResolution(FACING_MODES.ENVIRONMENT)
        changeCameraPermission(CameraPermissionStatus.GRANTED)
      } catch (err) {
        console.error(err)
        openModal({
          title: 'Permission for camera use has not been given.',
          isInlineTitle: true,
          subtitle: 'In order to capture your check, please enable your camera',
          confirmationButtonMessage: 'Reload',
          onConfirmPressed: () => {
            location.reload()
          },
          onDismiss: () => {
            if (navigate) {
              navigate()
            } else {
              closeModal()
            }
          },
        })
        setIsCameraOpen(false)
        changeCameraPermission(CameraPermissionStatus.DENIED)
      }
    }
  }

  const closeCamera = async () => {
    if (camera) {
      if (camera.stream) {
        await camera.stopCamera()
      }
      setIsCameraOpen(false)
    }
  }

  const takePicture = async () => {
    if (camera) {
      try {
        const uri = camera?.getDataUri({ sizeFactor: 1, imageType: IMAGE_TYPES.JPG })
        if (!uri) {
          console.log('uri - ', uri)
          openModal({
            type: 'warning',
            title: 'We were unable to save your check image. Please signout and try again.',
            confirmationButtonMessage: 'Logout',
            onConfirmPressed: logout,
          })
          return
        }

        // Check if uri is valid URL
        new URL(uri)

        if (takingFrontPhoto) setFront(uri)
        else setBack(uri)
      } catch (err) {
        // Not valid URL
        console.log(err)
        openModal({
          type: 'warning',
          title: 'We were unable to save your check image. Please signout and try again.',
          confirmationButtonMessage: 'Logout',
          onConfirmPressed: logout,
        })
      } finally {
        await closeCamera()
      }
    }
  }
  const handleOpenCamera = async (isTakingFront: boolean) => {
    setTakingFrontPhoto(isTakingFront)
    setIsCameraOpen(true)
  }

  useEffect(() => {
    if (!takeBackSide && front) {
      setIsComplete(true)
    }
    if (takeBackSide && front && back) {
      setIsComplete(true)
    }
  }, [takeBackSide, front, back])

  return (
    <>
      <LandscapeCamera
        videoRef={videoRef}
        takePicture={takePicture}
        closeCamera={closeCamera}
        showBorder={showBorder}
        isCameraOpen={isCameraOpen}
        startCamera={startCamera}
      />
      {cameraPermission === CameraPermissionStatus.DENIED && (
        <AppText style={styles.title} bold>
          Permission for camera use has not been given
        </AppText>
      )}
      <View style={styles.imageContainer}>
        <ImageContent
          imgSrc={front !== undefined ? front : ImgPlaceholder}
          title={!takeBackSide ? 'Info Page' : 'Front'}
          onPress={() => handleOpenCamera(true)}
        />
        {takeBackSide && (
          <ImageContent
            imgSrc={back !== undefined ? back : ImgPlaceholder}
            title={'Back'}
            onPress={() => handleOpenCamera(false)}
          />
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: 100,
    width: '90vw',
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
})
