import { noSelectionRegex, phoneRegex, states, zipRegex } from '@assets/constants'
import { InputTypes } from '@views/components'
import { IInputProps } from '@views/components/Form'
import * as yup from 'yup'

export const issueCardFields: IIssueCardFields = {
  selectCardType: {
    placeholder: 'Select',
    label: 'Select card type',
    type: InputTypes.picker,
    name: 'selectCardType',
  },
  recipientName: {
    placeholder: 'Select recipient',
    label: 'Recipient name',
    type: InputTypes.picker,
    name: 'recipientName',
  },
  orgName: {
    label: 'Organization name',
    type: InputTypes.text,
    name: 'orgName',
  },
  first_name: {
    name: 'first_name',
    label: 'First name',
    type: InputTypes.text,
    placeholder: 'Enter First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last name',
    type: InputTypes.text,
    placeholder: 'Enter Last Name',
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    type: InputTypes.text,
    placeholder: 'Enter Phone',
  },
  address: {
    name: 'address',
    label: 'Street Address',
    type: InputTypes.text,
    placeholder: 'Enter Street Address',
  },
  city: {
    name: 'city',
    label: 'City',
    type: InputTypes.text,
    placeholder: 'Enter City',
  },
  state: {
    name: 'state',
    label: 'State',
    type: InputTypes.picker,
    placeholder: 'Select State',
    items: states,
  },

  zip_code: {
    name: 'zip_code',
    label: 'Postal Code',
    type: InputTypes.text,
    placeholder: 'Enter Postal Code',
  },
}

export interface IIssueCardFields {
  selectCardType: IInputProps
  recipientName: IInputProps
  orgName: IInputProps
  first_name: IInputProps
  last_name: IInputProps
  address: IInputProps
  city: IInputProps
  state: IInputProps
  phone: IInputProps
  zip_code: IInputProps
}

export interface IIssueCard {
  selectCardType?: string
  recipientName?: string
  orgName?: string
  first_name?: string
  last_name?: string
  phone?: string
  address?: string
  city?: string
  state?: string
  country?: string
}

export const IssueCardSchema = [
  yup.object().shape({
    selectCardType: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
  yup.object().shape({
    recipientName: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
  yup.object().shape({
    recipientName: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  }),
  yup.object().shape({
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    phone: yup.string().trim().matches(phoneRegex, 'Invalid phone format').required('Required'),
    address: yup.string().required('Required'),
    city: yup.string().required('Required'),
    state: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
    zip_code: yup
      .string()
      .matches(zipRegex, 'Invalid Zipcode format')
      .min(5, 'Invalid Zipcode format')
      .max(5, 'Invalid Zipcode format')
      .required('Required'),
  }),
]
