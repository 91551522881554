import React from 'react'
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import { AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { currencyFormatter } from '@views/utils'
import { useAccount, useDeposits } from '@views/hooks'
import { BankingAccount } from '@domain/models'


const TeamTabs: React.FC<{}> = () => {
  const { subAccounts, currentSelectedAccount, onSelectCurrentAccount } = useAccount()
  const { getDeposits } = useDeposits()

  const handleSelectAccount = (account: BankingAccount) => {
    onSelectCurrentAccount(account)
    getDeposits(account.id)
  }

  return (
    <View>
      {subAccounts.length > 0 ? (
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={styles.teamTabs}>
          {subAccounts.map((account) => {
            const isAccountSelected = currentSelectedAccount.id === account.id
            return (
              <TouchableOpacity
                key={account.id}
                style={
                  isAccountSelected
                    ? styles.teamTabSelected
                    : styles.teamTab
                }
                onPress={() => handleSelectAccount(account)}>
                <View>
                  <AppText style={styles.teamName}>{account.nickname}</AppText>
                  <AppText
                    style={
                      styles.teamOwner
                    }>{`${account.user.first_name} ${account.user.last_name}`}</AppText>
                </View>
                <AppText style={styles.teamBalance}>
                  {currencyFormatter(account.available_balance / 100)}
                </AppText>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      ) : (
        <AppText style={styles.addSubAcc}>Add a sub-account to get started</AppText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  teamTabs: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: COLORS.WHITE,
    borderRadius: 4,
    margin: 10,

    shadowColor: COLORS.DARK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9,
  },
  teamTab: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 18,
    borderTopWidth: 1,
    borderTopColor: COLORS.BACKGROUND,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  teamTabSelected: {
    borderRadius: 4,
    padding: 18,
    borderWidth: 2,
    borderColor: COLORS.PRIMARY,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  teamName: {
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 5,
  },
  teamOwner: {
    fontSize: 12,
    fontWeight: '300',
  },
  teamBalance: {
    fontSize: 16,
    fontWeight: '700',
  },
  addSubAcc: {
    marginBottom: 30,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '400',
  },
})

export default TeamTabs
