import React from 'react'
import { StyleSheet } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { AppText, Row } from '@views/components'

type Props = {
  textStyle?: {}
  listStyle?: {}
  data: string[]
  underlineMap?: { [index: number]: string }
}

export const UL: React.FC<Props> = ({ textStyle, listStyle, data, underlineMap }) => {
  const bulletPoint = `\u2022`

  const defaultRender = (item: string) => (
    <Row>
      <AppText style={textStyle} bold>
        {bulletPoint}
      </AppText>
      <AppText style={[textStyle, styles.paddingLeft]} bold>
        {item}
      </AppText>
    </Row>
  )

  // Adds underline where word is in string
  const buildNewBullet = (orginalString: string, wordToUnderline: string) => {
    const splitString = orginalString.split(' ')

    const addSpace = (index: number) => (index === splitString.length - 1 ? `` : ` `)

    return splitString.map((word, index) => {
      if (word === wordToUnderline) {
        return (
          <AppText
            key={`${word}-${index}`}
            style={[textStyle, styles.underline]}
            bold>{`${word}${addSpace(index)}`}</AppText>
        )
      } else {
        return `${word}${addSpace(index)}`
      }
    })
  }

  return (
    <FlatList
      data={data}
      style={listStyle}
      keyExtractor={(_, index) => `UL_${index}`}
      renderItem={({ item, index }) => {
        if (!underlineMap) return defaultRender(item)

        const word = underlineMap[index]

        if (!word) return defaultRender(item)

        const newBuild = buildNewBullet(item, word)

        return (
          <Row>
            <AppText style={textStyle} bold>
              {bulletPoint}
            </AppText>
            <AppText style={[textStyle, styles.paddingLeft]} bold>
              {newBuild.map((item) => item)}
            </AppText>
          </Row>
        )
      }}
    />
  )
}

const styles = StyleSheet.create({
  paddingLeft: {
    paddingLeft: 5,
  },
  underline: {
    textDecorationLine: 'underline',
  },
})
