import { Transactions, TransactionsResponse } from '@domain/models'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { Dispatch } from 'react'

export enum ActionType {
  SET_LOADING = 'SET_LOADING',
  SET_TRANSACTIONS = 'SET_TRANSACTIONS',
}

export interface IAction {
  type: ActionType
  payload?: any
}

export const getTransactionsAction = async (
  params: Transactions,
  dispatchCallback: Dispatch<TransactionsResponse | undefined>
): Promise<void> => {
  const transactions = await bankingService(bankingRepo).getTransactions(params)
  dispatchCallback(transactions)
}

export type TransactionActions = IAction
