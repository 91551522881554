import { AuthenticateUserData, UserRepository } from '@domain/repositories/UserRepository'
import { HttpRepository } from '@domain/repositories/HttpRepository'
import { AdminInfoToUserDTO, SendEmailRequestDto } from '@microservices/dto/UserDTO'
import { Documents, User } from '@domain/models'
import { IAdminInfo, IBeneficialOwnersInfo } from '@views/schemas'

export const userRepository = (client: HttpRepository): UserRepository => ({
  authenticateUser: async (data: AuthenticateUserData) => {
    const response = await client.post<User>('user/authenticate', data)
    return response?.data
  },
  createAdminWithDisclosures: async (user: IAdminInfo, association_id?: string) => {
    const adminDto = AdminInfoToUserDTO(user)
    return await client.post<User>(`user/admin/${association_id}`, adminDto)
  },
  getAdminInfo: async (user_id?: string) => {
    return await client.get<IAdminInfo>(`user/admin/${user_id}`)
  },
  patchAdminInfo: async (user_id?: string, adminInfo?: Partial<IAdminInfo>) => {
    if (!adminInfo) return
    const adminDto = AdminInfoToUserDTO(adminInfo)
    return await client.patch<User>(`user/admin/${user_id}`, adminDto)
  },
  createBeneficialOwners: async (owner: IBeneficialOwnersInfo, association_id?: string) => {
    const body = {
      user: owner,
      additional_data: {
        percent_ownership: owner.percent_ownership,
      },
    }
    return await client.post(`user/owners/${association_id}`, body)
  },
  getBeneficialOwners: async (association_id?: string) => {
    return await client.get(`user/owners/${association_id}`)
  },
  updateBeneficialOwner: async (user_id?: string, owner?: Partial<IBeneficialOwnersInfo>) => {
    const body = {
      ...owner,
      percent_ownership: owner?.percent_ownership,
    }
    return await client.patch(`user/owners/${user_id}`, body)
  },
  postBeneficialOwnersEmails: async (
    sendEmailInfo: SendEmailRequestDto,
    association_id?: string
  ) => {
    await client.post(`user/invite-user/${association_id}`, sendEmailInfo)
  },
  postPersonalDocuments: async (user_id?: string, payload?: any) => {
    const response = await client.post<Documents>(`documents/user/${user_id}`, payload.body, payload.headers)
    if (response?.status === 201) return response.data
    return
  },
  sendSubAccountEmail: async (data: any) => {
    const response = await client.post(`user/sub-account`, data)
    if (response?.status === 201) return response.data

    return
  },
  createSubAccountAdmin: async (data: any, business_id?: string) => {
    const adminDto = AdminInfoToUserDTO(data)
    const response = await client.post(`user/sub-account/admin/${business_id}`, adminDto)
    if (response?.status === 201) return response.data

    return
  },
})
