import {
  BadRequestError,
  NotFoundError,
  ServerError,
  UnauthenticatedError,
  UnauthorizedError,
} from '.'

export const ErrorFromRes = (status?: number | string, code?: string, message?: string) => {
  const statusErrors: Record<string, Error> = {
    '400': new BadRequestError(message),
    '401': new UnauthenticatedError(message),
    '403': new UnauthorizedError(message),
    '404': new NotFoundError(message),
    '500': new ServerError(message),
  }
  const codeErrors: Record<string, Error> = {
    ERR_FR_TOO_MANY_REDIRECTS: new ServerError(message),
    ERR_BAD_OPTION_VALUE: new ServerError(message),
    ERR_BAD_OPTION: new ServerError(message),
    ERR_NETWORK: new ServerError(message),
    ERR_DEPRECATED: new ServerError(message),
    ERR_BAD_RESPONSE: new ServerError(message),
    ERR_BAD_REQUEST: new BadRequestError(message),
    ERR_CANCELED: new ServerError(message),
    ECONNABORTED: new ServerError(message),
    ETIMEDOUT: new ServerError(message),
  }
  if (status) return statusErrors[`${status}`]
  if (code) return codeErrors[code]
  return null
}
