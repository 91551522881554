import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  cloneElement,
  useCallback,
  useEffect,
} from 'react'
import { ModalHandles, ModalOptions } from './types'
import {
  View,
  StyleSheet,
  Modal as RnModal,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native'
import { AppText, Button, LoadingSpinner, Row } from '@views/components'

import successImg from '@assets/images/sucess-check.png'
import warningImg from '@assets/images/warning.png'
import errorImg from '@assets/images/error.png'
import { useSystemConfig } from '@views/hooks'
import { COLORS } from '@assets/constants'
import { AntDesign } from '@expo/vector-icons'

const MODAL_DEFAULT_OPTIONS: ModalOptions = {
  dismiss: true,
  isInlineTitle: false,
}

const Modal: ForwardRefRenderFunction<ModalHandles> = (_, ref) => {
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<ModalOptions>({} as ModalOptions)
  const { isWeb } = useSystemConfig()

  const openModal = useCallback((modalOptions: ModalOptions) => {
    setOptions({ ...MODAL_DEFAULT_OPTIONS, ...modalOptions })
    setVisible(true)
  }, [])
  const closeModal = useCallback(() => setVisible(false), [])

  const handleBackOnBrowser = () => {
    const onPopBack = () => {
      if (visible) closeModal()
    }
    window.addEventListener('popstate', onPopBack)
    return () => window.removeEventListener('popstate', onPopBack)
  }

  useEffect(isWeb ? handleBackOnBrowser : () => {}, [visible])

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  )

  const getImage = () => {
    if (!options.type && !options.modalImagePath) return

    let image
    switch (options.type) {
      case 'success':
        image = successImg
        break
      case 'warning':
        image = warningImg
        break
      case 'error':
        image = errorImg
        break
      case 'loading':
        return (
          <View style={styles.loadingSpinner}>
            <LoadingSpinner size={100} />
          </View>
        )
    }
    return <Image source={options.modalImagePath || image} style={styles.image} />
  }

  const getContent = () => {
    return (
      <>
        {!options.isInlineTitle && (
          <AppText bold style={styles.title}>
            {options.title}
          </AppText>
        )}
        {options.content ? (
          options.content
        ) : (
          <ScrollView contentContainerStyle={styles.scrollContent}>
            <AppText style={styles.subtitle}>{options.subtitle}</AppText>
          </ScrollView>
        )}
      </>
    )
  }

  const getButtons = () => {
    if (!options.buttons || !options.buttons.length) return getDefaultButtons()

    return (
      <View style={styles.button}>
        {options.buttons.map((button, idx) => {
          return cloneElement(button, {
            onPress: () => {
              closeModal()
              button.props.onPress?.()
            },
            key: `modal-button-${idx}`,
          })
        })}
      </View>
    )
  }

  const getDefaultButtons = () => {
    let buttons = []
    if (options?.onConfirmPressed || options?.confirmationButtonMessage)
      buttons.push(
        <Button
          key="confirmation"
          onPress={() => {
            closeModal()
            options?.onConfirmPressed?.()
          }}
          text={options.confirmationButtonMessage || 'Confirm'}
        />
      )
    if (options?.onCancelPressed || options?.cancelButtonMessage)
      buttons.push(
        <Button
          key="cancel"
          onPress={() => {
            closeModal()
            options?.onCancelPressed?.()
          }}
          type="secondary"
          text={options.cancelButtonMessage || 'Cancel'}
        />
      )
    return buttons.length ? <View style={styles.button}>{buttons}</View> : null
  }

  return (
    <RnModal
      visible={visible}
      animationType="fade"
      transparent
      onRequestClose={closeModal}
      onDismiss={options.onDismiss}>
      <TouchableWithoutFeedback onPress={() => options.dismiss && closeModal()}>
        <View style={[styles.container, options.containerStyle]}>
          <TouchableWithoutFeedback>
            <View style={[styles.content, options.contentStyle]}>
              {options.isInlineTitle ? (
                <Row style={styles.inlineTitle}>
                  <AppText bold style={[styles.title, styles.inlineTitleText]}>
                    {options.title}
                  </AppText>
                  {options.dismiss && (
                    <TouchableOpacity onPress={closeModal} style={{ alignItems: 'flex-end' }}>
                      <AntDesign name="closesquare" size={20} color={COLORS.GRAY_700} />
                    </TouchableOpacity>
                  )}
                </Row>
              ) : (
                options.dismiss && (
                  <TouchableOpacity onPress={closeModal} style={{ alignItems: 'flex-end' }}>
                    <AntDesign name="closesquare" size={20} color={COLORS.GRAY_700} />
                  </TouchableOpacity>
                )
              )}

              {getImage()}
              {getContent()}
              {getButtons()}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </RnModal>
  )
}

export default forwardRef(Modal)

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  scrollContent: {
    flex: 1,
  },
  headerRow: {
    justifyContent: 'space-between',
  },
  closeBtn: {
    width: 20,
    height: 20,
  },
  content: {
    flexGrow: 0,
    backgroundColor: 'white',
    borderRadius: 12,
    maxWidth: 480,
    margin: 16,
    padding: 36,
  },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  loadingSpinner: {
    marginBottom: 24,
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    lineHeight: 32,
    marginBottom: 8,
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 24,
  },
  button: {
    marginTop: 32,
  },
  inlineTitle: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inlineTitleText: {
    width: 'fit-content',
  },
})
