import React from 'react'
import { View, StyleSheet } from 'react-native'

type Props = {
  url: string
}

export const CardWidget: React.FC<Props> = ({ url }) => {
  return (
    <View style={styles.container}>
      <iframe src={url} width="100%" height="100%" style={{ border: 'none' }} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 300,
    width: 350,
  },
})
