import React, { useEffect } from 'react'
import { BankingAccount } from '@domain/models'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { openModal } from '@views/components'
import { createContext, ReactNode, useState } from 'react'
import { ExternalAccountToExternalAccountReqeustDTO } from '@microservices/dto'
import { getErrorMessage } from '@views/helpers'
import { useAuth } from '@views/hooks'
import { LoadingPage } from '@views/pages'
import { dollarToCents } from '@views/utils'
import { UnauthenticatedError } from '@microservices/errors/http'

interface IAccountContext {
  orgAccount: BankingAccount
  userAccount: BankingAccount
  currentSelectedAccount: BankingAccount
  subAccounts: BankingAccount[]
  onSelectCurrentAccount: (account: BankingAccount) => void
  createInternalTransfer: (data: CreateInternalTransferData) => Promise<void>
  transferFunds: (data: TransferFundsData) => Promise<void>
  getAllAccounts: (association_id: string) => Promise<void>
  loadingTransfer: boolean
  loadingAccounts: boolean
}

export type CreateInternalTransferData = {
  amount: number
  from: string
  to: string
}

export type TransferFundsData = {
  accountOwnerName: string
  bankName: string
  routingOrABANumber: string
  accountNumber: string
  amount: number
  comments?: string
  user_id: string
  originating_account_id: string
}

interface Props {
  children: ReactNode
}

export const AccountContext = createContext<IAccountContext>({} as IAccountContext)

const service = bankingService(bankingRepo)

export function AccountContextProvider({ children }: Props) {
  const [orgAccount, setOrgAccount] = useState<BankingAccount>({} as BankingAccount)
  const [userAccount, setUserAccount] = useState<BankingAccount>({} as BankingAccount)
  const [subAccounts, setSubAccounts] = useState<BankingAccount[]>([])
  const [currentSelectedAccount, setCurrentSelectedAccount] = useState<BankingAccount>(
    {} as BankingAccount
  )
  const [loadingTransfer, setLoadingTransfer] = useState(false)
  const [loadingAccounts, setLoadingAccounts] = useState(false)
  const { user, authFlowInfo, logout } = useAuth()

  const onSelectCurrentAccount = (account: BankingAccount) => {
    setCurrentSelectedAccount(account)
  }

  const getAllAccounts = async (association_id: string) => {
    try {
      if (!user?.Banking_account?.length) {
        logout()
        return
      }
      setLoadingAccounts(true)
      const response = await service.getAllAccounts(association_id)
      if (!response) throw new Error('Something went wrong')

      setSubAccounts(response.filter((account) => account.is_subaccount))
      setOrgAccount(response.filter((account) => !account.is_subaccount)[0])

      if (user?.Banking_account?.length) {
        const userAcc = response.find((acc) => acc.id === user?.Banking_account![0].id)

        if (!userAcc) {
          logout()
          return
        }
        setUserAccount(userAcc)
        setCurrentSelectedAccount(userAcc)
      }
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openModal({
        type: 'warning',
        title: 'Error',
        dismiss: false,
        subtitle: getErrorMessage(error),
        confirmationButtonMessage: 'Continue',
        contentStyle: { width: '80%', maxWidth: 400 },
      })
    } finally {
      setLoadingAccounts(false)
    }
  }

  const updateBalance = (data: { amount: number; accountId: string; isDecrease: boolean }) => {
    const { accountId, amount, isDecrease } = data

    if (accountId === userAccount.id) {
      setUserAccount({
        ...userAccount,
        available_balance: isDecrease
          ? userAccount.available_balance - dollarToCents(amount)
          : userAccount.available_balance + dollarToCents(amount),
      })
      return
    }

    if (accountId === orgAccount.id) {
      setOrgAccount({
        ...orgAccount,
        available_balance: isDecrease
          ? orgAccount.available_balance - dollarToCents(amount)
          : orgAccount.available_balance + dollarToCents(amount),
      })
      return
    }

    const newSubAccounts = subAccounts.map((acc) => {
      if (acc.id == accountId) {
        isDecrease
          ? (acc.available_balance -= dollarToCents(amount))
          : (acc.available_balance += dollarToCents(amount))
      }
      return acc
    })
    setSubAccounts(newSubAccounts)
  }

  const createInternalTransfer = async (data: CreateInternalTransferData) => {
    try {
      setLoadingTransfer(true)
      const internalTransferData = {
        amount: data.amount,
        originating_account_id: data.from,
        receiving_account_id: data.to as string,
      }
      await service.createInternalTransfer(internalTransferData)
      openModal({
        type: 'success',
        title: 'Success',
        dismiss: false,
        subtitle: 'Funds have been transferred successfully.',
        confirmationButtonMessage: 'Continue',
        onConfirmPressed: () => {
          updateBalance({ amount: data.amount, accountId: data.from, isDecrease: true })
          updateBalance({ amount: data.amount, accountId: data.to, isDecrease: false })
        },
      })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openModal({
        type: 'warning',
        title: 'Error',
        dismiss: false,
        subtitle: getErrorMessage(error),
        confirmationButtonMessage: 'Continue',
        contentStyle: { width: '80%', maxWidth: 400 },
      })
    } finally {
      setLoadingTransfer(false)
    }
  }

  const transferFunds = async (data: TransferFundsData) => {
    try {
      setLoadingTransfer(true)
      const externalAccountData = await service.findOrCreateExternalAccount(
        ExternalAccountToExternalAccountReqeustDTO(data)
      )
      if (!externalAccountData) throw new Error('No account found.')
      const { user_id, amount, originating_account_id, comments } = data
      const receiving_account_id = externalAccountData?.id
      const dc_sign = 'credit'
      await service.sendACH({
        user_id,
        amount: String(amount),
        originating_account_id,
        receiving_account_id,
        dc_sign,
        comments,
      })
      openModal({
        type: 'success',
        title: 'Success',
        dismiss: false,
        subtitle: 'Funds have been transferred successfully.',
        confirmationButtonMessage: 'Continue',
        onConfirmPressed: () =>
          updateBalance({
            amount: data.amount,
            accountId: data.originating_account_id,
            isDecrease: true,
          }),
      })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openModal({
        type: 'warning',
        title: 'Error',
        dismiss: false,
        subtitle: getErrorMessage(error),
        confirmationButtonMessage: 'Continue',
        contentStyle: { width: '80%', maxWidth: 400 },
      })
    } finally {
      setLoadingTransfer(false)
    }
  }

  useEffect(() => {
    const isFlowComplete = authFlowInfo?.isComplete

    if (user?.association?.id && isFlowComplete) {
      getAllAccounts(user.association.id)
    }
  }, [user, authFlowInfo])

  if (loadingAccounts) return <LoadingPage />

  return (
    <AccountContext.Provider
      value={{
        orgAccount,
        userAccount,
        currentSelectedAccount,
        subAccounts,
        createInternalTransfer,
        loadingTransfer,
        transferFunds,
        getAllAccounts,
        onSelectCurrentAccount,
        loadingAccounts,
      }}>
      {children}
    </AccountContext.Provider>
  )
}
