import { CardProductsResponse, IssueCardBody } from '@domain/models'

export interface ICardsContext {
  getCardProducts: () => Promise<void>
  issueCard: (body: IssueCardBody) => Promise<void>
  cardsState: ICardsState
}

export interface ICardsState {
  cardProducts: CardProductsResponse
  loadingCardProducts: boolean
  submitLoading: boolean
}
export const CardsInitialState: ICardsState = {
  cardProducts: {
    card_products: [],
  },
  loadingCardProducts: false,
  submitLoading: false,
}
