import React, { createContext, useState } from 'react'
import { Statement, Statements } from '@domain/models'
import { useAccount, useAuth } from '@views/hooks'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { generateStatementPDF } from './pdfGeneration'
import moment from 'moment'
import { generateCSV } from './csvGeneration'
import { Platform } from 'react-native'
import { UnauthenticatedError } from '@microservices/errors/http'

export type filterStatementsFields = {
  statementMonth?: string
  statementYear?: string
}

interface IStatementsContext {
  allStatements: Statements
  filteredStatements: Statements
  getAllStatements: () => Promise<Statements> | void
  filterStatements: (data: filterStatementsFields) => Statements
  clearStatementFilters: () => Statements
  downloadStatement: (statementId: string, pdfSelected: boolean) => Promise<boolean>
  loadingStatements: boolean
  loadingOneStatement: boolean
}

const initialStatementsContext: IStatementsContext = {
  allStatements: [],
  filteredStatements: [],
  getAllStatements: () => {},
  filterStatements: () => [],
  clearStatementFilters: () => [],
  downloadStatement: async () => true,
  loadingStatements: false,
  loadingOneStatement: false,
}

export const StatementsContext = createContext<IStatementsContext>(initialStatementsContext)

interface Props {
  children: React.ReactNode
}

export function StatementsContextProvider({ children }: Props) {
  const { userAccount } = useAccount()
  const { logout } = useAuth()

  const bService = bankingService(bankingRepo)

  const [allStatements, setAllStatements] = useState<Statements>([])
  const [filteredStatements, setfilteredStatements] = useState<Statements>([])
  const [loadingStatements, setLoadingStatements] = useState(false)
  const [loadingOneStatement, setLoadingOneStatement] = useState(false)

  const getAllStatements = async () => {
    setLoadingStatements(true)
    try {
      const statements = await bService.getStatementsByAccountId(userAccount.id)
      if (!statements) throw new Error('Something went wrong')
      const allStatementsOrdered = [...statements.statements].reverse()
      setAllStatements(allStatementsOrdered)
      setfilteredStatements(allStatementsOrdered.slice(0, 12))
      setLoadingStatements(false)
      return allStatementsOrdered
    } catch (err) {
      if (err instanceof UnauthenticatedError) {
        logout()
        return []
      }
      return []
    } finally {
      setLoadingStatements(false)
    }
  }

  const getOneStatement = async (statement_id: string) => {
    try {
      const statement = await bService.getStatement(statement_id)
      return statement
    } catch (err) {
      if (err instanceof UnauthenticatedError) {
        logout()
        return
      }
      throw Error('Something went wrong')
    }
  }

  const filterStatements = ({ statementMonth, statementYear }: filterStatementsFields) => {
    const shouldFilterMonth = statementMonth && statementMonth !== '--'
    const shouldFilterYear = statementYear && statementYear !== '--'

    if (!shouldFilterMonth && !shouldFilterYear) {
      const topTweleve = allStatements.slice(0, 12)
      setfilteredStatements(topTweleve)
      return topTweleve
    }

    const newFilteredStatements = []

    if (shouldFilterMonth && shouldFilterYear) {
      for (const statement of allStatements) {
        const date = moment(statement.start_date)
        const month = (date.month() + 1).toString()
        const year = date.year().toString()
        if (year === statementYear && month === statementMonth) {
          newFilteredStatements.push(statement)
        }
        if (newFilteredStatements.length === 12) break
      }
      setfilteredStatements(newFilteredStatements)
      return newFilteredStatements
    }

    if (shouldFilterMonth) {
      for (const statement of allStatements) {
        const month = (moment(statement.start_date).month() + 1).toString()
        if (month === statementMonth) {
          newFilteredStatements.push(statement)
        }
        if (newFilteredStatements.length === 12) break
      }
      setfilteredStatements(newFilteredStatements)
      return newFilteredStatements
    }

    if (shouldFilterYear) {
      for (const statement of allStatements) {
        const year = moment(statement.start_date).year().toString()
        if (year === statementYear) {
          newFilteredStatements.push(statement)
        }
        if (newFilteredStatements.length === 12) break
      }
      setfilteredStatements(newFilteredStatements)
      return newFilteredStatements
    }

    return filteredStatements
  }

  const clearStatementFilters = () => {
    const clearedStatements = allStatements.slice(0, 12)
    setfilteredStatements(clearedStatements)
    return clearedStatements
  }

  const generatePDF = (statement: Statement) => {
    const {
      user: { state, address_line_1, city },
    } = userAccount
    generateStatementPDF(statement, { state, city, street: address_line_1 })
  }

  const downloadStatement = async (statementId: string, pdfSelected: boolean) => {
    if (Platform.OS !== 'web') return false

    setLoadingOneStatement(true)
    try {
      const statement = await getOneStatement(statementId)
      if (!statement) return false
      if (pdfSelected) {
        generatePDF(statement)
      } else {
        generateCSV(statement)
      }
      return true
    } catch (err) {
      return false
    } finally {
      setLoadingOneStatement(false)
    }
  }

  return (
    <StatementsContext.Provider
      value={{
        allStatements,
        filteredStatements,
        getAllStatements,
        filterStatements,
        clearStatementFilters,
        downloadStatement,
        loadingStatements,
        loadingOneStatement,
      }}>
      {children}
    </StatementsContext.Provider>
  )
}
