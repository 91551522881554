import { DISCLAIMERS_URL } from './disclaimersUrl'

export const DISCLAIMERS_LIST = [
  {
    title: `Account Terms and Conditions`,
    content: `${DISCLAIMERS_URL}/PH_T_AND_C.pdf`,
  },
  {
    title: `Cardholder Agreement`,
    content: `${DISCLAIMERS_URL}/Business_Cardholder_Agreement_JED.pdf`,
  },
  {
    title: `Privacy Notice`,
    content: `${DISCLAIMERS_URL}/PH_PP.pdf`,
  },
  {
    title: `ACH Authorization Disclosure`,
    content: `${DISCLAIMERS_URL}/ACH_Authorization%20_%20Agreement%20Disclosure.pdf`,
  },
  {
    title: `KYC (USA Patriot Act) Disclosure`,
    content: `${DISCLAIMERS_URL}/USA_Patriot_Act.pdf`,
  },
  {
    title: `Error Resolution`,
    content: `${DISCLAIMERS_URL}/Error_Resolution_Disclosure_JED.pdf`,
  },
  {
    title: `Reg CC`,
    content: `${DISCLAIMERS_URL}/Reg_CC_Disclosure_JED.pdf`,
  },
  {
    title: `Reg DD`,
    content: `${DISCLAIMERS_URL}/Reg_DD_Disclosure_JED.pdf`,
  },
  {
    title: `E-Sign Consent Disclosure`,
    content: `${DISCLAIMERS_URL}/E_Sign_Consent_Disclosure_JED.pdf`,
  },
]
