import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import {
  AppText,
  openModal,
  PermissionContainer,
  DetailsModal,
  CardWidget,
  LoadingSpinner,
} from '@views/components'
import { COLORS, UserTypesEnum } from '@assets/constants'
import activateCard from '@assets/images/activate-card-icon.png'
import createPayment from '@assets/images/create-payment-icon.png'
import issueCard from '@assets/images/issue-card-icon.png'
import makeDeposit from '@assets/images/make-deposit-icon.png'
import infoIcon from '@assets/images/exclamation-icon.png'
import AccountInformation from './AccountInformation'
import { currencyFormatter } from '@views/utils'
import IssueCard from '../WebVersion/IssueCard'
import { CreatePayment } from '@views/components/CreatePayment'
import { MakeDeposit } from '@views/components/MakeDeposit'
import { useAccount, useAuth, useMemberCards, useSystemConfig } from '@views/hooks'
import { bankingRepo } from '@microservices/instances'
import { bankingService } from '@domain/service'
import { CardsProvider } from '@views/contexts'
import { UnauthenticatedError } from '@microservices/errors/http'
import { NavigationProp, useNavigation } from '@react-navigation/native'

const BusinessAccountInfo: React.FC = () => {
  const [showDepositsTab, setShowDepositsTab] = useState(false)
  const { isSmallDevice } = useSystemConfig()
  const { user, logout } = useAuth()
  const { currentSelectedAccount } = useAccount()
  const { getMemberCards } = useMemberCards()
  const [infoIconHover, setInfoIconHover] = useState(false)
  const [createPaymentHover, setCreatePaymentHover] = useState(false)
  const [issueCardHover, setIssueCardHover] = useState(false)
  const [makeDepositHover, setMakeDepositHover] = useState(false)
  const [activateCardHover, setActivateCardHover] = useState(false)
  const navigation = useNavigation<NavigationProp<any>>()

  const openCreatePaymentModal = () => {
    const containerStyle = isSmallDevice
      ? { height: '90%', width: '90%', maxHeight: 670 }
      : { maxWidth: 670 }

    openModal({
      contentStyle: containerStyle,
      content: <CreatePayment />,
      dismiss: false,
    })
  }
  const openMakeDepositModal = () => {
    const containerStyle = isSmallDevice ? { width: '90%' } : { maxWidth: 670 }
    openModal({
      contentStyle: containerStyle,
      content: <MakeDeposit navigate={() => navigation.navigate('makeDepositCheck')} />,
      dismiss: false,
    })
  }

  const details = [
    {
      label: 'Account name',
      value: currentSelectedAccount.nickname,
    },
    {
      label: 'Account number',
      value: currentSelectedAccount.account_number,
      isMasked: true,
    },
    {
      label: 'Routing number',
      value: currentSelectedAccount.bank_routing,
    },
    {
      label: 'Account type',
      value: currentSelectedAccount.account_type,
    },
  ]

  const onAccountInfoClick = () => {
    openModal({
      dismiss: true,
      title: 'Account Details',
      content: <DetailsModal content={details} />,
    })
  }

  const handleActivateCard = async () => {
    if (!user?.id) return

    openModal({
      content: <LoadingSpinner />,
      dismiss: false,
    })
    try {
      const newCardWidgetURL = await bankingService(bankingRepo).getCardWidgetURL(
        user.id,
        'activate_card'
      )
      if (newCardWidgetURL?.url)
        openModal({
          content: <CardWidget url={newCardWidgetURL.url} />,
        })
      else if (newCardWidgetURL?.url === '')
        openModal({
          type: 'warning',
          title: 'No Cards Have Been Issued',
        })
      else
        openModal({
          type: 'warning',
          title: 'An Error Has Occured',
          subtitle: 'Please try again',
        })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.headerContainer}>
        <View style={styles.clubNameContainer}>
          <AppText style={styles.clubTeamName}>{currentSelectedAccount.nickname}</AppText>
          <TouchableOpacity
            {...{
              onMouseEnter: () => setInfoIconHover(true),
              onMouseLeave: () => setInfoIconHover(false),
            }}
            onPress={onAccountInfoClick}
            style={[
              styles.infoIconContainer,
              { backgroundColor: infoIconHover ? COLORS.PRIMARY : COLORS.GRAY_700 },
            ]}>
            <Image style={styles.infoIcon} source={infoIcon} />
          </TouchableOpacity>
        </View>
      </View>

      <AppText style={styles.balance}>
        {currencyFormatter(currentSelectedAccount.available_balance / 100)}
      </AppText>
      <AppText style={styles.availableBalance}>Available debit balance</AppText>

      <View style={styles.headerContainer}>
        <TouchableOpacity
          {...{
            onMouseEnter: () => setCreatePaymentHover(true),
            onMouseLeave: () => setCreatePaymentHover(false),
          }}
          style={styles.optionContainer}
          onPress={openCreatePaymentModal}>
          <View
            style={[
              { backgroundColor: createPaymentHover ? COLORS.CYAN_100 : COLORS.CYAN_200 },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={createPayment} />
          </View>
          <AppText style={styles.optionsTitles}>Create a</AppText>
          <AppText style={styles.optionsTitles}>payment</AppText>
        </TouchableOpacity>
        <PermissionContainer>
          <View style={styles.optionContainer}>
            <TouchableOpacity
              {...{
                onMouseEnter: () => setIssueCardHover(true),
                onMouseLeave: () => setIssueCardHover(false),
              }}
              onPress={() =>
                openModal({
                  dismiss: false,
                  content: (
                    <CardsProvider>
                      <IssueCard onCardIssued={getMemberCards} />
                    </CardsProvider>
                  ),
                })
              }
              style={[
                { backgroundColor: issueCardHover ? COLORS.PURPLE_DARK : COLORS.PURPLE_LIGHT },
                styles.optionsIconContainer,
              ]}>
              <Image style={styles.optionsIcon} source={issueCard} />
            </TouchableOpacity>
            <AppText style={styles.optionsTitles}>Issue</AppText>
            <AppText style={styles.optionsTitles}>card</AppText>
          </View>
        </PermissionContainer>

        <TouchableOpacity
          {...{
            onMouseEnter: () => setMakeDepositHover(true),
            onMouseLeave: () => setMakeDepositHover(false),
          }}
          onPress={openMakeDepositModal}
          style={styles.optionContainer}>
          <View
            style={[
              { backgroundColor: makeDepositHover ? COLORS.BLUE_200 : COLORS.BLUE_300 },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={makeDeposit} />
          </View>
          <AppText style={styles.optionsTitles}>Make a</AppText>
          <AppText style={styles.optionsTitles}>deposit</AppText>
        </TouchableOpacity>

        <TouchableOpacity
          {...{
            onMouseEnter: () => setActivateCardHover(true),
            onMouseLeave: () => setActivateCardHover(false),
          }}
          style={styles.optionContainer}
          onPress={handleActivateCard}>
          <View
            style={[
              { backgroundColor: activateCardHover ? COLORS.ORANGE_DARK : COLORS.ORANGE_LIGHT },
              styles.optionsIconContainer,
            ]}>
            <Image style={styles.optionsIcon} source={activateCard} />
          </View>
          <AppText style={styles.optionsTitles}>Activate</AppText>
          <AppText style={styles.optionsTitles}>card</AppText>
        </TouchableOpacity>
      </View>

      <View style={styles.tabsContainer}>
        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(false)
          }}>
          <View style={showDepositsTab ? styles.tabContainer : styles.tabContainerSelected}>
            <AppText
              style={[styles.tab, { color: !showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Member cards
            </AppText>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            setShowDepositsTab(true)
          }}>
          <View style={showDepositsTab ? styles.tabContainerSelected : styles.tabContainer}>
            <AppText
              style={[styles.tab, { color: showDepositsTab ? COLORS.PRIMARY : COLORS.GRAY_500 }]}>
              Deposits
            </AppText>
          </View>
        </TouchableOpacity>
      </View>

      <AccountInformation showDepositsTab={showDepositsTab} />
    </View>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    flexGrow: 1,
    padding: 16,
    backgroundColor: COLORS.WHITE,
  },
  headerContainer: {
    paddingBottom: 40,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  clubNameContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  clubTeamName: {
    fontSize: 24,
    fontWeight: '700',
    color: COLORS.GRAY_700,
  },
  infoIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    height: 20,
    width: 20,
    borderRadius: 4,
  },
  infoIcon: {
    resizeMode: 'contain',
    height: 10,
    width: 10,
  },
  teamMainInfo: {
    flexDirection: 'column',
  },
  ownerName: {
    fontSize: 14,
    fontWeight: '600',
  },
  balance: {
    fontWeight: '700',
    fontSize: 22,
    marginBottom: 10,
  },
  availableBalance: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 30,
  },
  optionsIconContainer: {
    borderRadius: 10,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsIcon: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  optionsTitles: {
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'center',
    paddingTop: 5,
  },
  tabsContainer: {
    paddingVertical: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tabContainer: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
  },
  tabContainerSelected: {
    paddingBottom: 2,
    marginVertical: 10,
    marginRight: 20,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.PRIMARY,
  },
  tab: {
    fontSize: 16,
    fontWeight: '600',
  },
})

export default BusinessAccountInfo
