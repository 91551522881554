export const formatBOFormData = (info: any, num: number) => {
  let legal_address = null
  if (info[`addrStreet1BO${num}`])
    legal_address = {
      address_line_1: info[`addrStreet1BO${num}`],
      address_line_2: info[`addrStreet2BO${num}`],
      city: info[`addrCityBO${num}`],
      state: info[`addrStateBO${num}`],
      postal_code: info[`addrZipBO${num}`],
    }

  return {
    ...(info[`firstNameBO${num}`] && { first_name: info[`firstNameBO${num}`] }),
    ...(info[`lastNameBO${num}`] && { last_name: info[`lastNameBO${num}`] }),
    ...(info[`phoneBO${num}`] && { phone: info[`phoneBO${num}`] }),
    email: info[`emailBO${num}`],
    ...(info[`percentageOwnershipBO${num}`] && {
      percent_ownership: parseInt(info[`percentageOwnershipBO${num}`].replace(/\D/g, ''), 10),
    }),
    ...(info[`ssnBO${num}`] && { ssn: info[`ssnBO${num}`] }),
    ...(info[`dobBO${num}`] && { dob: info[`dobBO${num}`] }),
    ...(legal_address && { legal_address }),
  }
}
