import { BankingAccount, ExternalAccount, PlaidLinkToken } from '@domain/models'
import { SendEmailRequestDto } from '@microservices/dto'
import {
  IAdminInfo,
  IBasicInfo,
  IBeneficialOwnerEmails,
  IBeneficialOwnersInfo,
  ISportInfo,
} from '@views/schemas'

export interface ICreateAccountState {
  loading: boolean
  error: string
  data: IStateData
}

export interface IRelationshipInfo {
  type: string
  user: {
    id: string
    percentage_ownership: number | null
  }
}

export interface IStateData {
  basicInfo?: IBasicInfo
  adminInfo?: IAdminInfo
  beneficialOwnersInfo?: IBeneficialOwnersInfo[]
  sportInfo?: ISportInfo
  plaidToken?: PlaidLinkToken
  bankAccount?: BankingAccount
  externalAccount?: ExternalAccount
}

export const createAccountInitialState: ICreateAccountState = {
  loading: false,
  error: '',
  data: {
    basicInfo: undefined,
    adminInfo: undefined,
    beneficialOwnersInfo: undefined,
    sportInfo: undefined,
    plaidToken: {
      link_token: '',
      expiration: '',
      request_id: '',
    },
    bankAccount: undefined,
    externalAccount: undefined,
  },
}

export type ExternalAccountData = {
  public_token: string
  accounts: string[]
  institution: any
}

export interface ICreateAccountContext {
  createAccountState: ICreateAccountState
  postBasicInfo: (postData: IBasicInfo) => Promise<void>
  getBasicInfo: () => Promise<IBasicInfo>
  patchBasicInfo: (patchData: Partial<IBasicInfo>) => Promise<void>
  postAdminInfo: (postData: IAdminInfo, business_id?: string) => Promise<void>
  getAdminInfo: () => Promise<IAdminInfo>
  patchAdminInfo: (postData: Partial<IAdminInfo>) => Promise<void>
  postOwnerInfo: (postData: any, businessId?: string, admin?: IAdminInfo) => Promise<void>
  getOwnerInfo: () => Promise<void>
  patchOwnerInfo: (postData: Partial<IBeneficialOwnersInfo>) => Promise<void>
  postOwnerEmails: (postData: SendEmailRequestDto) => Promise<void>
  postSportInfo: (postData: ISportInfo, business_id?: string) => Promise<void>
  patchSportInfo: (postData: Partial<ISportInfo>, business_id?: string) => Promise<void>
  createPlaidLinkToken: () => Promise<PlaidLinkToken>
  exchangePublicToken: (data: ExternalAccountData) => Promise<void>
  createBankAccount: () => Promise<void>
  createBusinessVerification: () => Promise<{ kyc_status: string; kyb_status: string } | undefined>
}
