export const createRDCOptions = async (amount: string, uri1: any, uri2: any) => {
  const formData = new FormData()
  formData.append('amount', amount)
  formData.append('description', 'check_image')
  const [info] = uri1.split(',')
  const match = /:([^;]*)/.exec(info)
  const type = match ? `${match[1]}` : `image`
  const extension = type.split('/').pop()
  formData.append('mimetype', type)
  if (extension) formData.append('extension', extension)

  formData.append('file', uri1)
  formData.append('file2', uri2)
  return {
    body: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  }
}
