import { IssueCardBody } from '@domain/models/Card'
import { UnauthenticatedError } from '@microservices/errors/http'
import { useAuth } from '@views/hooks'
import React, { createContext, FC, ReactNode, useCallback, useReducer } from 'react'
import { ActionType, getCardProductsActiion, submitIssueCardAction } from './actions'

import { ICardsContext, CardsInitialState } from './interfaces'
import { CardsReducer } from './reducer'

interface ICardsContextProps {
  children: ReactNode
}

export const CardsContext = createContext<ICardsContext>({} as ICardsContext)

export const CardsProvider: FC<ICardsContextProps> = ({ children }) => {
  const [cardsState, dispatch] = useReducer(CardsReducer, CardsInitialState)
  const { logout } = useAuth()

  const setCardProducts = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.SET_CARD_PRODUCTS,
        payload,
      })
    },
    [dispatch]
  )

  const setCardProductsLoading = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.SET_CARD_PRODUCTS_LOADING,
        payload,
      })
    },
    [dispatch]
  )
  const setSubmitLoading = useCallback(
    (payload) => {
      dispatch({
        type: ActionType.SET_SUBMIT_LOADING,
        payload,
      })
    },
    [dispatch]
  )

  const getCardProducts = useCallback(async (): Promise<void> => {
    setCardProductsLoading(true)
    try {
      await getCardProductsActiion(setCardProducts)
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
    setCardProductsLoading(false)
  }, [setCardProducts, setCardProductsLoading])

  const issueCard = useCallback(
    async (body: IssueCardBody): Promise<void> => {
      try {
        setSubmitLoading(true)
        await submitIssueCardAction(body)
        setSubmitLoading(false)
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setSubmitLoading(false)
      }
    },
    [setSubmitLoading]
  )

  const value = {
    cardsState,
    getCardProducts,
    issueCard,
  }
  return <CardsContext.Provider value={value}>{children}</CardsContext.Provider>
}
