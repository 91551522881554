import React, { useState } from 'react'
import {
  openModal,
  DetailsModal,
  AppText,
  DropdownPopover,
  PermissionContainer,
  Hoverable,
} from '@views/components'
import { COLORS } from '@assets/constants'
import {
  MaterialCommunityIcons,
  FontAwesome5,
  SimpleLineIcons,
  AntDesign,
} from '@expo/vector-icons'
import { lockCardInteractionsTypes } from '@views/contexts'
import { currencyFormatter, formatDate, toTitleString } from '@views/utils'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { useAccount, useAuth, useMemberCards } from '@views/hooks'
import { MemberCard } from '@domain/models'
import { bankingService } from '@domain/service'
import { bankingRepo } from '@microservices/instances'
import { UnauthenticatedError } from '@microservices/errors/http'

type Props = {
  card: MemberCard
  index: number
  indexShowing: number
  setIndexShowing: React.Dispatch<React.SetStateAction<number>>
}

export const MemberCardWebVerion: React.FC<Props> = ({
  card,
  index,
  indexShowing,
  setIndexShowing,
}) => {
  const { logout } = useAuth()
  const { onLockCardInteractions, marqetaBootstrap } = useMemberCards()
  const cardHolderName = `${card.account_info.user.first_name} ${card.account_info.user.last_name}`

  const onFailure = () => {
    openModal({
      dismiss: true,
      type: 'error',
      title: 'An Error Has Occured',
      subtitle: 'Please try again',
    })
  }

  const onOverviewClick = async () => {
    openModal({
      dismiss: true,
      isInlineTitle: true,
      title: 'Card Details',
      content: (
        <View nativeID="card-details-container">
          <DetailsModal
            content={
              card.form === 'VIRTUAL'
                ? [
                    {
                      label: 'Cardholder name',
                      value: cardHolderName,
                    },
                    { label: 'Card number', value: '', id: 'display-card-pan' },
                    { label: 'CVV', value: '', id: 'display-card-cvv' },
                    { label: 'Expiration date', value: '', id: 'display-card-exp' },
                    {
                      label: 'Cardholder role',
                      value: card.account_info.is_subaccount ? 'Team Admin' : 'Org Admin',
                    },
                    { label: 'Date created', value: formatDate(card.creation_time, 'MM/DD/YY') },
                    { label: 'Status', value: toTitleString(card.card_status) },
                  ]
                : [
                    {
                      label: 'Cardholder name',
                      value: cardHolderName,
                    },
                    { label: 'Card number', value: card.last_four },
                    {
                      label: 'Cardholder role',
                      value: card.account_info.is_subaccount ? 'Team Admin' : 'Org Admin',
                    },
                    { label: 'Date created', value: formatDate(card.creation_time, 'MM/DD/YY') },
                    { label: 'Status', value: toTitleString(card.card_status) },
                  ]
            }
          />
        </View>
      ),
    })
    if (card.form === 'VIRTUAL') {
      window.marqeta.destroy()
      try {
        const tokenRes = await bankingService(bankingRepo).getCardClientToken(card.id)

        if (!tokenRes) {
          onFailure()
          return
        }

        marqetaBootstrap(tokenRes.client_token, onFailure)
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
      }
    }
  }

  const hanldeLockInteraction = (type: lockCardInteractionsTypes) => {
    onLockCardInteractions({
      type,
      cardId: card.id,
      userId: card.account_info.user_id,
      currentCardStatus: card.card_status,
    })
  }

  return (
    <View style={[styles.clubInfoRow, index !== indexShowing && styles.negativeZIndex]}>
      <AppText style={styles.cardMember}>{cardHolderName}</AppText>
      <AppText style={styles.memberStatus}>{toTitleString(card.form)}</AppText>
      <AppText style={styles.memberStatus}>{toTitleString(card.card_status)}</AppText>
      <View style={styles.amountDetailsContainer}>
        <AppText style={styles.cardAmount}>
          {currencyFormatter(card.account_info.available_balance / 100)}
        </AppText>
        <DropdownPopover
          index={index}
          indexShowing={indexShowing}
          setIndexShowing={setIndexShowing}
          from={<MaterialCommunityIcons name="dots-vertical" size={20} color={COLORS.GRAY_700} />}>
          <View style={styles.popoverContainer}>
            <Hoverable>
              {(isHovered) => (
                <TouchableOpacity style={styles.popoverOptions} onPress={onOverviewClick}>
                  <FontAwesome5
                    name="list-alt"
                    size={16}
                    color={isHovered ? COLORS.PRIMARY : COLORS.GRAY_700}
                  />
                  <AppText style={[styles.popoverOptionsText, isHovered && styles.textHover]}>
                    View details
                  </AppText>
                </TouchableOpacity>
              )}
            </Hoverable>

            <PermissionContainer>
              <>
                {card.card_status === 'ACTIVE' && (
                  <Hoverable>
                    {(isHovered) => (
                      <TouchableOpacity
                        style={styles.popoverOptions}
                        onPress={() => hanldeLockInteraction(lockCardInteractionsTypes.LOCK)}>
                        <SimpleLineIcons
                          name="lock"
                          size={16}
                          color={isHovered ? COLORS.PRIMARY : COLORS.GRAY_700}
                        />
                        <AppText style={[styles.popoverOptionsText, isHovered && styles.textHover]}>
                          Lock card
                        </AppText>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                )}
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={styles.popoverOptions}
                      onPress={() => hanldeLockInteraction(lockCardInteractionsTypes.SUSPEND)}>
                      <AntDesign
                        name="closesquare"
                        size={16}
                        color={isHovered ? COLORS.PRIMARY : COLORS.GRAY_700}
                      />
                      <AppText style={[styles.popoverOptionsText, isHovered && styles.textHover]}>
                        Suspend member
                      </AppText>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </>
            </PermissionContainer>
          </View>
        </DropdownPopover>
      </View>
    </View>
  )
}

export const MemberCardsWebVersion: React.FC = () => {
  const { memberCards } = useMemberCards()
  const { currentSelectedAccount, userAccount } = useAccount()
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const isOrgAccountSelected = currentSelectedAccount.id === userAccount.id

  const renderCardsByCurrentAccount = () => {
    const cards = memberCards.filter((card) => card.account_info.id === currentSelectedAccount.id)
    return cards.map((card, i) => (
      <MemberCardWebVerion
        card={card}
        key={card.id}
        index={i}
        indexShowing={selectedIndex}
        setIndexShowing={setSelectedIndex}
      />
    ))
  }

  const renderCards = () => {
    return memberCards.map((card, i) => (
      <MemberCardWebVerion
        card={card}
        key={card.id}
        index={i}
        indexShowing={selectedIndex}
        setIndexShowing={setSelectedIndex}
      />
    ))
  }
  return (
    <View style={styles.clubTeamInfocontainer}>
      <View style={styles.clubInfoHeader}>
        <AppText style={[styles.tabHeader]}>Cardholder Name</AppText>
        <AppText style={[styles.tabHeader]}>Card type</AppText>
        <AppText style={[styles.tabHeader]}>Status</AppText>
        <AppText style={[styles.tabHeader]}>Amount</AppText>
        <View />
      </View>
      {isOrgAccountSelected ? renderCards() : renderCardsByCurrentAccount()}
    </View>
  )
}

const styles = StyleSheet.create({
  clubTeamInfocontainer: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
  },
  clubInfoHeader: {
    paddingTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  amountDetailsContainer: {
    width: '25%',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabHeader: {
    fontSize: 12,
    fontWeight: '500',
    width: '100%',
  },
  cardMember: {
    fontSize: 14,
    fontWeight: '600',
    width: '100%',
  },
  memberStatus: {
    fontWeight: '300',
    fontSize: 14,
    width: '100%',
  },
  cardAmount: {
    fontSize: 14,
    fontWeight: '700',
    width: '100%',
  },
  clubInfoRow: {
    paddingVertical: 24,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderBottomWidth: 3,
    borderBottomColor: COLORS.GRAY_200,
    justifyContent: 'space-between',
  },
  popoverContainer: {
    backgroundColor: COLORS.WHITE,
    width: 180,
    paddingHorizontal: 12,
    alignItems: 'flex-start',
    paddingVertical: 12,
    borderRadius: 8,
    gap: 8,
  },
  popoverOptions: {
    display: 'flex',
    flexDirection: 'row',
  },
  popoverOptionsText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
  modalButton: {
    width: 400,
    marginTop: 8,
  },
  cancelButton: {
    marginTop: 16,
    borderWidth: 0,
  },
  negativeZIndex: {
    zIndex: -1,
  },
  textHover: {
    color: COLORS.PRIMARY,
  },
})
