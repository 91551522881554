import { StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Row, RNPicker, Button, IInputItems, AppText } from '@views/components'
import { Controller, useForm } from 'react-hook-form'
import { filterStatementsFields, statementsFilterSchema } from '@views/schemas'
import { useStatements } from '@views/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { TouchableOpacity } from 'react-native-gesture-handler'

export const StatementsFilterForm: React.FC = () => {
  const [years, setYears] = useState<IInputItems[]>([])

  const { allStatements, loadingStatements, filterStatements, clearStatementFilters } =
    useStatements()

  const setUpYears = () => {
    const yearMap = new Map()
    const yearsWithoutDups: IInputItems[] = []

    allStatements.forEach(({ start_date }) => {
      const year = start_date.slice(0, 4)
      if (!yearMap.has(year)) {
        yearMap.set(year, true)
        yearsWithoutDups.push({ label: year, value: year })
      }
    })
    setYears(yearsWithoutDups)
  }

  useEffect(() => {
    setUpYears()
  }, [allStatements])

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(statementsFilterSchema),
  })

  const clearFilters = () => {
    setValue('statementMonth', '')
    setValue('statementYear', '')
    clearStatementFilters()
  }

  return (
    <>
      <AppText>Filter Statements</AppText>

      <Row style={styles.filterForm}>
        <Controller
          name={filterStatementsFields.statementMonth.name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RNPicker
              onChange={onChange}
              name={filterStatementsFields.statementMonth.name}
              value={value}
              placeholder={`${filterStatementsFields.statementMonth.placeholder}`}
              items={filterStatementsFields.statementMonth.items || []}
              enabled={true}
              pickerStyle={styles.statementMonth}
            />
          )}
        />
        <Controller
          name={filterStatementsFields.statementYear.name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RNPicker
              onChange={onChange}
              name={filterStatementsFields.statementYear.name}
              value={value}
              placeholder={`${filterStatementsFields.statementYear.placeholder}`}
              items={years || []}
              enabled={true}
              pickerStyle={styles.statementYear}
            />
          )}
        />
        <Button
          style={styles.buttonStyle}
          textStyle={styles.buttonTextStyle}
          onPress={handleSubmit(filterStatements)}
          text={'Filter Statements'}
          disabled={loadingStatements}
        />
        <TouchableOpacity style={styles.clearFilter}>
          <AppText light style={styles.clearFilterText} onPress={clearFilters}>
            Clear Filters
          </AppText>
        </TouchableOpacity>
      </Row>
    </>
  )
}

const styles = StyleSheet.create({
  filterForm: {
    marginTop: 8,
    marginBottom: 30,
  },
  statementMonth: {
    width: 279,
    height: 44,
  },

  statementYear: {
    width: 156,
    height: 44,
    marginLeft: 16,
    marginRight: 20,
  },
  buttonStyle: {
    width: 180,
    height: 44,
    marginRight: 24,
  },
  buttonTextStyle: {
    fontSize: 12,
  },
  clearFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  clearFilterText: {
    textDecorationLine: 'underline',
  },
})
