import { COLORS } from '@assets/constants'
import step1of2Img from '@assets/images/step-1-of-2.png'
import step2of2Img from '@assets/images/step-2-of-2.png'
import successImg from '@assets/images/sucess-check.png'
import warningImg from '@assets/images/warning.png'
import { IssueCardBody } from '@domain/models'
import { AntDesign } from '@expo/vector-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppText, Button, closeModal, Form, FormInput, RenderRow } from '@views/components'
import { useAccount, useSystemConfig } from '@views/hooks'
import { useCards } from '@views/hooks/useCards'
import { issueCardFields, IssueCardSchema } from '@views/schemas'
import React, { useEffect, useState } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

enum FORM_STEPS {
  CARD_TYPE /* equal to 0 */,
  CARD_DETAILS /* equal to 1 */,
  CARD_DETAILS_PHYSICAL /* equal to 2 */,
  RECIPIENT_DETAILS /* etc */,
  SUCCESS,
  SUCCESS_PHYSICAL,
  DATA_NOT_SAVED,
}

interface Props {
  onCardIssued: () => void
}

const IssueCard: React.FC<Props> = ({ onCardIssued }) => {
  const { isSmallDevice } = useSystemConfig()
  const { orgAccount } = useAccount()
  const [displayStatus, setDisplayStatus] = useState(FORM_STEPS.CARD_TYPE)
  const [multipleStepsDisplay, setMultipleStepsDisplay] = useState(false)
  const { getCardProducts, cardsState, issueCard } = useCards()
  const { subAccounts, userAccount } = useAccount()
  useEffect(() => {
    getCardProducts()
  }, [getCardProducts])
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(IssueCardSchema[displayStatus]),
  })

  const handleXClick = () => {
    if (displayStatus === FORM_STEPS.SUCCESS) return closeModal()

    return setDisplayStatus(FORM_STEPS.DATA_NOT_SAVED)
  }

  const onSubmit = async (info: any) => {
    switch (displayStatus) {
      case FORM_STEPS.CARD_TYPE:
        if (info.selectCardType.indexOf('_PHYSICAL') > -1) {
          setMultipleStepsDisplay(true)
          return setDisplayStatus(FORM_STEPS.CARD_DETAILS_PHYSICAL)
        }
        if (info.selectCardType.indexOf('_VIRTUAL') > -1) {
          setMultipleStepsDisplay(false)
          return setDisplayStatus(FORM_STEPS.CARD_DETAILS)
        }
        break
      case FORM_STEPS.CARD_DETAILS:
        const virtualBody: IssueCardBody = {
          account_id: info.recipientName,
          card_product_id: info.selectCardType?.split('_VIRTUAL')?.[0],
          form: 'VIRTUAL',
          type: 'DEBIT',
        }
        await issueCard(virtualBody)
        onCardIssued()
        return setDisplayStatus(FORM_STEPS.SUCCESS)

      case FORM_STEPS.CARD_DETAILS_PHYSICAL:
        const selectedAccount = [userAccount, ...subAccounts].find(
          (acc) => acc.id === info.recipientName
        )
        if (!selectedAccount) return
        setValue('first_name', selectedAccount.user.first_name)
        setValue('last_name', selectedAccount.user.last_name)
        return setDisplayStatus(FORM_STEPS.RECIPIENT_DETAILS)

      case FORM_STEPS.RECIPIENT_DETAILS:
        const physicalBody: IssueCardBody = {
          account_id: info.recipientName,
          card_product_id: info.selectCardType?.split('_PHYSICAL')?.[0],
          form: 'PHYSICAL',
          type: 'DEBIT',
          shipping: {
            address: {
              address_line_1: info.address,
              city: info.city,
              country_code: 'US',
              postal_code: '00000',
              state: info.state,
            },
          },
        }
        await issueCard(physicalBody)
        onCardIssued()
        return setDisplayStatus(FORM_STEPS.SUCCESS_PHYSICAL)

      default:
        return null
    }
  }

  const selectCardTypeError = errors.selectCardType as unknown as FieldError
  const recipientNameError = errors.recipientName as unknown as FieldError
  const phoneError = errors.phone as unknown as FieldError

  const renderStep = () => {
    switch (displayStatus) {
      case FORM_STEPS.CARD_TYPE:
        return (
          <>
            {cardsState.loadingCardProducts ? (
              <ActivityIndicator />
            ) : (
              <>
                <FormInput
                  {...issueCardFields.selectCardType}
                  items={cardsState?.cardProducts?.card_products?.map((item) => ({
                    value: item.id + `_${item.form}`,
                    label: item.name,
                  }))}
                  control={control}
                  error={selectCardTypeError}
                />
                <Button onPress={handleSubmit(onSubmit)} text="Continue" />
              </>
            )}
          </>
        )
      case FORM_STEPS.CARD_DETAILS:
        return (
          <>
            <FormInput
              disabled={true}
              {...issueCardFields.orgName}
              placeholder={orgAccount.nickname}
              control={control}
              error={selectCardTypeError}
            />
            <FormInput
              {...issueCardFields.recipientName}
              items={[userAccount, ...subAccounts].map((item) => {
                return {
                  label: `${item.user.first_name} ${item.user.last_name}`,
                  value: item.id,
                }
              })}
              control={control}
              error={recipientNameError}
            />
            <Button onPress={handleSubmit(onSubmit)} text="Issue Card" />
          </>
        )
      case FORM_STEPS.CARD_DETAILS_PHYSICAL:
        return (
          <>
            <View style={styles.stepWrapper}>
              <Image source={step1of2Img} style={styles.stepImage} />
              <AppText style={styles.subtitle}>Step 1/2</AppText>
            </View>

            <FormInput
              disabled={true}
              {...issueCardFields.orgName}
              placeholder={orgAccount.nickname}
              control={control}
              error={selectCardTypeError}
            />
            <FormInput
              {...issueCardFields.recipientName}
              control={control}
              error={recipientNameError}
              items={[userAccount, ...subAccounts].map((item) => {
                return {
                  label: `${item.user.first_name} ${item.user.last_name}`,
                  value: item.id,
                }
              })}
            />
            <Button onPress={handleSubmit(onSubmit)} text="Continue to Recipient Details" />
          </>
        )
      case FORM_STEPS.RECIPIENT_DETAILS:
        return (
          <>
            {isSmallDevice ? (
              <View style={styles.subtitleWrapperMobile}>
                <View style={styles.stepWrapperMobile}>
                  <Image source={step2of2Img} style={styles.stepImage} />
                  <AppText style={styles.subtitle}>Step 2/2</AppText>
                </View>
                <AppText style={styles.subtitle}>Recipient Details</AppText>
              </View>
            ) : (
              <View style={styles.subtitleWrapper}>
                <AppText style={styles.subtitle}>Recipient Details</AppText>
                <View style={styles.stepWrapper}>
                  <Image source={step2of2Img} style={styles.stepImage} />
                  <AppText style={styles.subtitle}>Step 2/2</AppText>
                </View>
              </View>
            )}
            <View style={{ maxWidth: isSmallDevice ? 281 : 400 }}>
              <RenderRow
                fields={[issueCardFields.first_name, issueCardFields.last_name]}
                errors={errors}
                control={control}
                disabled={[issueCardFields.first_name, issueCardFields.last_name]}
              />
              <RenderRow
                fields={[issueCardFields.zip_code, issueCardFields.city]}
                errors={errors}
                control={control}
              />
              <RenderRow
                fields={[issueCardFields.address, issueCardFields.state]}
                errors={errors}
                control={control}
              />
            </View>
            <FormInput {...issueCardFields.phone} control={control} error={phoneError} />
            <Button onPress={handleSubmit(onSubmit)} text="Issue Card" />
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => {
                if (multipleStepsDisplay) setDisplayStatus(FORM_STEPS.CARD_DETAILS_PHYSICAL)
                else setDisplayStatus(FORM_STEPS.CARD_DETAILS)
              }}>
              <AppText>Back to Card Details</AppText>
            </TouchableOpacity>
          </>
        )
      default:
        return null /* For the success and error cases, in which the header are different */
    }
  }
  return (
    <View style={styles.container}>
      {cardsState.submitLoading ? (
        <>
          <ActivityIndicator />
        </>
      ) : displayStatus === FORM_STEPS.SUCCESS ? (
        <>
          {cardsState.submitLoading ? (
            <ActivityIndicator />
          ) : (
            <View style={styles.messageWrapper}>
              <Image source={successImg} style={styles.image} />
              <AppText style={styles.successTitle}>Success</AppText>
              <AppText style={styles.successSubtitle}>Card has been successfully issued.</AppText>
              <Button onPress={() => closeModal()} text="Back to Business Account" />
            </View>
          )}
        </>
      ) : displayStatus === FORM_STEPS.SUCCESS_PHYSICAL ? (
        <>
          {cardsState.submitLoading ? (
            <ActivityIndicator />
          ) : (
            <View style={styles.messageWrapper}>
              <Image source={successImg} style={styles.image} />
              <AppText style={styles.successTitle}>Success</AppText>
              <AppText style={styles.successSubtitle}>
                Card has been successfully issued. The card can take up to 7 business days to ship
              </AppText>
              <Button onPress={() => closeModal()} text="Back to Business Account" />
            </View>
          )}
        </>
      ) : displayStatus === FORM_STEPS.DATA_NOT_SAVED ? (
        <View style={styles.messageWrapper}>
          <Image source={warningImg} style={styles.image} />
          <AppText style={styles.successTitle}>The Entered Data Was Not Saved</AppText>
          <AppText style={styles.successSubtitle}>
            Are you sure you want to close and go back to your Business Account? You can lose your
            data.
          </AppText>
          <Button
            onPress={() => {
              if (multipleStepsDisplay) setDisplayStatus(FORM_STEPS.CARD_DETAILS_PHYSICAL)
              else setDisplayStatus(FORM_STEPS.CARD_DETAILS)
            }}
            text="Continue Issuing"
          />
          <TouchableOpacity style={styles.backButton} onPress={() => closeModal()}>
            <AppText>Back to Business Account</AppText>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <View style={isSmallDevice ? styles.headerMobile : styles.header}>
            <AppText style={styles.title}>Issue Card</AppText>
            <AntDesign
              name="closesquare"
              size={24}
              color={COLORS.GRAY_700}
              onPress={() => handleXClick()}
            />
          </View>
          <Form>{renderStep()}</Form>
        </>
      )}
    </View>
  )
}

export default IssueCard

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: { fontStyle: 'normal', fontWeight: '700', fontSize: 24 },
  subtitle: { fontStyle: 'normal', fontWeight: '600', fontSize: 16 },
  subtitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 281,
  },
  subtitleWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  successTitle: { fontStyle: 'normal', fontWeight: '700', fontSize: 24, marginBottom: 10 },
  successSubtitle: { marginBottom: 32, paddingHorizontal: 5 },
  image: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 24,
    alignSelf: 'center',
  },
  stepWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  stepWrapperMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginBottom: 20,
  },
  stepImage: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginRight: 8,
  },
  backButton: {
    marginTop: 28,
    textAlign: 'center',
  },
})
