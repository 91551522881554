import React from 'react'
import { openModal } from '@views/components'
import { createContext, ReactNode, useState } from 'react'
import { CameraPermissionStatus } from '@views/utils'

interface CameraPermissionContext {
  cameraPermission: CameraPermissionStatus
  changeCameraPermission: (permssion: CameraPermissionStatus) => void
  askCameraPermission: () => Promise<CameraPermissionStatus>
}

interface Props {
  children: ReactNode
}

export const CameraPermissionContext = createContext<CameraPermissionContext>({
  cameraPermission: CameraPermissionStatus.UNDETERMINED,
  changeCameraPermission: () => {},
  askCameraPermission: () => CameraPermissionStatus.UNDETERMINED as any,
})

export function CameraPermissionContextProvider({ children }: Props) {
  const [cameraPermission, setCameraPermission] = useState<CameraPermissionStatus>(
    CameraPermissionStatus.UNDETERMINED
  )

  const askPermisison = () =>
    new Promise<CameraPermissionStatus>((res) =>
      openModal({
        dismiss: false,
        title: 'Allow Camera Permission?',
        confirmationButtonMessage: 'Allow',
        cancelButtonMessage: 'Deny',
        onConfirmPressed: () => {
          res(CameraPermissionStatus.GRANTED)
        },
        onDismiss: () => {
          res(CameraPermissionStatus.DENIED)
        },
      })
    )

  const askCameraPermission = async () => {
    const permission = await askPermisison()
    setCameraPermission(permission)
    return permission
  }

  const changeCameraPermission = (newPermission: CameraPermissionStatus) => {
    setCameraPermission(newPermission)
  }

  return (
    <CameraPermissionContext.Provider
      value={{
        cameraPermission,
        changeCameraPermission,
        askCameraPermission,
      }}>
      {children}
    </CameraPermissionContext.Provider>
  )
}
