import { Statement, StatementTransaction } from '@domain/models'
import { mapSubtypes } from '@views/helpers'
import { centsToDollar, formatDate, formatStatementPeriod, toTitleString } from '@views/utils'

const download = async (data: string, fileName: string) => {
  // @ts-ignore
  const blob = new Blob([data], { type: 'text/csv', lastModified: 1 })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', `${fileName}.csv`)
  a.click()
}

const createTransaction = (transaction: StatementTransaction) => {
  const amount = `$${centsToDollar(transaction.data.lines[0].amount).toFixed(2)}`
  const dcSign = toTitleString(transaction.data.dc_sign)
  const date = formatDate(transaction.posted_date, 'MM/DD/YYYY')
  const description = mapSubtypes(transaction.subtype)
  return `${
    transaction.data.dc_sign === 'debit' ? '-' : ''
  }${amount},${dcSign},${date},${description}`
}

const createDates = (statement: Statement) => {
  const period = formatStatementPeriod(statement.start_date).replace(',', '')
  const startDate = formatDate(statement.start_date, 'MM/DD/YYYY')
  const endDate = formatDate(statement.end_date, 'MM/DD/YYYY')
  return `${period},${startDate},${endDate}`
}

const createSummary = (statement: Statement) => {
  const accNum = statement.account_summary.account_number
  const accType = statement.account_summary.account_type
  const currency = statement.account_summary.currency
  const nickname = statement.account_summary.nickname
  return `${accNum},${accType},${currency},${nickname}`
}

const createCSVString = (statement: Statement) => {
  const csv = [
    'Amount,Debit/Credit,Date,Description, ,Account Statement,Start Date,End Date',
    ...statement.transactions.map(createTransaction),
  ]

  // Add commas if low/no transactions
  for (let i = 1; i < 6; i++) {
    if (!csv[i]) {
      csv.push(' , , ,')
    }
  }

  csv[1] = csv[1] + ` , ,${createDates(statement)}`
  csv[3] = csv[3] + ` , ,Account Number,Account Type,Currency,Nickname`
  csv[4] = csv[4] + ` , ,${createSummary(statement)}`
  return csv.join('\n')
}

export const generateCSV = (statement: Statement) => {
  const csv = createCSVString(statement)
  download(csv, formatStatementPeriod(statement.start_date))
}
