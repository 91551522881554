import React, { useEffect } from 'react'
import { useAuth } from '@views/hooks'
import { AppText } from '@views/components'
import { StyleSheet } from 'react-native'
import { LocalStorage } from '@microservices/instances'

const localStorage = new LocalStorage()

const SignupWithRedirect = ({ route }: any) => {
  const { login } = useAuth()
  useEffect(() => {
    (async () => {

      await localStorage.setItem('business_id', route.params.business_id)
      await localStorage.setItem('subaccount_name', route.params.subaccount_name)

      await login({
        action: 'signup',
        redirectUri: `${route.params.redirect_uri}`,
      })
      return
    })()
    return
  })
  return <AppText style={styles.paddedText}>Redirecting...</AppText>
}

export default SignupWithRedirect

const styles = StyleSheet.create({
  paddedText: {
    padding: 20,
  },
})
