import { UserTypesEnum } from '@assets/constants'
import { useAuth } from '@views/hooks'
import React from 'react'

interface Props {
  children: JSX.Element
}

export const PermissionContainer: React.FC<Props> = ({ children }) => {
  const { user } = useAuth()
  const isSubAdmin = user?.type === UserTypesEnum.SUB_ADMIN
  if (isSubAdmin) return null
  return children
}
