import React from 'react'
import { bankingService, userService } from '@domain/service'
import { bankingRepo, userRepo } from '@microservices/instances'
import { Button, closeModal, openModal } from '@views/components'
import { createContext, ReactNode, useState } from 'react'
import { getErrorMessage } from '@views/helpers'
import { CardStatuses, CardStatusReasonEnum, MemberCard } from '@domain/models'
import { useAccount, useAuth } from '@views/hooks'
import { StyleSheet } from 'react-native'
import { GetMemberCardsDTO, PersonStatusesEnum } from '@microservices/dto'
import { COLORS, FONT_TYPES, UserTypesEnum } from '@assets/constants'
import { UnauthenticatedError } from '@microservices/errors/http'

export enum lockCardInteractionsTypes {
  LOCK = 'lock',
  SUSPEND = 'suspend',
}

export type onLockCardInteractionsParams = {
  type: lockCardInteractionsTypes
  userId: string
  cardId: string
  currentCardStatus: CardStatuses
}

interface IMemberCardsContext {
  memberCards: MemberCard[]
  getMemberCards: () => Promise<void>
  loadingMemberCards: boolean
  onLockCardInteractions: (params: onLockCardInteractionsParams) => void
  marqetaBootstrap: (clientAccessToken: string, onFailure: () => void) => void
}

interface Props {
  children: ReactNode
}

export const MemberCardsContext = createContext<IMemberCardsContext>({} as IMemberCardsContext)

const bService = bankingService(bankingRepo)
const uService = userService(userRepo)

export function MemberCardsContextProvider({ children }: Props) {
  const { user, logout } = useAuth()
  const { userAccount } = useAccount()
  const [loadingMemberCards, setLoadingMemberCards] = useState(false)
  const [memberCards, setMemberCards] = useState<MemberCard[]>([])

  const getMemberCards = async () => {
    try {
      setLoadingMemberCards(true)
      if (!user?.association.id || !user?.Banking_account?.length) return
      const data: GetMemberCardsDTO =
        user.type === UserTypesEnum.SUB_ADMIN
          ? { account_id: userAccount.id }
          : { association_id: user?.association.id }

      const response = await bService.getCards(data)

      if (!response) throw new Error('Something went wrong')

      setMemberCards(response)
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      openModal({
        type: 'warning',
        title: 'Error',
        dismiss: false,
        subtitle: getErrorMessage(error),
        confirmationButtonMessage: 'Continue',
        contentStyle: { width: '80%', maxWidth: 400 },
      })
    } finally {
      setLoadingMemberCards(false)
    }
  }

  const lockCard = async (cardId: string) => {
    try {
      const response = await bService.patchCardInfo(cardId, {
        card_status: 'SUSPENDED',
        reason: CardStatusReasonEnum.OTH,
      })

      if (!response) throw new Error('Something went wrong')
      updateCards(cardId, 'SUSPENDED')
      closeModal()
      openModal({
        dismiss: true,
        title: 'Successfully locked',
        subtitle: 'This card has been successfully locked.',
        type: 'success',
        onCancelPressed: closeModal,
        buttons: [
          <Button style={styles.modalButton} type="primary" text="Continue" onPress={closeModal} />,
        ],
      })
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  const suspendMember = async (userId: string, cardId: string, currentCardStatus: CardStatuses) => {
    try {
      const userRes = await uService.patchAdminInfo(userId, { status: PersonStatusesEnum.FROZEN })
      if (!userRes) throw new Error('Something went wrong')
      if (currentCardStatus === 'ACTIVE') {
        const cardRes = bService.patchCardInfo(cardId, {
          card_status: 'SUSPENDED',
          reason: CardStatusReasonEnum.OTH,
        })
        if (!cardRes) throw new Error('Something went wrong')
        updateCards(cardId, 'SUSPENDED')
      }
      closeModal()
      openModal({
        dismiss: true,
        title: 'Successfully Suspended',
        subtitle: 'This member has been successfully suspended.',
        type: 'success',
        onCancelPressed: closeModal,
        buttons: [
          <Button
            style={styles.modalButton}
            type="primary"
            text="Continue"
            onPress={currentCardStatus === 'ACTIVE' ? closeModal : openLockCardErrorModal}
          />,
        ],
      })
    } catch (err) {
      if (err instanceof UnauthenticatedError) {
        logout()
        return
      }
    }
  }

  const onLockCardInteractions = (params: onLockCardInteractionsParams) => {
    const { type, userId, cardId, currentCardStatus } = params
    openModal({
      dismiss: true,
      title: `${type === lockCardInteractionsTypes.LOCK ? 'Lock Card' : 'Suspend member'}`,
      subtitle: `${
        type === lockCardInteractionsTypes.LOCK
          ? 'Are you sure you want to lock this card?'
          : 'Are you sure you want to suspend this member?'
      }`,
      type: 'warning',
      buttons: [
        <Button
          style={styles.modalButton}
          type="primary"
          text={type === lockCardInteractionsTypes.LOCK ? 'Lock Card' : 'Suspend Member'}
          onPress={
            type === lockCardInteractionsTypes.LOCK
              ? () => lockCard(cardId)
              : () => suspendMember(userId, cardId, currentCardStatus)
          }
        />,
        <Button
          key="cancel"
          onPress={closeModal}
          type="secondary"
          text="Cancel"
          style={styles.cancelButton}
        />,
      ],
    })
  }

  const updateCards = (cardId: string, status: CardStatuses) => {
    setMemberCards(
      [...memberCards].map((card) => {
        if (card.id === cardId) {
          return {
            ...card,
            card_status: status,
          }
        }
        return card
      })
    )
  }

  const marqetaBootstrap = (clientAccessToken: string, onFailure: () => void) => {
    const cardDetailStyle = {
      fontFamily: FONT_TYPES.FONT_LIGHT,
      color: COLORS.DARK,
    }
    window.marqeta.bootstrap({
      clientAccessToken,
      integrationType: 'custom',
      containerId: 'card-details-container',
      showPan: {
        cardPan: {
          domId: 'display-card-pan',
          format: true,
          styles: cardDetailStyle,
        },
        cardExp: { domId: 'display-card-exp', format: true, styles: cardDetailStyle },
        cardCvv: { domId: 'display-card-cvv', styles: cardDetailStyle },
      },
      callbackEvents: {
        onFailure,
      },
    })
    window.marqeta.destroy()
  }

  const openLockCardErrorModal = () => {
    openModal({
      dismiss: true,
      title: 'Could Not Lock Card',
      subtitle: 'Unable to lock card until card is activated.',
      type: 'warning',
      onCancelPressed: closeModal,
      buttons: [
        <Button style={styles.modalButton} type="primary" text="Continue" onPress={closeModal} />,
      ],
    })
  }

  return (
    <MemberCardsContext.Provider
      value={{
        memberCards,
        getMemberCards,
        loadingMemberCards,
        onLockCardInteractions,
        marqetaBootstrap,
      }}>
      {children}
    </MemberCardsContext.Provider>
  )
}

const styles = StyleSheet.create({
  modalButton: {
    width: '100%',
    marginTop: 8,
  },
  cancelButton: {
    marginTop: 16,
    borderWidth: 0,
  },
})
