import { COLORS, FONT_TYPES } from '@assets/constants'
import { IInputItems } from '@views/components'
import React, { FC, useState } from 'react'
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button'

interface IRadioButtonProps {
  onChange: (value: any) => any
  items: IInputItems[]
  radioButtonStyle?: StyleProp<ViewStyle>
  radioButtonInputStyle?: StyleProp<ViewStyle>
  radioButtonLabelStyle?: StyleProp<TextStyle>
  buttonInnerColor?: COLORS
  buttonOuterColor?: COLORS
  buttonSelectedInnerColor?: COLORS
  buttonSelectedOuterColor?: COLORS
  value?: any
  container?: {}
  initialSelected?: number
}

export const RNRadioButton: FC<IRadioButtonProps> = ({
  onChange,
  items,
  radioButtonStyle = {},
  radioButtonInputStyle = {},
  radioButtonLabelStyle = {},
  container = {},
  buttonInnerColor = COLORS.CYAN,
  buttonOuterColor = COLORS.CYAN,
  buttonSelectedInnerColor = COLORS.CYAN,
  buttonSelectedOuterColor = COLORS.CYAN,
  initialSelected = -1,
}) => {
  const [selected, setSelected] = useState(initialSelected)

  const onPress = (itemValue: any, idx: number) => {
    setSelected(idx)
    onChange(itemValue)
  }

  return (
    <View style={container}>
      {items.map((item, idx) => {
        if (item.disabled)
          return (
            <RadioButton key={`radio-btn-${idx}`} style={[styles.radioButton, radioButtonStyle]}>
              <RadioButtonInput
                index={idx}
                obj={item}
                isSelected={false}
                buttonSize={7}
                buttonInnerColor={COLORS.GRAY_500}
                buttonOuterColor={COLORS.GRAY_500}
                buttonStyle={[styles.input, radioButtonInputStyle]}
              />
              <RadioButtonLabel
                obj={item}
                index={idx}
                onPress={() => {}}
                labelStyle={[styles.disabledText, radioButtonLabelStyle]}
              />
            </RadioButton>
          )

        return (
          <RadioButton key={`radio-btn-${idx}`} style={[styles.radioButton, radioButtonStyle]}>
            <RadioButtonInput
              obj={item}
              index={idx}
              isSelected={idx === selected}
              onPress={(itemValue) => onPress(itemValue, idx)}
              buttonInnerColor={idx === selected ? buttonSelectedInnerColor : buttonInnerColor}
              buttonOuterColor={idx === selected ? buttonSelectedOuterColor : buttonOuterColor}
              buttonSize={7}
              buttonStyle={[styles.input, radioButtonInputStyle]}
            />
            <RadioButtonLabel
              obj={item}
              index={idx}
              onPress={(itemValue) => onPress(itemValue, idx)}
              labelStyle={[styles.label, radioButtonLabelStyle]}
            />
          </RadioButton>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  radioButton: {
    marginBottom: 10,
  },
  disabledText: {
    color: COLORS.GRAY_500,
    fontSize: 14,
    fontFamily: FONT_TYPES.FONT_REGULAR,
  },
  input: {
    marginRight: 10,
    borderWidth: 1.5,
  },
  label: {
    fontSize: 14,
    fontFamily: FONT_TYPES.FONT_REGULAR,
  },
})
