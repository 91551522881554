import React, { useEffect } from 'react'
import { AppText } from '@views/components'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { MemberCardsMobileVersion } from './MemberCardsMobileVersion'
import { useMemberCards, useSystemConfig } from '@views/hooks'
import { MemberCardsWebVersion } from './MemberCardsWebVersion'

export const MemberCards: React.FC = () => {
  const { isSmallDevice } = useSystemConfig()

  const { getMemberCards, loadingMemberCards, memberCards } = useMemberCards()

  useEffect(() => {
    getMemberCards()
  }, [])

  if (loadingMemberCards) return <ActivityIndicator />

  if (!memberCards.length)
    return <AppText style={styles.noCards}>You have not issued any member cards.</AppText>

  if (isSmallDevice) return <MemberCardsMobileVersion />

  return <MemberCardsWebVersion />
}

const styles = StyleSheet.create({
  noCards: {
    fontSize: 16,
  },
})
