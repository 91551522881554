import React from 'react'
import { FieldError } from 'react-hook-form'
import { View, StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { AppText, FormInput, Row } from '@views/components'
import { IBeneficialOwnerInviteFields } from '@views/schemas'

import { COLORS } from '@assets/constants'
import Checkbox from 'expo-checkbox'

type Props = {
  form: IBeneficialOwnerInviteFields
  control: any
  errors: any
  removeForm?: (idx: number) => any
  formIdx?: number
  checked?: boolean
  changeFormType?: any
  type?: 'info' | 'invite'
  disabled?: boolean
}

const BeneficialOwnersEmail: React.FC<Props> = ({
  form,
  control,
  errors,
  removeForm,
  formIdx = 0,
  checked = false,
  changeFormType,
  type,
  disabled,
}) => {
  const isInvite = type && type === 'invite'
  return (
    <View style={styles.formWrapper} key={`owner-${formIdx + 1}-form`}>
      <Row>
        <AppText bold style={styles.subHeader}>
          {`Beneficial owner ${formIdx === -1 ? 'info' : formIdx + 1}`}
        </AppText>
        {formIdx > 0 && removeForm !== undefined && (
          <TouchableOpacity onPress={() => removeForm(formIdx)}>
            <AppText style={styles.removeOwnerText}>Remove</AppText>
          </TouchableOpacity>
        )}
      </Row>
      <Row style={styles.checkBoxWrapper}>
        <Checkbox
          value={!isInvite}
          disabled={checked || disabled}
          onValueChange={() => (changeFormType !== undefined ? changeFormType(formIdx) : '')}
          style={styles.checkBox}
          color={isInvite ? COLORS.BACKGROUND : COLORS.LIGHT_GREEN}
        />
        <AppText>Send an email</AppText>
      </Row>
      <FormInput
        name={form.email.name}
        label={form.email.label}
        type={form.email.type}
        control={control}
        disabled={!!disabled}
        error={errors[form.email.name] as unknown as FieldError}
        placeholder={form.email.placeholder}
        rules={form.email.rules}
      />
    </View>
  )
}

export default BeneficialOwnersEmail

const styles = StyleSheet.create({
  checkBoxWrapper: {
    marginVertical: 10,
  },
  checkBox: {
    marginRight: 10,
  },
  removeOwnerText: {
    color: COLORS.PRIMARY,
    fontSize: 14,
    marginLeft: 20,
    marginTop: 2,
    textDecorationLine: 'underline',
  },
  formWrapper: {
    borderBottomColor: COLORS.BACKGROUND,
    borderBottomWidth: 1,
    marginBottom: 25,
  },
  subHeader: {
    fontSize: 16,
    marginRight: 25,
  },
})
