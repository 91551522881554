import React from 'react'
import { CreateAccountStackScreenProps } from '@views/navigation/types'
import BeneficialOwnersForm from './BeneficialOwnersForm'

const BeneficialOwners = ({ navigation }: CreateAccountStackScreenProps<'beneficialOwners'>) => {
  const onSubmit = () => {
    navigation.navigate('sportInfo')
  }

  const onBack = () => {
    navigation.navigate('adminInfo')
  }

  return <BeneficialOwnersForm onSubmit={onSubmit} onBack={onBack} />
}

export default BeneficialOwners
