import React from 'react'
import { AppText, Button, Form, closeModal, RenderRow, IInputProps } from '@views/components'
import { View, StyleSheet } from 'react-native'
import {
  Control,
  FieldErrors,
  FieldValues,
  useForm,
  DeepRequired,
  FieldErrorsImpl,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { COLORS, dateRegex } from '@assets/constants'
import { useSystemConfig } from '@views/hooks'
import { AntDesign } from '@expo/vector-icons'
import { filterInfoFields, transactionsFilterSchema, TransactionsFormValues } from '@views/schemas'
import { TouchableOpacity } from 'react-native-gesture-handler'
import moment from 'moment'

type InputGroupProps = {
  control: Control<TransactionsFormValues>
  errors: FieldErrorsImpl<DeepRequired<TransactionsFormValues>>
  title: string
  fields: IInputProps[]
}

const InputGroup: React.FC<InputGroupProps> = ({ control, errors, title, fields }) => {
  return (
    <View>
      <AppText semiBold style={styles.rowHeader}>
        {title}
      </AppText>
      <RenderRow
        control={control as Control<any, FieldValues>}
        errors={errors as FieldErrors}
        fields={fields}
      />
    </View>
  )
}

type TransactionsFilterModalProps = {
  startDate?: string
  endDate?: string
  transactionType?: string
  handleFilter: (data: TransactionsFormValues) => void
}

export const TransactionsFilterModal: React.FC<TransactionsFilterModalProps> = ({
  startDate = '',
  endDate = '',
  transactionType = '',
  handleFilter,
}) => {
  const { isSmallDevice, isMediumDevice } = useSystemConfig()

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
  } = useForm<TransactionsFormValues>({
    resolver: yupResolver(transactionsFilterSchema),
    defaultValues: {
      startDate,
      endDate,
      transactionType,
    },
  })

  const handleClearFilters = () => {
    setValue('startDate', '')
    setValue('endDate', '')
    setValue('transactionType', '')
    clearErrors()
  }

  const onSubmit = async (data: TransactionsFormValues) => {
    // Check if data hasn't been changed
    if (
      data.startDate === startDate &&
      data.endDate === endDate &&
      data.transactionType === transactionType
    ) {
      closeModal()
      return
    }
    let isValid = true

    if (data.startDate && !data.startDate.match(dateRegex)) {
      isValid = false
      setError('startDate', { message: 'Invalid date' })
    }

    if (data.endDate && !data.endDate.match(dateRegex)) {
      isValid = false
      setError('endDate', { message: 'Invalid date' })
    }

    // Check if end date is before start date
    if (data.startDate && data.endDate && isValid) {
      const fromDate = moment(new Date(data.startDate))
      const toDate = moment(new Date(data.endDate))
      if (fromDate.diff(toDate) > 0) {
        isValid = false
        setError('endDate', { message: 'Invalid date' })
      }
    }

    if (isValid) {
      const actualTransactionType = data.transactionType === '--' ? '' : data.transactionType
      handleFilter({
        ...data,
        transactionType: actualTransactionType,
      })
      closeModal()
    }
  }

  return (
    <View
      style={
        isSmallDevice
          ? styles.container
          : isMediumDevice
          ? styles.medContainer
          : styles.bigContainer
      }>
      <View style={styles.header}>
        <AppText bold style={styles.title}>
          Filter
        </AppText>
        <AntDesign name="closesquare" size={24} color={COLORS.GRAY_700} onPress={closeModal} />
      </View>
      <Form>
        <InputGroup
          control={control}
          errors={errors}
          title={'Transactions dates'}
          fields={[filterInfoFields.startDate, filterInfoFields.endDate]}
        />
        <InputGroup
          control={control}
          errors={errors}
          title={'Payment details'}
          fields={[filterInfoFields.transactionType]}
        />
        <View style={[!isMediumDevice && styles.rowRev, styles.btns]}>
          <Button
            style={isMediumDevice ? styles.btnFull : styles.btnHalf}
            onPress={handleSubmit(onSubmit)}
            text="Accept Filter"
          />
          <TouchableOpacity style={styles.clearFilter}>
            <AppText light style={styles.clearFilterText} onPress={handleClearFilters}>
              Clear Filters
            </AppText>
          </TouchableOpacity>
        </View>
      </Form>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 280,
  },
  medContainer: {
    width: 650,
  },
  bigContainer: {
    width: 920,
  },
  title: { fontSize: 24 },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btns: {
    marginTop: 16,
  },
  rowHeader: {
    marginBottom: 12,
    fontSize: 16,
  },
  rowRev: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  btnHalf: {
    width: '50%',
  },
  btnFull: {
    width: '100%',
  },
  clearFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    paddingTop: 24,
  },
  clearFilterText: {
    textDecorationLine: 'underline',
  },
})
