import { UserTypesEnum } from '@assets/constants'

export const getRoleName = (role?: UserTypesEnum): string | undefined => {
  const rolesMap: Record<UserTypesEnum, string> = {
    ORG_ADMIN: 'Org admin',
    SUB_ADMIN: 'Sub admin',
    BENEFICIAL_OWNER: 'Org admin',
  }

  return role ? rolesMap[role] : undefined
}
