import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Checklist, HelpCenter, openModal } from '@views/components'
import { COLORS } from '@assets/constants'
import { useAuth } from '@views/hooks'

const OtherPage: React.FC = () => {
  const startChecklist = () => {
    openModal({
      containerStyle: styles.modalContainer,
      contentStyle: styles.modalContent,
      content: <Checklist />,
      dismiss: false,
    })
  }

  return (
    <View style={[styles.container, styles.containerMobile]}>
      <HelpCenter onStartChecklist={startChecklist} />
      <Footer />
    </View>
  )
}

const Footer = () => {
  const { logout } = useAuth()

  return (
    <View style={styles.footer}>
      <Button onPress={logout} type="secondary" style={styles.logoutContainer} text="Log out" />
    </View>
  )
}

export default OtherPage

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    paddingTop: 32,
    padding: 16,
    flex: 1,
  },
  footer: {
    justifyContent: 'flex-end',
  },
  containerMobile: {
    backgroundColor: COLORS.WHITE,
    justifyContent: 'space-between',
  },
  logoutContainer: {
    marginTop: 8,
  },
  ownerName: {
    fontSize: 14,
    marginBottom: 8,
  },
  ownerRole: { fontSize: 12 },
  modalContainer: {
    backgroundColor: 'rgba(0,0,0)',
    overflow: 'hidden',
  },
  modalContent: {
    position: 'absolute',
    top: '22.5vh',
    bottom: '12.5vh',
    left: 16,
    right: 16,
    borderRadius: 8,
    margin: 0,
    padding: 20,
    paddingRight: 36,
    paddingLeft: 36,
    filter: 'drop-shadow(0px 6px 24px rgba(54, 55, 70, 0.08))',
  },
})
