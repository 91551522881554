import { useSystemConfig } from '@views/hooks'
import React, { useEffect } from 'react'
import { MobileNavigator } from './mobileNavigation'
import { WebNavigator } from './webNavigation'

export default function MainNavigation() {
  const { isSmallDevice } = useSystemConfig()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js'
    script.type = 'text/javascript'
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return isSmallDevice ? <MobileNavigator /> : <WebNavigator />
}
