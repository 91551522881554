import React, { createContext, FC, ReactNode, useCallback, useState } from 'react'
import {
  createAccountInitialState,
  ExternalAccountData,
  ICreateAccountContext,
  ICreateAccountState,
} from '@views/contexts/CreateAccountContext/interface'
import { IAdminInfo, IBasicInfo, IBeneficialOwnersInfo, ISportInfo } from '@views/schemas'
import { BankingAccount, ExternalAccount, PlaidLinkToken } from '@domain/models'
import { useAuth } from '@views/hooks'
import { bankingService, businessService, userService } from '@domain/service'
import { bankingRepo, businessRepo, userRepo } from '@microservices/instances'
import { SportInfoDTOToSportInfo } from '@microservices/dto/SportInfoDTO'
import { SendEmailRequestDto } from '@microservices/dto'
import { UnauthenticatedError } from '@microservices/errors/http'

interface ICreateAccountContextProps {
  children: ReactNode
}

export const CreateAccountContext = createContext<ICreateAccountContext>(
  {} as ICreateAccountContext
)

export const CreateAccountProvider: FC<ICreateAccountContextProps> = ({ children }) => {
  const [createAccountState, setCreateAccountState] =
    useState<ICreateAccountState>(createAccountInitialState)
  const { user, setUser, updateAuthFlow, logout } = useAuth()

  const setLoading = useCallback((payload) => {
    setCreateAccountState((state) => {
      Object.assign(state, { loading: payload })
      return { ...state }
    })
  }, [])

  const setError = useCallback((payload) => {
    setCreateAccountState((state) => Object.assign(state, { error: payload }))
  }, [])

  const setBasicInfo = useCallback(async (payload: IBasicInfo) => {
    setCreateAccountState((state) => {
      Object.assign(state, { data: { ...state.data, basicInfo: payload } })
      return { ...state }
    })
  }, [])

  const setAdminInfo = useCallback(async (payload: IAdminInfo) => {
    setCreateAccountState((state) => {
      Object.assign(state, { data: { ...state.data, adminInfo: payload } })
      return { ...state }
    })
  }, [])

  const setOwnerInfo = useCallback((payload: IBeneficialOwnersInfo[]) => {
    setCreateAccountState((state) =>
      Object.assign(state, { data: { ...state.data, beneficialOwnersInfo: payload } })
    )
  }, [])

  const setSportInfo = useCallback((payload: ISportInfo | undefined) => {
    setCreateAccountState((state) =>
      Object.assign(state, { data: { ...state.data, sportInfo: payload } })
    )
  }, [])

  const setPlaidLinkToken = useCallback(async (payload: PlaidLinkToken) => {
    setCreateAccountState((state) => {
      Object.assign(state, { data: { ...state.data, plaidToken: payload } })
      return { ...state }
    })
  }, [])

  const setBankAccount = useCallback((payload: BankingAccount) => {
    setCreateAccountState((state) =>
      Object.assign(state, { data: { ...state.data, bankAccount: payload } })
    )
  }, [])

  const setExternalAccount = useCallback((payload?: ExternalAccount) => {
    setCreateAccountState((state) =>
      Object.assign(state, { data: { ...state.data, externalAccount: payload } })
    )
  }, [])

  const postBasicInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await businessService(businessRepo).createBusiness(payload)
        setUser(response.data)
        updateAuthFlow(response.data)
        await setBasicInfo(payload)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setUser, updateAuthFlow, setBasicInfo, setError]
  )

  const getBasicInfo = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      if (!user) {
        setError('Not logged in.')
        return
      }
      if (!user?.association?.id) return

      const response = await businessService(businessRepo).getBasicInfo(user.association.id)
      await setBasicInfo(response.data)
      return response.data
    } catch (error: any) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [setLoading, user, setBasicInfo, setError])

  const patchBasicInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        if (!user || !user.association.id) {
          setError('Not logged in.')
          return
        }
        const response = await businessService(businessRepo).patchBasicInfo(
          user.association.id,
          payload
        )
        setUser((prevUser) => ({
          ...prevUser,
          association: { ...prevUser.association, structure: response.data.orgLegalStructure },
        }))

        await setBasicInfo(response.data)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
        throw new Error(error)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, user, setBasicInfo, setError]
  )

  const postAdminInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await userService(userRepo).createAdminWithDisclosures(
          payload,
          user?.association?.id
        )
        setUser(response.data)
        updateAuthFlow(response.data)
        await setAdminInfo(payload)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, user?.association?.id, setUser, updateAuthFlow, setAdminInfo, setError]
  )

  const getAdminInfo = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      const response = await userService(userRepo).getAdminInfo(user?.id)
      await setAdminInfo(response.data)
      return response.data
    } catch (error: any) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [setLoading, user, setAdminInfo, setError])

  const patchAdminInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await userService(userRepo).patchAdminInfo(user?.id, payload)
        await setAdminInfo(response.data)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, user, setAdminInfo, setError]
  )

  const postOwnerInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await userService(userRepo).createBeneficialOwners(
          payload,
          user?.association?.id
        )
        setUser(response.data)
        updateAuthFlow(response.data)
        await setOwnerInfo(payload)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, user?.association?.id, setUser, updateAuthFlow, setOwnerInfo, setError]
  )

  const getOwnerInfo = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      const response = await userService(userRepo).getBeneficialOwners(user?.association?.id)
      await setOwnerInfo(response.data)
    } catch (error: any) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [setLoading, user?.association?.id, setOwnerInfo, setError])

  const patchOwnerInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await userService(userRepo).updateBeneficialOwner(user?.id, payload)
        setUser(response.data)
        updateAuthFlow(response.data)
        await setOwnerInfo(payload)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setError, user?.id, setUser, updateAuthFlow, setOwnerInfo]
  )

  const postOwnerEmails = useCallback(
    async (payload: SendEmailRequestDto) => {
      setLoading(true)
      setError('')
      try {
        await userService(userRepo).postBeneficialOwnersEmails(payload, user?.association?.id)
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        if (error instanceof Error) {
          setError(error.message)
          return
        }
        setError('Something went wrong.')
      } finally {
        setLoading(false)
      }
    },
    [setLoading, user?.association?.id, setError]
  )

  const postSportInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        if (!user || !user.association) {
          setError('Error with user object')
          return
        }
        const response = await businessService(businessRepo).createSportInfo(
          payload,
          user?.association?.id
        )
        setUser(response.data)
        updateAuthFlow(response.data)
        setSportInfo(SportInfoDTOToSportInfo(response.data.association.Sport_info))
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setError, setLoading, setSportInfo, setUser, updateAuthFlow, user]
  )

  const patchSportInfo = useCallback(
    async (payload) => {
      setLoading(true)
      setError('')
      try {
        const response = await businessService(businessRepo).patchSportInfo(
          payload,
          user?.association?.id
        )
        setSportInfo(response)
      } catch (error: any) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setError, setLoading, setSportInfo, setUser, user?.association?.id]
  )

  const createPlaidLinkToken = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      const plaidToken = await bankingService(bankingRepo).createLinkToken(user?.association?.id)
      await setPlaidLinkToken(plaidToken)
      return plaidToken
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      if (error instanceof Error) setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [setError, setLoading, setPlaidLinkToken, user?.association?.id])

  const createBankAccount = useCallback(async (): Promise<void> => {
    setLoading(true)
    setError('')
    try {
      const response = await bankingService(bankingRepo).createBankAccount({
        user_id: user?.id,
        association_id: user?.association?.id,
      })

      if (!response) {
        setError('Something went wrong.')
        return
      }
      setUser(response)
      updateAuthFlow(response)

      if (!response.Banking_account?.length) {
        setError('Something went wrong.')
        return
      }
      setBankAccount(response.Banking_account[0])
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      if (error instanceof Error) setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [
    setBankAccount,
    setError,
    setLoading,
    setUser,
    updateAuthFlow,
    user?.association?.id,
    user?.id,
  ])

  const createBusinessVerification = useCallback(async (): Promise<
    { kyc_status: string; kyb_status: string } | undefined
  > => {
    setLoading(true)
    setError('')
    try {
      await businessService(businessRepo).createBusinessVerification(user?.association?.id)
      const response = await businessService(businessRepo).createPersonVerification(user?.id)

      if (!response) {
        setError('Something went wrong.')
        return
      }
      setUser(response.data)
      updateAuthFlow(response.data)
      return {
        kyc_status: response.data.kyc_status,
        kyb_status: response.data.association.kyb_status,
      }
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        logout()
        return
      }
      if (error instanceof Error) setError(error.message)
    } finally {
      setLoading(false)
    }
  }, [setError, setLoading, setUser, updateAuthFlow, user?.association?.id])

  const exchangePublicToken = useCallback(
    async (data: ExternalAccountData): Promise<void> => {
      setLoading(true)
      setError('')
      try {
        const { public_token, accounts, institution } = data
        const response = await bankingService(bankingRepo).exchangePublicToken(
          public_token,
          accounts,
          institution,
          user?.association?.id
        )
        if (response?.External_account) {
          setLoading(false)
          const [externalAccount] = response?.External_account
          setExternalAccount(externalAccount)
          updateAuthFlow(response)
        }
      } catch (error) {
        if (error instanceof UnauthenticatedError) {
          logout()
          return
        }
        if (error instanceof Error) setError(error.message)
      } finally {
        setLoading(false)
      }
    },
    [setError, setExternalAccount, setLoading, user?.association?.id]
  )

  const value = {
    createAccountState,
    postBasicInfo,
    getBasicInfo,
    patchBasicInfo,
    postAdminInfo,
    getAdminInfo,
    patchAdminInfo,
    postOwnerInfo,
    getOwnerInfo,
    patchOwnerInfo,
    postOwnerEmails,
    postSportInfo,
    patchSportInfo,
    createPlaidLinkToken,
    createBankAccount,
    createBusinessVerification,
    exchangePublicToken,
  }

  return <CreateAccountContext.Provider value={value}>{children}</CreateAccountContext.Provider>
}
