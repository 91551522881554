import React from 'react'
import { Entypo, Ionicons, SimpleLineIcons } from '@expo/vector-icons'

export const OTHER_ROUTE = 'other'
export const TRANSACTIONS_ROUTE = 'transactions'
export const BUSINESS_ACCOUNT_ROUTE = 'businessAccount'
export const PERSONAL_DOCUMENTS_ROUTE = 'personalDocuments'
export const INITIAL_DEPOSIT_CHECK_ROUTE = 'initialDepositCheck'
export const MAKE_DEPOSIT_CHECK_ROUTE = 'makeDepositCheck'
export const SUB_ADMIN_SIGNUP_ROUTE = 'subAdminSignup'
export const STATEMENTS_ROUTE = 'statements'
export const BENEFICIAL_OWNER_FROM_EMAIL = 'beneficialOwnerFormFromEmail'

export const mainNavPages = [
  {
    title: 'Business Account',
    page: BUSINESS_ACCOUNT_ROUTE,
    getIcon: (color: string) => <Entypo name="wallet" size={20} color={color} />,
  },
  {
    title: 'Transactions',
    page: TRANSACTIONS_ROUTE,
    getIcon: (color: string) => <Ionicons name="md-newspaper-sharp" size={20} color={color} />,
  },
  {
    title: 'Statements',
    page: STATEMENTS_ROUTE,
    getIcon: (color: string) => <Ionicons name="md-folder-sharp" size={20} color={color} />,
  },
]

export const mainNavPagesMobile = [
  ...mainNavPages,
  {
    title: 'Other',
    page: OTHER_ROUTE,
    getIcon: (color: string) => <SimpleLineIcons name="options-vertical" size={20} color={color} />,
  },
]
