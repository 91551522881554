// @TODO fix lint for this file
/* eslint react-native/no-inline-styles: 0 */
import React from 'react'
import { View, StyleSheet, Image, ScrollView, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import { AppText, Button, Row } from '@views/components'

import logo from '@assets/images/logo.png'
import example from '@assets/images/landing-example.png'

import { useAuth, useSystemConfig } from '@views/hooks'
import { COLORS } from '@assets/constants'

const LandingPage = () => {
  const { login } = useAuth()
  const { isLargeDevice, isSmallDevice } = useSystemConfig()
  const imgSize = isLargeDevice ? { width: 290, height: 310 } : { width: 500, height: 630 }

  const calcHorizontalMargin = (): string | number => {
    if (isSmallDevice) return 8
    if (isLargeDevice) return '15%'
    return 'auto'
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <LinearGradient
        colors={[COLORS.LIGHT_GREEN, COLORS.PRIMARY]}
        style={styles.linearContent}
        start={{
          x: 0,
          y: 0,
        }}
        end={{
          x: 0,
          y: 0,
        }}>
        <View style={!isSmallDevice ? styles.nav : styles.mobileNav}>
          <Row style={{ alignItems: 'center', marginLeft: calcHorizontalMargin() }}>
            <Image
              source={logo}
              style={{ width: 126, height: 30, marginRight: !isSmallDevice ? 80 : undefined }}
            />
            {!isLargeDevice ? (
              <>
                <AppText style={[styles.navItem, styles.text]}>Business Account</AppText>
                <AppText style={[styles.navItem, styles.text]}>Pre-Season Loan Program</AppText>
                <AppText style={[styles.navItem, styles.text]}>
                  Bookkeeping and Accounting Services
                </AppText>
                <AppText style={[styles.navItem, styles.text]}>FAQ</AppText>
              </>
            ) : null}
          </Row>
          <TouchableOpacity onPress={() => login()} style={{ marginRight: calcHorizontalMargin() }}>
            {isSmallDevice ? (
              <AppText bold style={[styles.text, { textDecorationLine: 'underline' }]}>
                Log in
              </AppText>
            ) : (
              <AppText style={styles.text}>
                Already have an account?{' '}
                <AppText bold style={[{ textDecorationLine: 'underline' }, styles.text]}>
                  Log in
                </AppText>
              </AppText>
            )}
          </TouchableOpacity>
        </View>
        <View style={isSmallDevice ? styles.bodyMobile : styles.body}>
          <View
            style={{
              ...styles.infoContainer,
              maxWidth: isSmallDevice ? '100%' : '50%',
              marginTop: 100,
            }}>
            <AppText bold style={[styles.text, { fontSize: 40, marginBottom: 20 }]}>
              Digital banking for sports organizations
            </AppText>
            <AppText light style={[styles.text, { fontSize: 16, lineHeight: 24 }]}>
              Digital banking and financial management platform that gives youth sports teams and
              clubs everything they need to manage money with ease and transparency.
            </AppText>

            <View style={styles.buttonContainer}>
              <Button
                onPress={() => {
                  login({ action: 'signup' })
                }}
                type="primary"
                text="Get Started"
              />
            </View>
          </View>
          <View style={isSmallDevice ? styles.imgContainerMobile : styles.imgContainerWeb}>
            <Image source={example} style={imgSize} />
          </View>
        </View>
      </LinearGradient>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollContainer: { height: '100%' },
  linearContent: { height: '100%' },
  text: {
    color: 'white',
  },
  nav: {
    marginTop: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileNav: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navItem: {
    marginRight: 32,
  },
  bodyMobile: {
    marginRight: 16,
    marginLeft: 8,
  },
  body: {
    margin: 'auto',
    marginTop: 105,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '70%',
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  imgContainerWeb: {
    marginLeft: 120,
  },
  imgContainerMobile: {
    marginBottom: 40,
    marginLeft: '10%',
  },
  buttonContainer: {
    marginTop: 32,
    marginBottom: 60,
    width: 270,
  },
})

export default LandingPage
