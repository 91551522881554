import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Button } from '@views/components'

export type CameraProps = {
  takePicture: () => void
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
}

export const Camera: React.FC<CameraProps> = ({ takePicture, videoRef }) => {
  return (
    <View>
      <View style={styles.cameraContainer}>
        <video
          style={{ width: '100%', height: '80%', objectFit: 'cover' }}
          autoPlay={true}
          ref={videoRef}
          contentEditable={false}
          controls={false}
          playsInline={true}
          unselectable={'on'}
        />
      </View>
      <View style={styles.buttonContainer}>
        <Button type={'secondary'} onPress={takePicture} text={'Take photo'} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cameraContainer: {
    height: '60vw',
    width: '90vw',
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
})
