import React, { useState } from 'react'
import { isHoverEnabled } from './HoverState'

type Props = {
  onHoverIn?: () => any
  onHoverOut?: () => any
  children: React.ReactNode | ((isHovered: boolean) => React.ReactNode)
}

export const Hoverable: React.FC<Props> = ({ onHoverIn, onHoverOut, children }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [showHover, setShowHover] = useState(true)

  const hanldeMouseEnter = (e: any) => {
    if (isHoverEnabled() && !isHovered) {
      if (onHoverIn) {
        onHoverIn()
      }
      setIsHovered(true)
    }
  }

  const hanldeMouseLeave = (e: any) => {
    if (isHovered) {
      if (onHoverOut) {
        onHoverOut()
      }
      setIsHovered(false)
    }
  }

  const hanldeGrant = () => {
    setShowHover(false)
  }

  const hanldeRelease = () => {}

  const child = typeof children === 'function' ? children(showHover && isHovered) : children

  return React.cloneElement(React.Children.only(child), {
    onMouseEnter: hanldeMouseEnter,
    onMouseLeave: hanldeMouseLeave,
    onResponderGrant: () => setShowHover(false),
    onResponderRelease: () => setShowHover(true),
    onPressIn: hanldeGrant,
    onPressOut: hanldeRelease,
  })
}
