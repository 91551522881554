import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { BUSINESS_ACCOUNT_ROUTE, COLORS } from '@assets/constants'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { AppText, DropdownPopover, StatementsFilterForm, StatementsList } from '@views/components'
import { useAuth, useStatements, useSystemConfig } from '@views/hooks'
import { formatStatementPeriod, getRoleName } from '@views/utils'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { LoadingPage } from '@views/pages'

const Statements: React.FC = ({ navigation }: any) => {
  const { isMobile } = useSystemConfig()
  const { user, logout } = useAuth()
  const { getAllStatements } = useStatements()
  const onMobile = isMobile()
  const hasRole = !!user?.type

  useEffect(() => {
    if (!onMobile) {
      getAllStatements()
    } else {
      navigation.navigate(BUSINESS_ACCOUNT_ROUTE)
    }
  }, [])

  if (onMobile) {
    return <LoadingPage />
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.optionContainer}>
          <AppText style={styles.title}>Statements</AppText>
        </View>
        <View style={styles.teamMainInfo}>
          <View style={styles.teamMainInfoText}>
            <AppText style={styles.ownerName}>{user?.first_name}</AppText>
            {hasRole && <AppText style={styles.ownerRole}>{getRoleName(user?.type)}</AppText>}
          </View>
          <DropdownPopover
            from={<MaterialCommunityIcons name="chevron-down" size={20} color={COLORS.GRAY_700} />}>
            <TouchableOpacity style={styles.popoverContainer} onPress={() => logout()}>
              <Ionicons name="exit" size={20} color={COLORS.GRAY_700} />
              <AppText style={styles.logoutText}>Log out</AppText>
            </TouchableOpacity>
          </DropdownPopover>
        </View>
      </View>
      <AppText style={styles.smallText}>Current statement period</AppText>
      <AppText bold style={styles.currentStatement}>
        {formatStatementPeriod()}
      </AppText>
      <StatementsFilterForm />

      <AppText semiBold style={styles.smallText}>
        Statement period
      </AppText>
      <StatementsList />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flexGrow: 1,
    padding: 30,
    backgroundColor: COLORS.WHITE,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: COLORS.DARK,
    fontStyle: 'normal',
  },
  headerContainer: {
    paddingBottom: 28,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginRight: 24,
  },
  teamMainInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  teamMainInfoText: {
    flexDirection: 'column',
  },
  ownerName: {
    fontSize: 14,
    fontWeight: '600',
  },
  ownerRole: { fontSize: 12 },
  popoverContainer: {
    backgroundColor: '#FFF',
    width: 120,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logoutText: {
    color: COLORS.GRAY_700,
    marginLeft: 8,
  },
  smallText: {
    fontSize: 12,
  },
  currentStatement: {
    fontSize: 16,
    marginTop: 4,
    marginBottom: 24,
  },
})

export default Statements
