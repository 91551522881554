import React from 'react'
import moment from 'moment'
import { View, StyleSheet } from 'react-native'
import { AppText } from '../AppText'
import { currencyFormatter, formatDate, toTitleString } from '@views/utils'
import { COLORS, FONT_TYPES } from '@assets/constants'
import { useDeposits } from '@views/hooks'

export const DepositsTabWebVersion: React.FC = () => {
  const { deposits, initiatedBy } = useDeposits()

  return (
    <View style={styles.container}>
      <View style={styles.tableHead}>
        <AppText style={styles.head}>Initiated by</AppText>
        <AppText style={styles.head}>Date</AppText>
        <AppText style={styles.head}>Status</AppText>
        <AppText style={styles.head}>Amount</AppText>
      </View>
      {deposits.map((item, i) => (
        <View key={item.id}>
          <View style={styles.row}>
            <AppText semiBold style={styles.tableItem}>
              {`${initiatedBy.user.first_name} ${initiatedBy.user.last_name}`}
            </AppText>
            <AppText light style={styles.tableItem}>
              {formatDate(item.creation_time, 'MM/DD/YY')}
            </AppText>
            <AppText light style={styles.tableItem}>
              {toTitleString(item.status)}
            </AppText>
            <AppText bold style={styles.tableItem}>
              + {currencyFormatter(item.check_amount / 100)}
            </AppText>
          </View>
          {i !== deposits.length - 1 && <View style={styles.seperator} />}
        </View>
      ))}
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
  },
  noElements: {
    fontSize: 16,
    fontWeight: '400',
  },
  tableHead: {
    paddingTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  head: {
    fontSize: 12,
    fontFamily: FONT_TYPES.FONT_SEMIBOLD,
    width: '25%',
  },
  row: {
    paddingVertical: 24,
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableItem: {
    width: '25%',
    fontSize: 14,
  },
  seperator: {
    height: 1,
    backgroundColor: COLORS.GRAY_200,
  },
})
