export enum ROLES {
  ORG_ADMIN = 'org_admin',
  SUB_ADMIN = 'sub_admin',
  BENEFICIAL_OWNER = 'beneficial_owner',
}

export enum UserTypesEnum {
  ORG_ADMIN = 'ORG_ADMIN',
  SUB_ADMIN = 'SUB_ADMIN',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
}
