import React, { FC, ReactNode } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import {
  AppText,
  Button,
  ErrorMessage,
  InputTypes,
  RNInput,
  RNPicker,
  RNRadioButton,
  RNSlider,
  Row,
} from '@views/components'
import { useDisclaimer, useSystemConfig } from '@views/hooks'

import { COLORS } from '@assets/constants'
import back from '@assets/images/back.png'

export type classNameType = string
export type childrenType = ReactNode

export interface IFormProps {
  children?: childrenType
  className?: classNameType
  buttonLabel?: string
  onSubmit?: any
  handleSubmit?: any
  onBack?: any
  loading?: boolean
  showDisclaimer?: boolean
  showButtons?: boolean
}

export interface IFormInput {
  name: string
  label?: string
  type: InputTypes
  control: any
  error: FieldError | undefined
  items?: IInputItems[]
  placeholder?: string | number
  onChangeText?: any
  rules?: object
  disabled?: boolean
  onFocus?: () => void
}

export interface IInputProps {
  name: string
  type: InputTypes
  label?: string
  items?: IInputItems[]
  placeholder?: string | number
  rules?: {}
  onFocus?: () => void
  value?: any
}

export interface IInputItems {
  label: string
  value: any
  disabled?: boolean
  enabled?: boolean
}

export const ButtonRow: FC<IFormProps> = ({
  buttonLabel = 'Submit',
  loading,
  onSubmit,
  onBack,
  showDisclaimer = false,
}) => {
  const { isMediumDevice, isExtraLargeDevice } = useSystemConfig()
  const { isDisclaimerAccepted, openDisclaimers } = useDisclaimer()

  const renderSubmitButton = () => {
    return (
      <Button
        loading={loading}
        onPress={
          !isDisclaimerAccepted && showDisclaimer
            ? () => openDisclaimers({ onAccepted: onSubmit })
            : onSubmit
        }
        type="primary"
        text={buttonLabel}
      />
    )
  }

  // Temp 'mobile' view to account for navigation
  // @TODO update once mobile form navigation is added
  if (isMediumDevice)
    return (
      <View>
        <View style={styles.mediumBtn} >{renderSubmitButton()}</View>
        <Button onPress={onBack} type="secondary" text="Back" />
      </View>
    )
  else if (isExtraLargeDevice)
    return (
      <>
        <Row style={styles.btnRow}>
          <TouchableOpacity onPress={onBack} style={styles.backButton}>
            <Row>
              <Image source={back} style={styles.backIcon} />
              <AppText>Back</AppText>
            </Row>
          </TouchableOpacity>
          <View style={styles.btnSmall}>{renderSubmitButton()}</View>
        </Row>
      </>
    )

  return (
    <Row style={styles.btnRow}>
      <TouchableOpacity onPress={onBack} style={styles.backButton}>
        <Row>
          <Image source={back} style={styles.backIcon} />
          <AppText>Back</AppText>
        </Row>
      </TouchableOpacity>
      <Row>
        <View style={styles.btnSmall}>{renderSubmitButton()}</View>
      </Row>
    </Row>
  )
}

export const Form: FC<IFormProps> = ({ children }) => {
  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      style={styles.form}
      contentContainerStyle={styles.contentContainerStyle}>
      <View style={styles.children}>{children}</View>
    </ScrollView>
  )
}

export const FormInput: FC<IFormInput> = ({
  name,
  label,
  type,
  control,
  error,
  placeholder,
  onChangeText = null,
  items = [],
  rules = {},
  disabled = false,
  onFocus,
}) => {
  const isError = Boolean(error)
  const isPicker = Boolean(type === InputTypes.picker)
  const isSlider = Boolean(type === InputTypes.slider)
  const isRadioButton = Boolean(type === InputTypes.radioButton && items.length > 0)

  return (
    <View key={name} style={styles.container}>
      <AppText style={styles.label}>{label}</AppText>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => {
          if (isPicker)
            return (
              <RNPicker
                onChange={onChange}
                name={name}
                value={value}
                placeholder={`${placeholder}`}
                items={items}
                enabled={!disabled}
              />
            )

          if (isSlider) return <RNSlider onChange={onChange} onBlur={onBlur} value={value} isDisabled={disabled} />

          if (isRadioButton)
            return <RNRadioButton onChange={onChange} value={value} items={items} />

          return (
            <RNInput
              onFocus={onFocus}
              type={type}
              onChange={onChange}
              onChangeText={onChangeText}
              onBlur={onBlur}
              value={value}
              placeholder={`${placeholder}`}
              editable={!disabled}
              selectTextOnFocus={!disabled}
            />
          )
        }}
        defaultValue={isSlider ? placeholder : ''}
        name={name}
        control={control}
        rules={rules}
      />
      {isError && <ErrorMessage error={error} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  form: {
    paddingTop: 16,
  },
  children: {
    paddingTop: 16,
  },
  disclaimerWrapper: {
    margin: 'auto',
  },
  disclaimer: {
    fontSize: 14,
    marginLeft: 5,
    marginRight: 10,
    marginBottom: 10,
  },
  link: {
    textDecorationLine: 'underline',
  },
  backButton: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  backIcon: {
    height: 14,
    width: 14,
    marginRight: 10,
  },
  btnRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnSmall: {
    width: 270,
  },
  label: {
    color: COLORS.DARK,
    fontSize: 14,
    marginBottom: 8,
    marginLeft: 0,
  },
  mediumBtn: { marginBottom: 16 }
})
