import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdminForm, Button, Row, openModal, QRCodeModal, AppText } from '@views/components'
import { AdminInfoSchema } from '@views/schemas'
import { useDisclaimer, useSystemConfig, useAuth } from '@views/hooks'
import { COLORS, verificationStatuses } from '@assets/constants'
import { userService } from '@domain/service'
import { userRepo, bankingRepo, LocalStorage } from '@microservices/instances'
import { bankingService } from '@domain/service'
import Header from '../CreateAccount/Header'
import { UnauthenticatedError } from '@microservices/errors/http'

const localStorage = new LocalStorage()

const SubAdminInfo = ({ navigation }: any) => {
  const { openDisclaimers } = useDisclaimer()
  const { isSmallDevice } = useSystemConfig()
  const { user, logout } = useAuth()
  const { isMobile } = useSystemConfig()
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(AdminInfoSchema),
  })

  useEffect(() => {
    reset({ email: user?.email ? user.email : '' })
  }, [reset, user])

  useEffect(() => {
    if (!user || !user.id_synctera) return
    const createdBankAccount = !!user?.Banking_account?.length
    const passedKYC =
      user?.kyc_status === verificationStatuses.ACCEPTED ||
      user?.kyc_status === verificationStatuses.PROVISIONAL

    if (passedKYC) {
      onApproved({ user_id: user.id! })
      return
    }
    if (!createdBankAccount && !passedKYC) {
      onNeedReview()
      return
    }
  }, [])

  const openErrorModal = () =>
    openModal({
      title: 'Something went wrong',
      subtitle: 'Please have your org administrator add your email once again as a sub-account.',
      dismiss: false,
      type: 'error',
      confirmationButtonMessage: 'Logout',
      onConfirmPressed: () => logout(),
    })

  const onApproved = async ({
    user_id,
    business_id,
  }: {
    user_id: string
    business_id?: string
  }) => {
    setLoading(true)

    openModal({
      title: 'Creating Account',
      subtitle: 'We are finalizing the creation of your account',
      type: 'warning',
      dismiss: false,
    })
    const association_id = business_id || (await localStorage.getItem<string>('business_id'))
    const subaccount_name = await localStorage.getItem<string>('subaccount_name')

    if (!association_id || !subaccount_name) return openErrorModal()

    try {
      await bankingService(bankingRepo).createBankAccount({
        nickname: subaccount_name,
        is_subaccount: true,
        association_id,
        user_id,
      })
      await localStorage.removeItem('business_id')
      await localStorage.removeItem('subaccount_name')
      openModal({
        dismiss: false,
        type: 'success',
        title: 'Success',
        subtitle: 'Your account has been created.',
        buttons: [
          <Button
            style={!isSmallDevice ? styles.modalButton : styles.modalButtonSmall}
            type="primary"
            text="View Account"
            onPress={() => {
              document.location.reload()
            }}
          />,
        ],
      })
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err)
      if (err instanceof UnauthenticatedError) {
        logout()
        return
      }
    } finally {
      setLoading(false)
    }
  }

  const onNeedReview = () => {
    if (isMobile()) navigation.navigate('personalDocuments')
    else
      openModal({
        dismiss: false,
        content: (
          <QRCodeModal
            component={'personalDocuments'}
            showLogo={true}
            title={'Personal Information Required'}
            instructions={
              'In order to verify your organization, please upload additional documents'
            }
            documentsNeeded={'documents'}
            btnTitle={'Refresh'}
          />
        ),
      })
  }

  const onSubmit = (data: any) => {
    setLoading(true)
    openDisclaimers({
      onAccepted: async () => {
        if (user) {
          try {
            const business_id = await localStorage.getItem<string>('business_id')
            const userRes = await userService(userRepo).createSubAccountAdmin(data, business_id)

            if (
              userRes.kyc_status === verificationStatuses.ACCEPTED ||
              userRes.kyc_status === verificationStatuses.PROVISIONAL
            )
              onApproved({ business_id, user_id: userRes.id })
            else onNeedReview()
          } catch (error) {
            if (error instanceof UnauthenticatedError) {
              logout()
              return
            }
            openErrorModal()
          } finally {
            setLoading(false)
          }
        }
      },
    })
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <View>
        <Header subtitle="Fill out the summary information." route="Administrator data" />
        <AdminForm control={control} errors={errors} />
      </View>
      <Row style={styles.buttonWrapper}>
        <Button
          style={!isSmallDevice ? styles.button : {}}
          onPress={handleSubmit(onSubmit)}
          type="primary"
          text={loading || isSubmitting ? 'Loading...' : 'Submit'}
          loading={isSubmitting || loading}
        />
      </Row>
    </ScrollView>
  )
}

export default SubAdminInfo

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 30,
    backgroundColor: COLORS.WHITE,
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  button: {
    maxWidth: 270,
  },
  modalButton: {
    width: 400,
  },
  modalButtonSmall: {
    maxWidth: 200,
  },
})
