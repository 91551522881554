import { Platform } from 'react-native'
let isEnabled = false

if (Platform.OS === 'web') {
  const HOVER_THRESHOLD_MS = 1000
  let lastTouchTimestap = 0

  function enableHover() {
    if (isEnabled || Date.now() - lastTouchTimestap < HOVER_THRESHOLD_MS) {
      return
    }
    isEnabled = true
  }

  function disableHover() {
    lastTouchTimestap = Date.now()
    if (isEnabled) {
      isEnabled = false
    }
  }
  document.addEventListener('touchstart', disableHover, true)
  document.addEventListener('touchmove', disableHover, true)
  document.addEventListener('mousemove', enableHover, true)
}

export const isHoverEnabled = () => isEnabled
