import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Row, AppText } from '@views/components'
import { COLORS } from '@assets/constants'
import { maskAccountNumber } from '@views/utils'

interface Props {
  label: string
  value: any
  isMasked?: boolean
  id?: string
}

export const DetailsItem: React.FC<Props> = ({ label, value, isMasked, id }) => {
  const [hidden, setHidden] = useState(true)

  return (
    <Row style={styles.itemRow}>
      <AppText semiBold>{label}</AppText>
      <View style={styles.value}>
        <Row>
          <AppText id={id} light>{isMasked && hidden ? maskAccountNumber(value as string) : value}</AppText>
          {isMasked ? (
            <TouchableOpacity onPress={() => setHidden(!hidden)}>
              <AppText light style={styles.hideBtn}>
                {hidden ? 'Show' : 'Hide'}
              </AppText>
            </TouchableOpacity>
          ) : null}
        </Row>
      </View>
    </Row>
  )
}

const styles = StyleSheet.create({
  itemRow: {
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.GRAY_200,
    marginBottom: 12,
    paddingBottom: 12,
    fontSize: 14,
  },
  value: {
    justifyContent: 'flex-start',
    width: 200,
    height: 20
  },
  hideBtn: {
    textDecorationLine: 'underline',
    marginLeft: 24,
  },
})
