export const legalAddressProps = [
  'addrCity',
  'address_line_1',
  'address_line_2',
  'addrState',
  'addrStreet1',
  'addrStreet2',
  'addrZip',
  'city',
  'orgAddrCity',
  'orgAddrState',
  'orgAddrStreet1',
  'orgAddrStreet2',
  'orgAddrZip',
  'state',
  'zip_code',
]
