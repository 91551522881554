import * as yup from 'yup'
import {
  dateRegex,
  noSelectionRegex,
  phoneRegex,
  ssnRegex,
  states,
  stringRegex,
  zipRegex,
} from '@assets/constants'
import { IInputProps } from '@views/components/Form'
import { InputTypes } from '@views/components/Input'
import { isAdult } from '@views/utils'
import { PersonStatusesEnum } from '@microservices/dto'

export const adminInfoFields: IAdminInfoFields = {
  first_name: {
    name: 'first_name',
    label: 'First name',
    type: InputTypes.text,
    placeholder: 'Enter first name',
    rules: {
      required: true,
      pattern: stringRegex,
    },
  },
  last_name: {
    name: 'last_name',
    label: 'Last name',
    type: InputTypes.text,
    placeholder: 'Enter last name',
    rules: {
      required: true,
      pattern: stringRegex,
    },
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    type: InputTypes.phone,
    placeholder: 'Enter phone',
  },
  email: {
    name: 'email',
    label: 'Email',
    type: InputTypes.text,
    placeholder: 'Enter email',
  },
  ssn: {
    name: 'ssn',
    label: 'SSN',
    type: InputTypes.ssn,
    placeholder: 'Enter SSN',
  },
  birthday: {
    name: 'birthday',
    label: 'Date of birth',
    type: InputTypes.date,
    placeholder: 'MM/DD/YYYY',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Street address',
    type: InputTypes.text,
    placeholder: 'Enter street address',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Apartment, suite, etc (optional)',
    type: InputTypes.text,
    placeholder: 'Optional',
  },
  city: {
    name: 'city',
    label: 'City',
    type: InputTypes.text,
    placeholder: 'Enter city',
  },
  state: {
    name: 'state',
    label: 'State',
    type: InputTypes.picker,
    placeholder: 'Select state',
    items: states,
  },
  zip_code: {
    name: 'zip_code',
    label: 'ZIP code',
    type: InputTypes.text,
    placeholder: 'Enter ZIP',
  },
}

export interface IAdminInfoFields {
  first_name: IInputProps
  last_name: IInputProps
  phone: IInputProps
  email: IInputProps
  ssn: IInputProps
  birthday: IInputProps
  address_line_1: IInputProps
  address_line_2: IInputProps
  city: IInputProps
  state: IInputProps
  zip_code: IInputProps
}

export interface IAdminInfo {
  id?: string
  first_name: string
  last_name: string
  phone: string
  email: string
  ssn: string
  birthday: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip_code: string
  percent_ownership?: number
  status?: PersonStatusesEnum
}

export const AdminInfoSchema = yup.object().shape({
  first_name: yup.string().required('Required').matches(stringRegex, 'Invalid format'),
  last_name: yup.string().required('Required').matches(stringRegex, 'Invalid format'),
  phone: yup.string().trim().matches(phoneRegex, 'Invalid phone format').required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  ssn: yup.string().trim().matches(ssnRegex, 'Invalid SSN format').required('Required'),
  birthday: yup
    .string()
    .matches(dateRegex, 'Invalid date')
    .test('age', 'Must be 18 or older', (value) => {
      if (typeof value === 'string') return isAdult(value)
      return false
    })
    .required('Required'),
  address_line_1: yup.string().required('Required'),
  address_line_2: yup.string(),
  city: yup.string().required('Required'),
  state: yup.string().matches(noSelectionRegex, 'Make a selection').required('Required'),
  zip_code: yup
    .string()
    .matches(zipRegex, 'Invalid Zipcode format')
    .min(5, 'Invalid Zipcode format')
    .max(5, 'Invalid Zipcode format')
    .required('Required'),
})
