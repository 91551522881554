import { IssueCardBody, Transactions } from '@domain/models'
import { BankingRepository } from '@domain/repositories/BankingRepository'
import { AchDTO, CreateBankAccountDTO } from '@microservices/dto'

export const bankingService = (repository: BankingRepository): BankingRepository => ({
  createLinkToken: (userId) => {
    return repository.createLinkToken(userId)
  },
  getTransactions: (params: Transactions) => {
    return repository.getTransactions(params)
  },
  getCardProducts: () => {
    return repository.getCardProducts()
  },
  submitIssueCard: (body: IssueCardBody) => {
    return repository.submitIssueCard(body)
  },
  exchangePublicToken: (
    public_token: string,
    accounts: string[],
    institution: any,
    association_id?: string
  ) => {
    return repository.exchangePublicToken(public_token, accounts, institution, association_id)
  },
  createBankAccount: (data: CreateBankAccountDTO) => {
    return repository.createBankAccount(data)
  },
  sendACH: (data: AchDTO) => {
    return repository.sendACH(data)
  },
  createCheckDeposit: (business_id, payload) => {
    return repository.createCheckDeposit(business_id, payload)
  },
  getCardWidgetURL(user_id: string, widget_type: 'activate_card' | 'set_pin', card_id?: string) {
    return repository.getCardWidgetURL(user_id, widget_type, card_id)
  },
  createInternalTransfer: (data) => {
    return repository.createInternalTransfer(data)
  },
  findOrCreateExternalAccount: (data) => {
    return repository.findOrCreateExternalAccount(data)
  },
  getAllAccounts: (association_id) => {
    return repository.getAllAccounts(association_id)
  },
  getDeposits(accountId) {
    return repository.getDeposits(accountId)
  },
  getCards: (accountInfo) => {
    return repository.getCards(accountInfo)
  },
  patchCardInfo: (card_id, cardInfo) => {
    return repository.patchCardInfo(card_id, cardInfo)
  },
  getCardClientToken: (card_id) => {
    return repository.getCardClientToken(card_id)
  },
  getStatementsByAccountId: (account_id) => {
    return repository.getStatementsByAccountId(account_id)
  },
  getStatement: (statement_id) => {
    return repository.getStatement(statement_id)
  },
})
