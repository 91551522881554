import React from 'react'
import { View, TouchableOpacity, Image, StyleSheet, ScrollView } from 'react-native'
import { AppText, closeModal, openModal } from '@views/components'
import { COLORS } from '@assets/constants'
import depositCheckCard from '@assets/images/deposit-check-card.png'
import closeIcon from '@assets/images/close-icon.png'
import { QRCodeModal } from '../QRCodeModal'
import { useSystemConfig } from '@views/hooks'

type MakeDepositProps = {
  navigate?: () => void
}

export const MakeDeposit: React.FC<MakeDepositProps> = ({ navigate }) => {
  const { isMobile } = useSystemConfig()

  const openDepositCheck = () => {
    if (isMobile()) {
      closeModal()
      if (navigate) {
        navigate()
      }
    } else
      openModal({
        isInlineTitle: true,
        title: 'Make A Deposit By Check',
        content: <QRCodeModal title="" component={'makeDepositCheck'} />,
      })
  }

  return (
    <>
      <View style={styles.headerContainer}>
        <View>
          <AppText bold style={styles.title}>
            Make a Deposit
          </AppText>
        </View>
        <TouchableOpacity onPress={closeModal}>
          <Image source={closeIcon} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.optionsContainer}>
        <TouchableOpacity style={[styles.imageContainer]} onPress={openDepositCheck}>
          <Image source={depositCheckCard} style={styles.optionsImage} />
        </TouchableOpacity>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  headerContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  title: { fontSize: 24 },
  closeIcon: { width: 20, height: 20, resizeMode: 'contain' },
  optionsContainer: {
    flexDirection: 'row',
    marginTop: 20,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  optionsImage: { width: 240, height: 200, borderRadius: 8 },
  imageContainer: { padding: 15, borderWidth: 1, borderRadius: 8, borderColor: COLORS.BACKGROUND },
})
