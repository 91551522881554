import React, { useEffect, useRef, useState } from 'react'
import { FlatList, Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Avatar } from '@rneui/themed'
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'

import { COLORS, orgInfo, SCREEN_WIDTH } from '@assets/constants'
import CheckmarkFilled from '@assets/images/checkmark_filled.png'
import logo from '@assets/images/logo_color.png'
import { AppText } from '@views/components'

const CAROUSEL_ITEM_SPACE = 36

interface VisibleProps extends MaterialTopTabBarProps {
  isVisible: true
  isNonProfit: boolean
  hasBankAccount: boolean
  checkComplete: (route: string) => boolean
}

interface NotVisibleProps extends MaterialTopTabBarProps {
  isVisible: false
  isNonProfit?: boolean
  hasBankAccount?: boolean
  checkComplete?: (route: string) => boolean
}

type TTopTabBar = VisibleProps | NotVisibleProps

export const TopTabBar = ({
  state,
  navigation,
  isVisible,
  isNonProfit = false,
  checkComplete,
  hasBankAccount,
}: TTopTabBar) => {
  const flatListRef = useRef<FlatList>(null)
  const currentPageIdx = state.index
  const navPages = isNonProfit ? orgInfo.filter((item, idx) => idx !== 2) : orgInfo
  const [itemWidths, setItemWidth] = useState<number[]>(Array(navPages.length).fill(0))

  const isFunding = currentPageIdx > navPages.length
  let isPrevComplete = true
  useEffect(() => {
    const lastPageIndex =  navPages.length - 1
    if (!isFunding && currentPageIdx < lastPageIndex)
      // scroll to the current tab
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: currentPageIdx,
        viewPosition: 0.5,
      })
  }, [currentPageIdx, isFunding, itemWidths])

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Image source={logo} style={styles.logo} />
      </View>
      {isVisible && (
        <>
          <View style={styles.avatarGroup}>
            <TouchableOpacity
              disabled={!isFunding}
              onPress={() => {
                navigation.navigate('createAccount', {
                  screen: 'Review',
                })
              }}>
              <Avatar
                rounded={false}
                title={'1'}
                titleStyle={!isFunding ? styles.avatarTitleActive : styles.avatarTitle}
                containerStyle={!isFunding ? styles.avatarActive : styles.avatar}
              />
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!hasBankAccount}
              onPress={() => {
                navigation.navigate('createAccount', {
                  screen: 'fundAccount',
                })
              }}>
              <Avatar
                rounded={false}
                title={'2'}
                titleStyle={isFunding ? styles.avatarTitleActive : styles.avatarTitle}
                containerStyle={isFunding ? styles.avatarActive : styles.avatar}
              />
            </TouchableOpacity>
          </View>
          {!isFunding && (
            <ScrollView style={styles.carouselContainer}>
              <FlatList
                ref={flatListRef}
                data={navPages}
                initialScrollIndex={currentPageIdx}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                keyExtractor={(_, index) => `orgInfo_step_${index}`}
                contentContainerStyle={[
                  styles.carousel,
                  {
                    paddingLeft: (SCREEN_WIDTH - itemWidths[0]) / 2,
                    paddingRight: (SCREEN_WIDTH - itemWidths[itemWidths.length - 1]) / 2,
                  },
                ]}
                getItemLayout={(_, index) => ({
                  length: itemWidths[index],
                  offset:
                    (SCREEN_WIDTH - itemWidths[0]) / 2 +
                    itemWidths.slice(0, index).reduce((total, current) => total + current, 0) +
                    CAROUSEL_ITEM_SPACE * index,
                  index,
                })}
                renderItem={({ item: { route, title }, index }) => {
                  const isComplete = checkComplete!(route)
                  const isDisabled = isPrevComplete ? false : index >= currentPageIdx
                  const isActive = index === currentPageIdx
                  isPrevComplete = isComplete
                  return (
                    <TouchableOpacity
                      key={`orgInfo_step_${index}`}
                      style={styles.sublistItem}
                      disabled={isDisabled}
                      onLayout={(object) => {
                        setItemWidth((oldWidths) => {
                          const newWidths = [...oldWidths]
                          newWidths[index] = object.nativeEvent.layout.width
                          return newWidths
                        })
                      }}
                      onPress={() => {
                        navigation.navigate('createAccount', {
                          screen: route,
                        })
                      }}>
                      {isComplete && !isDisabled ? (
                        <Image source={CheckmarkFilled} style={styles.checkmarkImg} />
                      ) : (
                        <View style={isActive ? styles.checkmarkActive : styles.checkmark} />
                      )}
                      <AppText style={isActive ? styles.subtitleActive : styles.subtitle}>
                        {title}
                      </AppText>
                    </TouchableOpacity>
                  )
                }}
              />
            </ScrollView>
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.WHITE,
    boxShadow: `inset 0px -1px 0px ${COLORS.BACKGROUND}`,
    paddingBottom: 24,
  },
  headerContainer: {
    padding: 16,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: { width: 126, height: 30, resizeMode: 'contain' },
  avatarGroup: {
    flexDirection: 'row',
    gap: 40,
    justifyContent: 'center',
    marginTop: 20,
  },
  avatar: {
    width: 54,
    height: 54,
    borderRadius: 4,
    backgroundColor: COLORS.BACKGROUND,
  },
  avatarActive: {
    width: 54,
    height: 54,
    borderRadius: 4,
    backgroundColor: COLORS.PRIMARY,
  },
  avatarTitle: {
    color: COLORS.DARK,
  },
  avatarTitleActive: {
    color: COLORS.WHITE,
  },
  carouselContainer: {
    marginTop: 24,
    flex: 1,
  },
  carousel: {
    display: 'flex',
    gap: CAROUSEL_ITEM_SPACE,
  },
  subtitle: {
    color: COLORS.GRAY_700,
    fontSize: 16,
  },
  subtitleActive: {
    color: COLORS.VIOLET_600,
    fontSize: 16,
  },
  sublistItem: {
    flex: 1,
    minHeight: 24,
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkmark: {
    width: 15,
    height: 15,
    borderRadius: 2,
    borderWidth: 1.2,
    borderStyle: 'solid',
    marginRight: 10,
    borderColor: COLORS.GRAY_700,
  },
  checkmarkActive: {
    width: 15,
    height: 15,
    borderRadius: 2,
    borderWidth: 1.2,
    borderStyle: 'solid',
    marginRight: 10,
    borderColor: COLORS.PRIMARY,
    boxSizing: 'border-box',
  },
  checkmarkImg: {
    width: 15,
    height: 20,
    marginRight: 10,
    boxSizing: 'border-box',
  },
})
